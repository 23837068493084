import React from "react";
import Button from "@mui/material/Button";
import { StyledRealPayWrapper } from "./index.styled";

const RealPayText = ({ payValue, reSelect = () => {} }) => {
  const openNewServiceTerms = () => {
    window.open("/service-terms", "_blank");
  };

  return (
    <StyledRealPayWrapper>
      <div className="pay-text-wrapper">
        <div className="label">实付金额：</div>
        <div className="pay-value">
          <span className="unit">¥</span>
          <span className="value">{payValue}</span>
        </div>
      </div>
      <div className="other-desc">
        开通会员前请点击阅读
        <span className="blank-link" onClick={openNewServiceTerms}>
          《用户协议》
        </span>
      </div>
      <div className="return-select" onClick={reSelect}>
        <Button variant="outlined">重新选择套餐</Button>
      </div>
    </StyledRealPayWrapper>
  );
};

export default RealPayText;
