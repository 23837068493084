import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { StyledDialogTitle } from "../guideBanner/index.styled";
import { StyledSearchWrapper } from "./index.styled";
import useAddImgs, { IMAGE_TYPES } from "../../hooks/useAddImgs";
import { ReactComponent as IconClose } from "../../assets/images/guides/icon_close.svg";
import uploadImg from "../../assets/images/guides/img_upload_popup@2x.png";
import { ReactComponent as IconDelete } from "../../assets/images/guides/icon_delete_hover_picture.svg";

const SearchDialog = ({
  title,
  searchKey,
  searchUrl,
  searchImageName,
  open,
  handleSearch,
  handleClose,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");

  const revokeImageUrl = () => {
    imageUrl && imageUrl.startsWith("blob:") && URL.revokeObjectURL(imageUrl);
  };

  const {
    inputFileKey,
    inputFileRef,
    addImg,
    showAddImgDialog,
    uploadLoading,
  } = useAddImgs({
    handlerAddImgs: ([{ url, imageName: addImageName }]) => {
      revokeImageUrl();
      setImageUrl(url);
      setImageName(addImageName);
      const name = searchKey === "url" ? "imageName" : "faceImageName";
      handleSearch({
        [searchKey]: "",
        [name]: "",
        [addImageName ? name : searchKey]: addImageName || imageUrl,
        [searchKey === "url" ? "imageNameBlobUrl" : "faceImageNameBlobUrl"]:
          url,
      });
      handleClose();
    },
  });

  const search = () => {
    handleClose();
    // if (!imageUrl && !searchUrl) {
    //   return;
    // }
    const name = searchKey === "url" ? "imageName" : "faceImageName";
    handleSearch({
      [searchKey]: "",
      [name]: "",
      [imageName ? name : searchKey]: imageName || imageUrl,
      [searchKey === "url" ? "imageNameBlobUrl" : "faceImageNameBlobUrl"]:
        imageUrl,
    });
  };

  const removeImg = (e) => {
    e.stopPropagation();
    setImageName("");
    revokeImageUrl();
    setImageUrl("");
    const name = searchKey === "url" ? "imageName" : "faceImageName";
    handleSearch({
      [searchKey]: "",
      [name]: "",
      [searchKey === "url" ? "imageNameBlobUrl" : "faceImageNameBlobUrl"]: "",
    });
  };

  useEffect(() => {
    if (open) {
      setImageUrl(searchUrl || "");
      setImageName(searchImageName || "");
    }
  }, [open, searchUrl, searchImageName]);

  return (
    <Dialog
      classes={{
        paper: `search-url-modal`,
      }}
      maxWidth={false}
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle>
        <h5 className="title">{title}</h5>
        <div className="close" onClick={handleClose}>
          <IconClose />
        </div>
      </StyledDialogTitle>
      <StyledSearchWrapper>
        <InputBase
          className="search-input"
          placeholder="请在此粘贴图片网址"
          value={imageUrl?.startsWith("blob:") ? "" : imageUrl}
          onChange={(e) => {
            setImageUrl(e.target.value);
            setImageName("");
            revokeImageUrl();
          }}
        />
        <Button
          className="dialog-search-btn"
          variant="contained"
          onClick={search}
        >
          确定
        </Button>
      </StyledSearchWrapper>
      <StyledSearchWrapper className="column" handleDropFiles={addImg}>
        {imageUrl ? (
          <div
            className="result-image"
            style={{ backgroundImage: `url(${imageUrl})` }}
            onClick={showAddImgDialog}
          >
            <IconDelete className="del-icon" onClick={removeImg} />
          </div>
        ) : (
          <>
            <img className="upload-img" src={uploadImg} />
            <h5 className="upload-title">点击下方按钮，或拖入图片、文件夹</h5>
            <p className="upload-desc">支持大小小于4M的JPG、PNG图片</p>
            <Button
              variant="contained"
              className="dialog-search-btn upload-btn"
              onClick={showAddImgDialog}
              disabled={uploadLoading}
            >
              上传图片
            </Button>
          </>
        )}
        <input
          ref={inputFileRef}
          key={inputFileKey}
          type="file"
          accept={IMAGE_TYPES.join(",")}
          className="hide-input"
          onChange={addImg}
        />
      </StyledSearchWrapper>
    </Dialog>
  );
};

export default SearchDialog;
