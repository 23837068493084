import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { StyledWeXinQrCodeWrapper } from "./index.styled";
import { HOST } from "../../config";
import { useLocation } from "react-router-dom";
import useGetImages from "../../hooks/useGetImages";
import { getBase64 } from "../../utilities/Utilities";

const WeiXinLoginForm = ({ onChangeFormType = () => {}, blind = false }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loginResearch } = useGetImages();

  const { userInfo, tencentCos } = useSelector((state) => ({
    userInfo: state?.userStore?.userInfo,
    tencentCos: state?.imageStore?.tencentCos,
  }));

  const handlerMessage = (e) => {
    console.log("handle-wxlogin-msg", e);
    if (e.data?.type === "wx-login-data") {
      const info = e.data.data ?? {};
      dispatch({
        type: "user/setUserAuthenticated",
        payload: { userInfo: info },
      });
      const headImageUrl = info.headImageUrl;
      if (!headImageUrl || /^https?:\/\//.test(headImageUrl)) {
        dispatch({
          type: "user/setUserAuthenticated",
          payload: { userInfo: info },
        });
      } else {
        tencentCos.downloadImage(headImageUrl, function (err, data) {
          if (err) {
            console.error(err);
          } else {
            getBase64(data.Body).then((url) => {
              dispatch({
                type: "user/setUserAuthenticated",
                payload: {
                  userInfo: {
                    ...info,
                    headImageUrl: url,
                  },
                },
              });
            });
          }
        });
      }
      dispatch({
        type: "user/setLoginModalState",
        payload: { isLoginModalOpen: false },
      });

      // 登录成功后如果在搜索页，自动重新搜索
      if (pathname === "/gallery/search") {
        loginResearch({ userId: info.userId });
      }
    }
  };

  const initWxQrCode = (blind = false) => {
    new window.WxLogin({
      self_redirect: true,
      id: "wx-qrcode",
      appid: "wx0cbd3a68916dd42d",
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent(`${HOST}/auth`),
      state: !blind ? "STATE" : `${userInfo.userId}-true`,
      style: "",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7bWFyZ2luLXRvcDowO3dpZHRoOiAxMDAlO2JvcmRlcjpub25lO30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5Om5vbmU7fQ==",
    });
  };

  useEffect(() => {
    let script = null;
    if (window.top === window) {
      window.addEventListener("message", handlerMessage);
    }
    if (window.WxLogin) {
      initWxQrCode(blind);
    } else {
      script = document.createElement("script");
      script.onload = () => initWxQrCode(blind);
      script.src =
        "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      document.body.appendChild(script);
    }
    return () => {
      script = null;
      window.removeEventListener("message", handlerMessage);
    };
  }, [blind]);

  return (
    <StyledWeXinQrCodeWrapper>
      <div className="wx-qrcode-wrapper" id="wx-qrcode"></div>
      <Stack className="btns" direction="row" spacing={4}>
        {!blind && (
          <>
            <span className="btn" onClick={() => onChangeFormType("account")}>
              账号登录
            </span>
            <span className="btn" onClick={() => onChangeFormType("telephone")}>
              手机号登录
            </span>
            <span className="btn" onClick={() => onChangeFormType("email")}>
              邮箱登录
            </span>
          </>
        )}
      </Stack>
    </StyledWeXinQrCodeWrapper>
  );
};

export default WeiXinLoginForm;
