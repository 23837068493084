import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sources: [
    {
      id: Date.now() + "" + 0,
      url: "https://assets.soujpg.com/souJpg/images/Yf4GSPkoB8kc6sFpQ7dmNT.webp",
    },
  ],
  showTabs: true,
};
export const editorReducer = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setSources: (state, { payload }) => {
      state.sources = payload.sources;
    },

    setShowTabs: (state, { payload }) => {
      state.showTabs = payload.showTabs;
    },
  },
});

export const { setSources } = editorReducer.actions;
export default editorReducer.reducer;
