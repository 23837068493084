import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { StyledVerifyCodeWrapper } from "./index.styled";
import { useEffect } from "react";

const SendVerifyCode = ({
  beforeSend = () => true,
  sendFn = () => Promise.reject("需传递返回Promise的发送验证码函数"),
  handleSendSuccess = () => {},
  waitSecondLimit = 60,
  ...props
}) => {
  const [waitSecond, setWaitSecond] = useState(waitSecondLimit);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const timer = useRef(null);

  const sendVerifyCode = () => {
    if (!beforeSend()) {
      return;
    }
    setLoading(true);
    sendFn()
      .then((res) => {
        if (res?.payload?.error) {
          setLoading(false);
          return;
        }
        countDownSecond();
        handleSendSuccess(res?.payload?.userId);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countDownSecond = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setDisabled(true);
    setWaitSecond(waitSecondLimit);
    timer.current = setInterval(() => {
      setWaitSecond((preVal) => preVal - 1);
    }, 1000);
  };

  useEffect(() => {
    if (waitSecond === 0) {
      setDisabled(false);
      clearInterval(timer.current);
    }
  }, [waitSecond]);

  useEffect(
    () => () => {
      clearInterval(timer.current);
    },
    []
  );

  return (
    <StyledVerifyCodeWrapper>
      <TextField
        className="input code"
        inputProps={{ maxLength: 6 }}
        placeholder="请输入验证码"
        {...props}
      />
      <Button
        disabled={loading || disabled}
        className="send-btn"
        variant="contained"
        onClick={sendVerifyCode}
      >
        {!disabled ? "获取验证码" : `${waitSecond}s后重试`}
      </Button>
    </StyledVerifyCodeWrapper>
  );
};

export default SendVerifyCode;
