import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import short from "short-uuid";
import Message from "../components/message";
import { getBase64, b64toBlob } from "../utilities/Utilities";
import { useSelector } from "react-redux";

export const IMAGE_MAX_SIZE = 1024 * 1024 * 15;
export const IMAGE_TYPES = ["image/png", "image/jpeg", "image/webp"];

export default ({ onlyUseFiles = false, handlerAddImgs = () => {} }) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const [inputFileKey, setInputFileKey] = useState(Date.now());
  const showAddImgDialog = () => {
    inputFileRef.current?.click();
  };
  const { tencentCos } = useSelector((state) => state?.imageStore);

  // 添加图片
  const addImg = (e) => {
    const files = e.target.files;
    setInputFileKey(Date.now());
    const filterFiles = Array.from(files)
      .filter((file) => {
        const { type, size } = file;
        if (!IMAGE_TYPES.includes(type)) {
          Message({
            message: "请上传.jpg,.jpeg,.webp格式的图片",
            type: "warning",
          });
          return false;
        }
        if (size > IMAGE_MAX_SIZE) {
          Message({ message: "请上传小于15M的图片", type: "warning" });
          return false;
        }
        return true;
      })
      .slice(0, 50);
    if (filterFiles.length) {
      if (!onlyUseFiles) {
        fetchFilesCosUrls(filterFiles);
      } else {
        handlerAddImgs(
          filterFiles.map((file) => ({
            file,
            fileName: file.name,
          }))
        );
      }
    }
  };

  // const addDirectory = async () => {
  //   const dirHandle = await window.showDirectoryPicker();
  //   const values = dirHandle.values();
  //   const files = [];
  //   let next = null;
  //   async function getFile() {
  //     next = await values.next();
  //     const { value } = next;
  //     if (value) {
  //       if (value.kind === "file") files.push(await value.getFile());
  //       else console.log("是目录", value.name);
  //     }
  //   }
  //   await getFile();
  //   while (!next.done) await getFile();
  //   const newFiles = files.filter(
  //     (file) => IMAGE_TYPES.includes(file.type) && file.size < IMAGE_MAX_SIZE
  //   );
  //   if (!newFiles.length) {
  //     Message({
  //       type: "warning",
  //       message: "文件夹中不存在5M以内的jpg/jpeg/webp格式图片",
  //     });
  //     return;
  //   }
  //   fetchFilesCosUrls(newFiles);
  // };

  const [uploadLoading, setUploading] = useState(false);
  // 通过腾讯cos上传图片并生成临时blob地址
  const getFileCosUrl = (file, onProgress) => {
    return getBase64(file)
      .then((base64Data) =>
        b64toBlob(base64Data.split("base64,")[1], file.type)
      )
      .then((uploadBlob) => {
        const type = file.type.substring("image/".length);
        const imageIdKey =
          short.generate() + "." + (type === "jpeg" ? "jpg" : type);
        let url = null;
        return new Promise((resolve, reject) => {
          tencentCos.uploadImage(
            uploadBlob,
            imageIdKey,
            function (err) {
              if (err) {
                console.log(err);
                Message({ type: "error", message: "上传失败" });
                reject(err);
              } else {
                url = window.URL.createObjectURL(file);
                resolve({
                  imageName: imageIdKey,
                  url,
                });
              }
            },
            onProgress
          );
        });
      });
  };
  // 批量上传并返回img对象数组 [{ url, imageName, fileName }]
  const fetchFilesCosUrls = (files = []) => {
    setUploading(true);
    // Message({ type: "info", message: "上传图片中" });
    dispatch({
      type: "user/setWaitProgressModalState",
      payload: {
        isWaitProgress: {
          open: true,
          progress: 0,
        },
      },
    });
    const percents = Array(files.length).fill(0);
    const onProgress = ({ percent }, index) => {
      percents[index] = percent;
      dispatch({
        type: "user/setWaitProgressModalState",
        payload: {
          isWaitProgress: {
            open: true,
            progress:
              (percents.reduce((sum, item) => sum + item, 0) / files.length) *
              100,
          },
        },
      });
    };
    Promise.all(
      files.map((file, index) =>
        getFileCosUrl(file, (data) => onProgress(data, index))
      )
    )
      .then((cosResults) => {
        handlerAddImgs(
          cosResults.map(({ imageName, url }, index) => ({
            url,
            imageName,
            fileName: files[index].name,
          }))
        );
      })
      .finally(() => {
        dispatch({
          type: "user/setWaitProgressModalState",
          payload: {
            isWaitProgress: {
              open: false,
              progress: 0,
            },
          },
        });
        setUploading(false);
      });
  };

  return {
    inputFileRef,
    inputFileKey,
    showAddImgDialog,
    addImg,
    // addDirectory,
    uploadLoading,
  };
};
