import IconShape from "../../assets/images/search/icon_composition@2x.png";
import IconPerson from "../../assets/images/search/icon_person@2x.png";
import IconAge from "../../assets/images/search/icon_age@2x.png";
import IconPlace from "../../assets/images/search/icon_place@2x.png";
import IconImageType from "../../assets/images/search/icon_file@2x.png";

export const categorys = [
  {
    titleKey: "图像质量",
    icon: IconImageType,
    searchKey: "quality",
    children: [
      { value: null, label: "不限" },
      { value: "5.5-10", label: "极好" },
      { value: "5.2-10", label: "很好" },
      { value: "5-10", label: "比较好" },
      { value: "4-10", label: "一般好" },
    ],
  },
  {
    titleKey: "构图",
    icon: IconShape,
    searchKey: "orientation",
    children: [
      { value: null, label: "不限" },
      { value: "horizontal", label: "横图" },
      { value: "vertical", label: "竖图" },
      { value: "square", label: "正方形" },
    ],
  },
  {
    titleKey: "人数",
    icon: IconPerson,
    searchKey: "faceNum",
    children: [
      { value: null, label: "不限" },
      { value: "0-1", label: "无人" },
      { value: "1-2", label: "1人" },
      { value: "2-3", label: "2人" },
      { value: "3-100", label: "3人及以上" },
    ],
  },
  {
    titleKey: "年龄",
    icon: IconAge,
    searchKey: "age",
    children: [
      { value: null, label: "不限" },
      { value: "baby", label: "婴儿" },
      { value: "children", label: "儿童" },
      { value: "juvenile", label: "少年" },
      { value: "youth", label: "青年" },
      { value: "mid-life", label: "中年" },
      { value: "old", label: "老年" },
    ],
  },
  {
    titleKey: "地域",
    icon: IconPlace,
    searchKey: "ethnicity",
    children: [
      { value: null, label: "不限" },
      { value: "chinese", label: "中国人" },
      { value: "indian", label: "印度人" },
      { value: "black", label: "黑人" },
      { value: "white", label: "白人" },
      { value: "east asian", label: "东亚人" },
      { value: "southeast asian", label: "东南亚人" },
      { value: "middle eastern", label: "中东人" },
      { value: "latino hispanic", label: "拉美裔西班牙人" },
    ],
  },
  // {
  //   titleKey: "图片格式",
  //   icon: IconImageType,
  //   searchKey: "mimetype",
  //   children: [
  //     { value: null, label: "不限" },
  //     { value: "jpg", label: "JPG" },
  //     { value: "png", label: "PNG" },
  //     { value: "gif", label: "GIF" },
  //     { value: "ai", label: "AI" },
  //     { value: "psd", label: "PSD" },
  //     { value: "cdr", label: "CDR" },
  //   ],
  // },
];

export const whiteOrBlackHexColor = (hex) => {
  // 如果HEX颜色值前面有'#'，则去掉
  hex = hex.replace("#", "");

  // 将HEX颜色值转换为RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  if (255 * 3 - (r + g + b) < 100) {
    [r, g, b] = [0, 0, 0];
  } else {
    [r, g, b] = [255, 255, 255];
  }

  // 将取反后的RGB颜色值转换回HEX格式
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  // 返回反色的HEX颜色值
  return "#" + rHex + gHex + bHex;
};
