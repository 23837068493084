import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const RoutesWrapper = ({ children, setTransparentNav }) => {
  const protectedRoutes = ["userprofile", "details", "gallery", "aitools"];
  const publicRoutes = ["", "siteDoc"];
  const { isAuthenticated } = useSelector((state) => state?.userStore);
  useEffect(() => {
    if (protectedRoutes.includes(window.location?.pathname.split("/")[1])) {
      setTransparentNav(false);
      //   if (!isAuthenticated) {
      //     navigate("/");
      //   }
    } else if (publicRoutes.includes(window.location?.pathname.split("/")[1])) {
      setTransparentNav(true);
    }
  }, [window.location.pathname, isAuthenticated]);
  return <>{children}</>;
};

export default RoutesWrapper;
