import React from "react";
import { createRoot } from "react-dom/client";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./index.scss";

const Message = ({
  open = true,
  type = "error",
  message = "",
  duration = 2000,
}) => {
  const container = document.body;
  const _dom = document.createElement("div");

  container.appendChild(_dom);
  let root = null;

  const close = () => {
    root?.unmount();
    container.removeChild(_dom);
    root = null;
  };

  root = createRoot(_dom);
  root.render(
    <Snackbar
      autoHideDuration={duration}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={close}
      className="snack-bar"
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );

  return {
    close,
  };
};

export default Message;
