import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import SvgIcon from "../svgIcon";
import SearchDialog from "./SearchDialog";
import { StyledSearchBox, SearchInputWrapper } from "./index.styled";
import ColorPicker from "./colorPicker";
import useGetImages from "../../hooks/useGetImages";
import { getKeywords } from "../../redux/Actions/imagesAction";
import useOpen from "../../hooks//useOpen";
import { setParams } from "../../redux/Reducers/imagesReducer";
import { ReactComponent as IconSearch } from "../../assets/images/search/icon_search.svg";
import { ReactComponent as IconPersonSearch } from "../../assets/images/search/icon_personsearch.svg";
import { ReactComponent as IconPicSearch } from "../../assets/images/search/icon_picsearch.svg";
import { ReactComponent as IconDelete } from "../../assets/images/guides/icon_close.svg";

const IMAGE_TYPES = [
  { value: "all", label: "全部" },
  { value: "photo", label: "摄影图片" },
  { value: "vector", label: "矢量图" },
];

const SearchBox = ({ className, top = false }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { searchParams, search } = useGetImages();

  useEffect(() => {
    if (pathname === "/") {
      dispatch({
        type: "allImages/reset",
      });
      dispatch({
        type: "allImages/setImages",
        payload: {
          responseItems: [],
          totalCount: 0,
        },
      });
    }
    setSuggests([]);
  }, [pathname]);

  // 更改搜索图片类型
  const changeImageType = (e) => {
    search({ ...searchParams, imageType: e.target.value });
  };

  // 语义搜索提示tooltip
  const [sentenceOpen, setSentenceOpen] = useState(false);
  // 语义搜索提示展示时间
  const sentenceOpenTimer = useRef(null);
  useEffect(
    () => () => {
      if (sentenceOpenTimer.current) {
        clearTimeout(sentenceOpenTimer.current);
      }
    },
    []
  );
  const handleSearchOpenSentenceTooltip = () => {
    if (!searchParams.sentence) {
      setSentenceOpen(true);
      if (sentenceOpenTimer.current) {
        clearTimeout(sentenceOpenTimer.current);
      }
      sentenceOpenTimer.current = setTimeout(() => {
        setSentenceOpen(false);
      }, 3000);
    }
  };

  // 关键词搜索图片
  const changeInputText = (e) => {
    dispatch(setParams({ ...searchParams, inputText: e.target.value }));
    searchSuggest(e.target.value);
  };

  // 根据输入内容返回提示词
  const [suggests, setSuggests] = useState([]);
  const selectSuggest = (s) => {
    const data = {
      ...searchParams,
      inputText: s,
    };

    if (!data.inputText) {
      search(data);
      setSuggests([]);
      return;
    }

    let historysSearch = localStorage.getItem("historySearch");
    if (historysSearch) {
      historysSearch = JSON.parse(historysSearch);
      if (historysSearch.length >= 3) {
        historysSearch.pop();
      }
    }
    historysSearch = historysSearch || [];
    if (!historysSearch.includes(data.inputText)) {
      historysSearch.unshift(data.inputText);
    }
    localStorage.setItem("historySearch", JSON.stringify(historysSearch));
    setSuggests([]);
    search(data).then(handleSearchOpenSentenceTooltip);
  };
  const debounceQuickSearch = debounce((val) => {
    selectSuggest(val);
  }, 300);

  const quickSearch = (e) => {
    if (e.keyCode === 13) {
      debounceQuickSearch(e.target.value);
    }
  };
  const [suggestLoading, setSuggestLoading] = useState(false);
  const searchSuggest = useCallback(
    debounce((val) => {
      if (!val) {
        return;
      }
      setSuggestLoading(true);
      dispatch(
        getKeywords({
          params: {
            serviceMethod: "keywordSuggest",
            kwPrefix: val,
          },
        })
      )
        .then((res) => {
          if (Array.isArray(res?.payload.kws) && res.payload.kws.length) {
            setSuggests(res.payload.kws);
          } else {
            setSuggests([]);
          }
        })
        .finally(() => {
          setSuggestLoading(false);
        });
    }, 300),
    []
  );

  // 历史关键词搜索记录
  const [historys, setHistorys] = useState([]);
  useEffect(() => {
    const historysSearch = localStorage.getItem("historySearch");
    setHistorys(historysSearch ? JSON.parse(historysSearch) : []);
  }, []);
  const handleDeleteHistory = (index) => {
    setHistorys((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      localStorage.setItem("historySearch", JSON.stringify(arr));
      return arr;
    });
  };

  // 切换语义搜索，默认false
  const changeSentence = () => {
    // 语义词有值时切换立即自动搜索，无关机词输入值时仅更改store状态
    if (searchParams.inputText) {
      search({
        ...searchParams,
        sentence: !searchParams.sentence,
      });
    } else {
      dispatch(
        setParams({ ...searchParams, sentence: !searchParams.sentence })
      );
    }
  };

  // 点击搜索按钮
  const handleSearch = () => {
    if (!searchParams.inputText) {
      search(searchParams);
      setSuggests([]);
      return;
    }

    // 记录搜索历史然后再搜索
    let historysSearch = localStorage.getItem("historySearch");
    if (historysSearch) {
      historysSearch = JSON.parse(historysSearch);
      if (historysSearch.length >= 3) {
        historysSearch.pop();
      }
    }
    historysSearch = historysSearch || [];
    if (!historysSearch.includes(searchParams.inputText)) {
      historysSearch.unshift(searchParams.inputText);
    }
    localStorage.setItem("historySearch", JSON.stringify(historysSearch));
    setSuggests([]);
    search(searchParams).then(handleSearchOpenSentenceTooltip);
  };

  const { open, handleOpen, handleClose } = useOpen(false);
  const [searchDialogProps, setSearchDialogProps] = useState({
    title: "",
  });

  const openSearchDialog = (data) => {
    // 打开相似图、颜色、人脸搜索弹窗
    handleOpen();
    setSearchDialogProps(data);
  };

  const handleIconSearch = (data) => {
    // 相似图 | 人脸图片搜索
    search({
      ...searchParams,
      ...data,
    });
  };

  useEffect(() => {
    // 初始化清空关键词提示选项下拉框
    const clearSuggest = () => {
      setSuggests([]);
    };
    window.addEventListener("click", clearSuggest, false);
    return () => window.removeEventListener("click", clearSuggest, false);
  }, []);

  return (
    <StyledSearchBox className={`${className} ${top ? "top" : ""}`}>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: top ? "40px" : "70px",
          padding: top ? "0 30px 0 0" : "0 30px 0 18px",
          borderRadius: "10px",
          borderTopRightRadius: top ? 0 : "10px",
          borderBottomRightRadius: top ? 0 : "10px",
          border: top ? "1px solid #999999" : "",
          borderRight: "none",
          boxShadow: "none",
        }}
      >
        <Select
          className="select"
          value={searchParams.imageType}
          onChange={changeImageType}
        >
          {IMAGE_TYPES.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Divider
          sx={{ height: 26, background: "#999999" }}
          orientation="vertical"
        />
        <SearchInputWrapper>
          <InputBase
            style={{ width: "100%" }}
            placeholder="查找免费高清图片..."
            value={searchParams.inputText}
            onChange={changeInputText}
            onKeyDown={quickSearch}
          />
          <ul
            className={`suggest-list ${
              suggestLoading || suggests.length ? "show" : ""
            }`}
          >
            {suggestLoading ? (
              <CircularProgress
                style={{ width: 20, height: 20, marginLeft: 20 }}
              />
            ) : (
              suggests.map((s) => (
                <li
                  key={s}
                  className="suggest-item"
                  onClick={() => selectSuggest(s)}
                >
                  {s}
                </li>
              ))
            )}
          </ul>
          {!top && (
            <ul className="history-wrapper">
              {historys.map((str, index) => (
                <Tooltip key={str} placement="top">
                  <li className="history-item" key={str}>
                    <div
                      className="left"
                      onClick={() =>
                        search({ ...searchParams, inputText: str })
                      }
                    >
                      <SvgIcon fontSize={16} color="#fff">
                        <IconSearch />
                      </SvgIcon>
                      <span className="string">{str}</span>
                    </div>
                    <SvgIcon
                      fontSize={16}
                      color="#fff"
                      onClick={() => handleDeleteHistory(index)}
                    >
                      <IconDelete />
                    </SvgIcon>
                  </li>
                </Tooltip>
              ))}
            </ul>
          )}
        </SearchInputWrapper>
        {!top && (
          <SvgIcon
            className="search-btn"
            color="#666"
            fontSize={25}
            onClick={handleSearch}
          >
            <IconSearch />
          </SvgIcon>
        )}
      </Paper>
      {top && (
        <Button
          className="search-btn-wrapper"
          variant="contained"
          onClick={handleSearch}
        >
          <IconSearch className="search-btn" style={{ fill: "#fff" }} />
        </Button>
      )}
      <div className="other-search-types">
        <div className={`sentence-switch ${!top ? "white" : ""}`}>
          <Tooltip
            title="如果觉得效果不满意，可以尝试一下语义搜索哦~"
            open={sentenceOpen}
            arrow
          >
            <Switch checked={searchParams.sentence} onChange={changeSentence} />
          </Tooltip>
          语义搜索
        </div>
        <Tooltip title="识图搜索" placement="top" arrow>
          <IconPicSearch
            style={{ fill: top ? "#3D96FF" : "#fff" }}
            className="icon-search-btn"
            onClick={() =>
              openSearchDialog({
                title: "识图搜索",
                searchKey: "url",
                searchUrl: searchParams.url || searchParams.imageNameBlobUrl,
                searchImageName: searchParams.imageName,
              })
            }
          />
        </Tooltip>
        <ColorPicker
          top={top}
          color={searchParams.qColorsInfo}
          handleSearch={handleIconSearch}
        />
        <Tooltip title="人像搜索" placement="top" arrow>
          <IconPersonSearch
            style={{ fill: top ? "#3D96FF" : "#fff" }}
            className="icon-search-btn"
            onClick={() =>
              openSearchDialog({
                title: "人像搜索",
                searchKey: "faceUrl",
                searchUrl:
                  searchParams.faceUrl || searchParams.faceImageNameBlobUrl,
                searchImageName: searchParams.faceImageName,
              })
            }
          />
        </Tooltip>
        <SearchDialog
          {...searchDialogProps}
          open={open}
          handleClose={handleClose}
          handleSearch={handleIconSearch}
        />
      </div>
    </StyledSearchBox>
  );
};

export default SearchBox;
