import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import { StyledDialogTitle } from "../guideBanner/index.styled";
import { SketchPicker } from "react-color";
import SvgIcon from "../svgIcon";
import { ReactComponent as IconColorSearch } from "../../assets/images/search/icon_colorsearch.svg";
import useOpen from "../../hooks/useOpen";
import { ReactComponent as IconClose } from "../../assets/images/guides/icon_close.svg";
import { StyledColorBody } from "./index.styled";
import { whiteOrBlackHexColor } from "./utils";

const POPULAR_COLORS = [
  "#F8E71B",
  "#F84949",
  "#7ED321",
  "#BD10E0",
  "#9012FE",
  "#F0FF00",
  "#9B9B9B",
  "#4B8FE2",
  "#50E3C1",
  "#A5CEFF",
];

const ColorPicker = ({ top, color, handleSearch }) => {
  const { open, handleOpen, handleClose } = useOpen();
  const {
    open: colorOpen,
    handleOpen: handleColorOpen,
    handleClose: handleColorClose,
  } = useOpen();

  const [searchColors, setSearchColors] = useState([]);

  useEffect(() => {
    setSearchColors(
      color?.split
        ? color.split(",").map((item) => item.substring(0, item.indexOf("-")))
        : []
    );
  }, [color]);

  const handleDelColor = (delIndex) => {
    setSearchColors(searchColors.filter((_, index) => index !== delIndex));
  };

  const [currentColor, setCurrentColor] = useState("#000000");
  const changeColor = ({ hex }) => {
    setCurrentColor(hex);
  };

  const confirmColor = () => {
    if (!searchColors.includes(currentColor)) {
      setSearchColors([...searchColors, currentColor]);
    }
    handleColorClose();
  };

  const addExampleColor = (colorExample) => {
    if (!searchColors.includes(colorExample)) {
      setSearchColors([...searchColors, colorExample]);
    }
  };

  const handleSearchColor = () => {
    handleSearch({
      qColorsInfo: searchColors.length
        ? searchColors
            .map((item) => `${item}-${Math.floor(100 / searchColors.length)}`)
            .join(",")
        : null,
    });
    handleClose();
  };

  return (
    <>
      <Tooltip title="颜色搜索" placement="top" arrow>
        <IconColorSearch
          style={{ fill: top ? "#3D96FF" : "#fff" }}
          className="icon-search-btn"
          onClick={handleOpen}
        />
      </Tooltip>
      <Dialog
        classes={{
          paper: `search-color-modal`,
        }}
        maxWidth={false}
        open={open}
      >
        <StyledDialogTitle>
          <h5 className="title">颜色搜索</h5>
          <div className="close" onClick={handleClose}>
            <IconClose />
          </div>
        </StyledDialogTitle>
        <StyledColorBody>
          <div className="color-values">
            {searchColors.map((item, index) => (
              <div
                key={item}
                className="color"
                style={{ backgroundColor: item }}
              >
                <span
                  className="color-text"
                  style={{ color: whiteOrBlackHexColor(item) }}
                >
                  {item}
                </span>
                <SvgIcon
                  fontSize={16}
                  color={whiteOrBlackHexColor(item)}
                  className="del-color"
                  onClick={() => handleDelColor(index)}
                >
                  <IconClose />
                </SvgIcon>
              </div>
            ))}
            <Button
              className="add-btn"
              disabled={searchColors.length === 3}
              style={{ marginLeft: searchColors.length ? 20 : 0 }}
              onClick={handleColorOpen}
            >
              <div className="add-icon">+</div>
              <div className="add-text">添加颜色</div>
            </Button>
          </div>
          <div className="select-colors">
            <div className="select-colors-item">
              <div className="label">流行颜色</div>
              {POPULAR_COLORS.map((item) => (
                <Button
                  key={item}
                  className="color-item"
                  disabled={searchColors.length === 3}
                  style={{ backgroundColor: item }}
                  onClick={() => addExampleColor(item)}
                ></Button>
              ))}
            </div>
            <div className="select-colors-item mt30">
              <div className="label">最近色彩</div>
              {/* {POPULAR_COLORS.map((item) => (
                <div
                  key={item}
                  className="color-item"
                  style={{ backgroundColor: item }}
                ></div>
              ))} */}
            </div>
            <p className="desc">点击色块，可以快捷添加颜色哦~</p>
          </div>
          <div className="footer">
            <Button variant="contained" onClick={handleSearchColor}>
              搜索
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              取消
            </Button>
          </div>
        </StyledColorBody>
      </Dialog>
      <Dialog
        classes={{
          paper: `color-modal`,
        }}
        maxWidth={false}
        open={colorOpen}
      >
        <StyledDialogTitle style={{ marginBottom: 20 }}>
          <h5 className="title">颜色</h5>
          <div className="close" onClick={handleColorClose}>
            <IconClose />
          </div>
        </StyledDialogTitle>
        <div className="flex">
          <SketchPicker
            styles={{ maxWidth: "220px" }}
            onChange={changeColor}
            color={currentColor}
          />
          <div className="value">
            <div
              className="color-box"
              style={{
                backgroundColor: currentColor,
              }}
            ></div>
          </div>
        </div>
        <Button
          className="dialog-btn"
          variant="contained"
          onClick={confirmColor}
        >
          确定
        </Button>
      </Dialog>
    </>
  );
};

export default ColorPicker;
