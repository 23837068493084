import { createSlice } from "@reduxjs/toolkit";
import { AppStatus } from "../../utilities/staticObjects";
import TencentCos from "../../utilities/Utilities";

// import {
//   getAllTags,
//   getFaceSearchImages,
//   getImages,
//   getImagesMetaInfo,
//   getKeywords,
//   getTagsImages,
//   getTagsSuggestions,
//   textToImage,
// } from "../Actions/imagesAction";
// import { registerStep1, registerStep2 } from "../Actions/userActions";
const tencentCos = new TencentCos();
const initialState = {
  userInfo: {
    nickName: null,
    emailName: null,
    userId: null,
    password: null,
    userInputEmailVerifiedCode: null,
  },
  imagesList: {
    images: null,
    appStatus: AppStatus.LOADING,
  },
  textToImage: {
    images: null,
    appStatus: AppStatus.LOADING,
  },
  tagsImagesList: {
    images: null,
    totalCount: 0,
    appStatus: AppStatus.LOADING,
    pageNum: 1,
    pageSize: 50,
  },
  keywords: {
    suggestedKeywords: null,
    keywordsStatus: AppStatus.LOADING,
    findKeywordData: null,
  },
  tags: {
    tagsStatus: AppStatus.LOADING,
    findTagOf: null,
    suggestedTags: [],
  },
  allTags: [],
  imageDetails: {
    imageMetaInfo: null,
    imageMetaInfoStatus: AppStatus.LOADING,
  },
  params: {
    age: null,
    gender: null,
    imageAppOpName: "imageSearch",
    inputText: "",
    pageSize: 100,
    faceNum: null,
    source: null,
    pageNum: 1,
    imageType: "all",
    illegal: 0,
    searchable: 1,
    sort: "quality",
    ccLicenceType: null,
    userId: null,
    searchMode: "souJpg",
    imageName: "",
    faceImageName: "",
    sentence: false,
    freeCommercialUse: "0",
    quality: null,
  },
  imagesLoading: false,
  userParams: {
    userAppOpName: null,
    nickName: null,
    emailName: null,
    userId: null,
    password: null,
    userInputEmailVerifiedCode: null,
  },

  userImageOpParams: {
    imageAppOpName: "userImageOp",
    userImageOpName: null,
    source: null,
    imageId: null,
    kwId: null,
    userId: null,
    tagNames: [],
    uploadedImageUrl: null,
    uploadedImageDomain: null,
    uploadImageContent: null,
  },

  userRegisterStep1Response: {
    userId: null,
    errorCode: null,
    sendEmailCodeSuccess: null,
    status: null,
  },
  userRegisterStep2Response: {
    errorCode: null,
    emailVerifiedCodeMatchSuccess: null,
    status: null,
  },
  tencentCos: tencentCos,
};
export const imagesReducer = createSlice({
  name: "allImages",
  initialState,
  reducers: {
    imagesPageNumUpdate: (state, { payload }) => {
      state.params.pageNum = payload;
    },
    tagsImagesPageNumUpdate: (state, { payload }) => {
      state.tagsImagesList.pageNum = payload;
    },
    reset: (state) => {
      state.params = {
        age: null,
        gender: null,
        imageAppOpName: "imageSearch",
        inputText: "",
        pageSize: 100,
        faceNum: null,
        source: null,
        pageNum: 1,
        imageType: "all",
        illegal: 0,
        searchable: 1,
        sort: "quality",
        ccLicenceType: null,
        userId: null,
        sentence: false,
        qColorsInfo: null,
        freeCommercialUse: "0",
        quality: null,
      };
    },
    setImages: (state, { payload }) => {
      state.imagesList.images = payload;
    },
    setImagesLoading: (state, { payload }) => {
      state.imagesLoading = payload.loading;
    },
    setKeyword: (state) => {
      state.keywords.suggestedKeywords = null;
      state.keywords.keywordsStatus = AppStatus.READY;
    },
    findKeywordsOf: (state, { payload }) => {
      state.keywords.findKeywordData = payload;
    },
    findTagOf: (state, { payload }) => {
      state.tags.findTagOf = payload;
    },
    setParams: (state, { payload }) => {
      state.params = payload;
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
    setUserLoginResponse: (state, { payload }) => {
      state.userLoginResponse = payload;
    },
    setUserRegisterStep1Response: (state, { payload }) => {
      state.userRegisterStep1Response = payload;
    },
    setUserRegisterStep2Response: (state, { payload }) => {
      state.userRegisterStep2Response = payload;
    },
  },
  extraReducers: {
    // [getImages.pending]: (state) => {
    //   state.imagesList.appStatus = AppStatus.LOADING;
    // },
    // [getImages.fulfilled]: (state, { payload }) => {
    //   state.imagesList.images = payload;
    //   state.imagesList.appStatus = AppStatus.READY;
    // },
    // [getImages.rejected]: (state) => {
    //   state.imagesList.images = null;
    //   state.imagesList.appStatus = AppStatus.READY;
    // },
    // [getFaceSearchImages.pending]: (state) => {
    //   state.imagesList.appStatus = AppStatus.LOADING;
    // },
    // [getFaceSearchImages.fulfilled]: (state, { payload }) => {
    //   state.imagesList.images = payload;
    //   state.imagesList.appStatus = AppStatus.READY;
    // },
    // [getFaceSearchImages.rejected]: (state) => {
    //   state.imagesList.images = null;
    //   state.imagesList.appStatus = AppStatus.READY;
    // },
    // [getKeywords.pending]: (state, { meta }) => {
    //   // if (meta.arg.params.kwPrefix === state.findKeywordData) {
    //   state.keywords.suggestedKeywords = null;
    //   // }
    //   state.keywords.keywordsStatus = AppStatus.LOADING;
    // },
    // [getKeywords.fulfilled]: (state, { payload }) => {
    //   // if (payload.param === state.keywords.findKeywordData) {
    //   console.info(payload);
    //   state.keywords.suggestedKeywords = payload.kws;
    //   state.keywords.keywordsStatus = AppStatus.READY;
    //   // }
    // },
    // [getKeywords.rejected]: (state) => {
    //   state.keywords.suggestedKeywords = null;
    //   state.keywords.keywordsStatus = AppStatus.READY;
    // },
    // [getTagsSuggestions.pending]: (state, { meta }) => {
    //   // if (meta.arg.params.kwPrefix === state.tags.findTagOf) {
    //   state.tags.suggestedTags = null;
    //   // }
    //   state.tags.tagsStatus = AppStatus.LOADING;
    // },
    // [getTagsSuggestions.fulfilled]: (state, { payload }) => {
    //   // if (payload.param === state.tags.findTagOf) {
    //   state.tags.suggestedTags = payload.result.kws;
    //   state.tags.tagsStatus = AppStatus.READY;
    //   // }
    // },
    // [getTagsSuggestions.rejected]: (state) => {
    //   state.tags.suggestedTags = null;
    //   state.tags.tagsStatus = AppStatus.READY;
    // },
    // [getImagesMetaInfo.pending]: (state) => {
    //   state.imageDetails.imageMetaInfoStatus = AppStatus.LOADING;
    // },
    // [getImagesMetaInfo.fulfilled]: (state, { payload }) => {
    //   state.imageDetails.imageMetaInfo = payload;
    //   state.imageDetails.imageMetaInfoStatus = AppStatus.READY;
    // },
    // [getImagesMetaInfo.rejected]: (state) => {
    //   state.imageDetails.imageMetaInfo = null;
    //   state.imageDetails.imageMetaInfoStatus = AppStatus.READY;
    // },
    // // register
    // [registerStep1.pending]: (state) => {
    //   state.userRegisterStep1Response.status = AppStatus.LOADING;
    // },
    // [registerStep1.fulfilled]: (state, { payload }) => {
    //   // console.log('payload:' + payload.sendEmailCodeSuccess);
    //   state.userRegisterStep1Response.userId = payload.userId;
    //   state.userRegisterStep1Response.sendEmailCodeSuccess =
    //     payload.sendEmailCodeSuccess;
    //   state.userRegisterStep1Response.errorCode = payload.errorCode;
    //   state.userRegisterStep1Response.status = AppStatus.READY;
    //   // console.log(state.userRelatedAPIResponse);
    // },
    // [registerStep1.rejected]: (state) => {
    //   state.userRegisterStep1Response.status = AppStatus.READY;
    // },
    // [registerStep2.pending]: (state) => {
    //   state.userRegisterStep2Response.status = AppStatus.LOADING;
    // },
    // [registerStep2.fulfilled]: (state, { payload }) => {
    //   state.userRegisterStep2Response.errorCode = payload.errorCode;
    //   state.userRegisterStep2Response.emailVerifiedCodeMatchSuccess =
    //     payload.emailVerifiedCodeMatchSuccess;
    //   state.userRegisterStep2Response.status = AppStatus.READY;
    // },
    // [registerStep2.rejected]: (state) => {
    //   state.userRegisterStep2Response.status = AppStatus.READY;
    // },
    // [getAllTags.fulfilled]: (state, { payload }) => {
    //   state.allTags = payload;
    // },
    // [getTagsImages.pending]: (state) => {
    //   state.tagsImagesList.appStatus = AppStatus.LOADING;
    // },
    // [getTagsImages.fulfilled]: (state, { payload }) => {
    //   state.tagsImagesList.images = payload.responseItems;
    //   state.tagsImagesList.totalCount = payload.totalCount;
    //   state.tagsImagesList.appStatus = AppStatus.READY;
    // },
    // [getTagsImages.rejected]: (state) => {
    //   state.tagsImagesList.images = null;
    //   state.tagsImagesList.appStatus = AppStatus.READY;
    // },
    // [textToImage.pending]: (state) => {
    //   state.textToImage.appStatus = AppStatus.LOADING;
    //   state.textToImage.images = null;
    // },
    // [textToImage.fulfilled]: (state, { payload }) => {
    //   state.textToImage.appStatus = AppStatus.READY;
    //   state.textToImage.images = payload?.generatedImageUrls;
    // },
    // [textToImage.rejected]: (state) => {
    //   state.textToImage.appStatus = AppStatus.READY;
    //   state.textToImage.images = null;
    // },
  },
});

export const {
  imagesPageNumUpdate,
  tagsImagesPageNumUpdate,
  reset,
  setImages,
  setKeyword,
  findKeywordsOf,
  findTagOf,
  setParams,
  setImagesLoading,
  setUserInfo,
  setUserLoginResponse,
  setUserRegisterStep1Response,
  setUserRegisterStep2Response,
} = imagesReducer.actions;
export default imagesReducer.reducer;
