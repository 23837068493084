import { useState } from "react";
import { mobileReg, mailReg, verifyCodeReg } from "../utilities/regular";

export const useValidateTelephone = () => {
  const [telephone, setTelephone] = useState("");
  const [errorText, setErrorText] = useState("");

  const validateTel = () => {
    if (!telephone) {
      setErrorText("请输入手机号");
      return false;
    }
    if (!mobileReg.test(telephone)) {
      setErrorText("请输入正确的手机号");
      return false;
    }
    setErrorText("");
    return true;
  };

  return {
    telephone,
    setTelephone,
    validateTel,
    errorText,
  };
};

export const useValidateVerifyCode = () => {
  const [verifyCode, setVerifyCode] = useState("");
  const [errorText, setErrorText] = useState("");

  const validateVerifyCode = () => {
    if (!verifyCode) {
      setErrorText("请输入验证码");
      return false;
    }
    if (!verifyCodeReg.test(verifyCode)) {
      setErrorText("请输入6位数字");
      return false;
    }
    setErrorText("");
    return true;
  };

  return {
    verifyCode,
    setVerifyCode,
    validateVerifyCode,
    errorText,
  };
};

export const useValidateEmail = () => {
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");

  const validateEmail = () => {
    if (!email) {
      setErrorText("请输入邮箱");
      return false;
    }
    if (!mailReg.test(email)) {
      setErrorText("请输入正确的邮箱");
      return false;
    }
    setErrorText("");
    return true;
  };

  return {
    email,
    setEmail,
    validateEmail,
    errorText,
  };
};

export const useValidateImgCode = () => {
  const [imgCode, setImgCode] = useState("");
  const [errorText, setErrorText] = useState("");

  const validateImgCode = (realCode = "") => {
    if (!imgCode) {
      setErrorText("请输入图形验证码");
      return false;
    }
    if (imgCode.toLocaleLowerCase() !== realCode.toLocaleLowerCase()) {
      setErrorText("请输入正确的图形验证码");
      return false;
    }
    setErrorText("");
    return true;
  };

  return {
    imgCode,
    setImgCode,
    validateImgCode,
    errorText,
  };
};

// 账号登录验证
export const useValidateAccount = () => {
  const [accountName, setAccountName] = useState("");
  const [accountPasswd, setAccountPasswd] = useState("");
  const [accountErrorText, setAccountErrorText] = useState("");
  const [pwdErrorText, setPwdErrorText] = useState("");

  const validate = () => {
    if (!accountName) {
      setAccountErrorText("请输入账号");
      return false;
    }
    if (!accountPasswd) {
      setPwdErrorText("请输入密码");
      return false;
    }
    setAccountErrorText("");
    setPwdErrorText("");
    return true;
  };

  return {
    accountName,
    setAccountName,
    accountPasswd,
    setAccountPasswd,
    validate,
    accountErrorText,
    pwdErrorText,
  };
};
