import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import SendVerifyCode from "../sendVerifyCode";
import ImgCode from "./ImgCode";
import { StyledInputWrapper, LoginBtn } from "./index.styled";
import {
  useValidateImgCode,
  useValidateTelephone,
  useValidateVerifyCode,
} from "../../hooks/useValidate";
import useLogin from "../../hooks/useLogin";
import usePhoneScreen from "../../hooks/usePhoneScreen";
import {
  loginAsyncThunk,
  sendVerifyCodeAsyncThunk,
} from "../../redux/Actions/userActions";
import useBlind from "../../hooks/useBlind";

const TelephoneLoginForm = ({ onChangeFormType = () => {}, blind = false }) => {
  const { userInfo } = useSelector((state) => state?.userStore);
  const { isPhoneScreen } = usePhoneScreen();

  const dispatch = useDispatch();

  const {
    telephone,
    setTelephone,
    errorText: telephoneErrorText,
    validateTel,
  } = useValidateTelephone();

  const {
    verifyCode,
    setVerifyCode,
    errorText: verifyCodeErrorText,
    validateVerifyCode,
  } = useValidateVerifyCode();

  const {
    imgCode,
    setImgCode,
    errorText: imgCodeErrorText,
    validateImgCode,
  } = useValidateImgCode();

  const changeTelephone = (e) => {
    setTelephone(e.target.value);
  };

  const changeVerifyCode = (e) => {
    setVerifyCode(e.target.value);
  };

  const changeImgCode = (e) => {
    setImgCode(e.target.value);
  };

  const sendTelVerifyCode = () =>
    dispatch(
      sendVerifyCodeAsyncThunk({
        params: {
          serviceMethod: "sendVerifiedCode",
          mobileNumber: `+86${telephone}`,
          loginType: "mobile",
          blinedUserId: userInfo.userId,
          blindModel: blind,
        },
      })
    );

  const tempUserId = useRef(null);
  const setTempUserId = (userId) => {
    tempUserId.current = userId;
  };

  const { loading, login } = useLogin({
    dispatch,
    loginFn: () =>
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "loginNew",
            userId: tempUserId.current,
            loginType: "mobile",
            userInputVerifiedCode: verifyCode,
          },
        })
      ),
  });

  const [realImgCode, setRealImgCode] = useState("");
  const beforeSend = () => validateTel() && validateImgCode(realImgCode);
  const handleLogin = () => {
    if (
      !validateTel() ||
      !validateVerifyCode() ||
      !validateImgCode(realImgCode)
    ) {
      return;
    }

    login();
  };

  const { blind_op } = useBlind({
    dispatch,
    blindFn: () =>
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "updateUserInfo",
            userId: userInfo.userId,
            userInputVerifiedCode: verifyCode,
            mobileNumber: `+86${telephone}`,
          },
        })
      ),
  });

  const handleBlind = () => {
    if (
      !validateTel() ||
      !validateVerifyCode() ||
      !validateImgCode(realImgCode)
    ) {
      return;
    }

    blind_op();
  };

  return (
    <StyledInputWrapper>
      <TextField
        className="input"
        error={Boolean(telephoneErrorText)}
        helperText={telephoneErrorText}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          maxLength: 11,
        }}
        maxLength="11"
        fullWidth
        placeholder="请输入手机号"
        value={telephone}
        onChange={changeTelephone}
      />
      <ImgCode
        onGetRealImgCode={setRealImgCode}
        value={imgCode}
        onChange={changeImgCode}
        error={Boolean(imgCodeErrorText)}
        helperText={imgCodeErrorText}
      />
      <SendVerifyCode
        value={verifyCode}
        onChange={changeVerifyCode}
        error={Boolean(verifyCodeErrorText)}
        helperText={verifyCodeErrorText}
        beforeSend={beforeSend}
        sendFn={sendTelVerifyCode}
        handleSendSuccess={setTempUserId}
      />
      {!blind && (
        <>
          <LoginBtn disabled={loading} onClick={handleLogin}>
            登录
          </LoginBtn>
          <Stack className="btns" direction="row" spacing={4}>
            {!isPhoneScreen && (
              <span className="btn" onClick={() => onChangeFormType("weixin")}>
                微信登录
              </span>
            )}
            <span className="btn" onClick={() => onChangeFormType("account")}>
              账号登录
            </span>
            <span className="btn" onClick={() => onChangeFormType("email")}>
              邮箱登录
            </span>
          </Stack>
        </>
      )}
      {blind && (
        <LoginBtn disabled={loading} onClick={handleBlind}>
          绑定
        </LoginBtn>
      )}
    </StyledInputWrapper>
  );
};

export default TelephoneLoginForm;
