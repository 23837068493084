import styled from "styled-components";

export const StyledWaitProgressWrapper = styled.div`
  padding: 50px 80px;
  padding-top: 60px;
  width: 600px;
  height: 450px;
  background: linear-gradient(-90deg, #cecbfa 0%, #ffffff 100%);
  .banner {
    display: flex;
  }
  .left {
    .title {
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #7a4ae8;
      white-space: nowrap;
    }
    .explain-item {
      display: flex;
      align-items: center;
      margin-top: 26px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      &:first-child {
        margin-top: 44px;
      }
      .yes-icon {
        margin-right: 10px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    width: 100%;
    // margin-left: -10px;
    .logo {
      width: 294px;
      height: 277px;
    }
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }
  .progress {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .text {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
    }
    .progress-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .progress-box {
        flex: 1;
        .MuiLinearProgress-bar {
          background: linear-gradient(
            -90deg,
            rgba(150, 109, 244, 0.2) 0%,
            #20b7fe 100%
          );
        }
      }
      .value {
        margin-left: 20px;
        width: 50px;
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 750px) {
    &&&& {
      margin: -0.4rem;
      padding: 0.4rem !important ;
      width: 80vw;
      height: auto;
      .banner {
        flex-direction: column;
        align-items: center;
      }
      .title {
        font-size: 0.32rem;
      }
      .explain-item {
        margin-top: 0.26rem;
        font-size: 0.16rem;
        &:first-child {
          margin-top: 0.44rem;
        }
        .yes-icon {
          margin-right: 0.1rem;
        }
      }
      .logo {
        width: 2.9rem;
        height: 2.7rem;
      }
      .progress {
        margin-top: 0.3rem;
        .text {
          font-size: 0.16rem;
        }
        .value {
          margin-left: 0.2rem;
          width: 0.5rem;
          font-size: 0.2rem;
        }
      }
    }
  }
`;
