import React from "react";
import Message from "../components/message";
import { store } from "../redux/store";
import apiContactQRcode from "../assets/images/contact/api.jpg";

export const APICall = async function (path, method, bodyData) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const requesOptions = {
    method: method,
    headers: myHeaders,
    body: JSON.stringify(bodyData),
  };

  try {
    const response = await fetch(`${path}`, requesOptions);
    const json = await response.json();
    if (!response.ok || json.errorCode) {
      // 调用接口的错误码回调操作
      const showLogin = () => {
        store.dispatch({
          type: "user/setLoginModalState",
          payload: {
            isLoginModalOpen: true,
          },
        });
      };
      const showCommonErrorModal = (content) => () => {
        store.dispatch({
          type: "user/setCommonErrorModalState",
          payload: {
            open: true,
            content,
            handleCloseFn: () => {},
          },
        });
      };
      // const showPay = () => {
      //   store.dispatch({
      //     type: "user/setPayModalState",
      //     payload: {
      //       isPayModalOpen: true,
      //     },
      //   });
      // };
      const errorHandler = {
        userAuthError01: showLogin,
        // 游客无权限时，弹出登录弹窗
        permissionCheckError01: showLogin,
        permissionCheckError02: () => {
          const { serviceMethod } = JSON.parse(requesOptions.body).params;
          const { userLevel } = store.getState().userStore.userInfo;
          // userLevel 0 为普通用户，额度用完需弹出支付弹窗
          if (userLevel === 0) {
            showLogin();
          } else {
            // 其他为付费用户，当天额度用完需提示当天额度已用完
            // 目前 userLevel 1 2 3 的 text2Image|colorization|nsfw|styleTransfer|imageInpainting|imageBgRemove|imageEnhance|downloadLargeImage 额度都为100
            // uploadImageToGallery 为1000
            Message({
              type: "warning",
              message: `您的当天额度${
                serviceMethod === "uploadImageToGallery" ? 1000 : 100
              }次已用完，请明天再试`,
              duration: 3000,
            });
          }
        },
        // 图片下载失败时
        saleDisallowed: showCommonErrorModal(
          <div>
            <img style={{ width: 150, height: 150 }} src={apiContactQRcode} />
            <p style={{ fontSize: 18 }}>图片下载失败，请联系客服</p>
          </div>
        ),
        // 登录用户额度不够提示
        ServicePlanQuotaNotEnoughError: showCommonErrorModal(
          <div>
            {/* <img style={{ width: 150, height: 150 }} src={apiContactQRcode} /> */}
            <p style={{ fontSize: 18 }}>用户免费额度已用完，请购买套餐</p>
          </div>
        ),
        // 第三方图库导致的图片下载失败处理
        ErrorDownloadImage: showCommonErrorModal(
          <div>
            <img style={{ width: 150, height: 150 }} src={apiContactQRcode} />
            <p style={{ fontSize: 18 }}>图片下载失败，请联系客服</p>
          </div>
        ),
        haveSensitiveWords: showCommonErrorModal("敏感词，请重新输入！"),
        sensitiveWordsDetected: showCommonErrorModal("敏感词，请重新输入！"),
        // 游客搜索非指定关键词的时候
        ONLY_LIMTED_KWS_SEARCH_ALLOWED_FOR_GUEST: showLogin,
        validSpExisted: showCommonErrorModal("请您使用完当前套餐之后再购买"),
      };
      if (errorHandler[json.errorCode]) {
        errorHandler[json.errorCode]();
      } else if (!JSON.parse(requesOptions.body).params.noFailTip) {
        // noFailTip表示为不弹出通用的请求错误提示接口
        Message({
          message: json.error || json.errorCode,
        });
      }
      return { error: json };
    }
    return { result: json };
  } catch (err) {
    return { error: "Something went wrong" };
  }
};
