import styled from "styled-components";

export const FooterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 54px 0;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  background: #000000;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      padding: 0.6rem 0.4rem 0.54rem 0.4rem;
      font-size: 0.16rem;
      overflow: hidden;
      .logo {
        width: 1.76rem;
        height: 0.5rem;
      }
      .h5-grid {
        align-items: flex-start;
        width: 100vw;
        flex-basis: 100%;
        &.code-container {
          padding-top: 0.75rem !important;
          align-items: center;
        }
      }
      .footer-link-grid {
        align-items: center;
        margin-top: 0.56rem;
        // &:first-child {
        //   align-items: flex-start;
        // }
        // &:last-child {
        //   align-items: flex-end;
        // }
      }
      .footer-version {
        flex-direction: column;
        align-items: center;
        margin-top: 0.6rem !important;
        > span {
          margin-top: 0.2rem;
        }
      }
    }
  }
  .logo {
    width: 176px;
    height: 51px;
  }
  .link {
    color: #ffffff;
  }
  .contact-item {
    text-align: center;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export const ToggleLanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.4rem;
      width: 100vw;
      .label {
        font-size: 0.18rem;
      }
      .btn {
        font-size: 0.18rem;
      }
      .icon {
        margin-left: 0.08rem;
      }
    }
  }
  .label {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
  }
  .btn {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
  }
  .icon {
    margin-left: 7px;
    transform: rotate(-90deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    &.active {
      transform: rotate(90deg);
    }
  }
`;

export const LinkListTitle = styled.h5`
  font-size: 18px;
  font-weight: bold;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    font-size: 0.18rem;
  }
`;

export const LinkListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.1rem;
      .link {
        margin-top: 0.34rem;
      }
    }
  }
  .link {
    margin-top: 34px;
    color: #ffffff;
  }
`;

export const ContactQrCode = styled.div`
  margin-top: 20px;
  width: 90px;
  height: 90px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.2rem;
      width: calc(50vw - 0.4rem);
      height: 1.5rem;
      text-align: center;
      .img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .img {
    width: 90px;
    height: 90px;
  }
`;
