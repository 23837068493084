import styled from "styled-components";
import DropFileBox from "../dropFileBox";

export const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  height: 700px;
  background: linear-gradient(-90deg, #2d52ca 0%, #3d96ff 100%);
  .search-box {
    margin-top: 67px;
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    padding: 1.2rem 0 0.5rem 0;
    height: auto;
    background: linear-gradient(-90deg, #2d52ca 0%, #3d96ff 100%);
    .search-box {
      margin-top: 0.2rem;
      margin-left: 0;
    }
  }
`;

export const StyledSearchTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  .search-logo {
    margin-left: 18px;
    width: 200px;
    height: 86px;
    background: #ffffff;
    border-radius: 20px;
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    width: 80vw;
    font-size: 0.48rem;
    .search-logo {
      margin-left: 0.18rem;
      width: 2rem;
      height: 0.86rem;
      border-radius: 0.2rem;
    }
  }
`;

export const StyledSearchBox = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  .select {
    width: 22%;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .search-btn-wrapper {
    height: 60px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #3d96ff;
  }
  .search-btn {
    cursor: pointer;
  }
  .other-search-types {
    display: flex;
    align-items: center;
    .sentence-switch {
      margin-left: 15px;
      width: 130px;
      .Mui-checked {
        color: #3d96ff;
        + .MuiSwitch-track {
          background-color: #3d96ff;
        }
      }
      &.white {
        color: #fff;
        .MuiSwitch-switchBase {
          color: #ddd;
        }
        .Mui-checked {
          color: #fff;
          + .MuiSwitch-track {
            background-color: #fff;
          }
        }
      }
    }
    .icon-search-btn {
      margin-left: 15px;
      width: 28px;
      cursor: pointer;
    }
  }
  &.top {
    .select {
      width: 18%;
      font-size: 14px;
    }
    .search-btn-wrapper {
      height: 40px;
    }
    .search-btn {
      width: 22px;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    width: 100%;
    padding: 0.4rem 0.2rem;
    .MuiPaper-root {
      padding: 0 0.3rem 0 0.2rem;
      height: 0.8rem;
    }
    .MuiInputBase-root {
      height: 100%;
      .MuiSelect-select {
        padding: 0 0 0 0.35rem !important;
      }
      .MuiSelect-iconOutlined {
        left: 0;
        top: 50%;
        width: 0.3rem;
        height: 0.3rem;
        font-size: 0.3rem;
        transform: translateY(-50%);
      }
    }
    .select {
      width: 35%;
      .MuiSelect-select {
        font-size: 0.2rem;
      }
    }
    .MuiInputBase-input {
      font-size: 0.2rem;
    }
    .search-btn-wrapper {
      min-width: 1rem;
      height: 0.8rem;
      .search-btn {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
    .MuiDivider-root {
      height: 100%;
    }
    .other-search-types {
      .sentence-switch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1rem;
        font-size: 0.14rem;
      }
      .icon-search-btn {
        margin-left: 0.15rem;
        width: 0.4rem;
      }
    }
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin-left: 25px;
  flex: 1;
  .suggest-list {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: -10px;
    width: 50%;
    padding: 10px 0;
    max-height: 200px;
    border: 1px solid #999;
    overflow-y: auto;
    background-color: #fff;
    display: none;
    &.show {
      display: block;
    }
    .suggest-item {
      padding: 0 20px;
      height: 30px;
      font-size: 14px;
      color: #666;
      line-height: 30px;
      cursor: pointer;
      transition: color 0.3s ease;
      &:hover {
        color: rgb(85, 85, 255);
        background-color: #ededed;
      }
    }
  }
  .history-wrapper {
    position: absolute;
    left: -20px;
    bottom: -70px;
    display: flex;
    .history-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 14px;
      width: 180px;
      height: 36px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      > div {
        cursor: pointer;
      }
      &:not(:first-child) {
        margin-left: 20px;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .string {
        margin-left: 12px;
        display: inline-block;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    margin-left: 0.25rem;
    height: 0.8rem;
    display: flex;
    .suggest-list {
      position: absolute;
      z-index: 2;
      top: 1rem;
      left: -0.5rem;
      width: 60vw;
      padding: 0.1rem 0;
      max-height: 2rem;
    }
    .history-wrapper {
      display: none;
    }
  }
`;

export const StyledThemeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 100px;
  }
  .img {
    width: 150px;
    height: 150px;
    background: url() center center / cover no-repeat;
    border: 5px solid #80acee;
    border-radius: 50%;
  }
  .text {
    margin-top: 20px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
  }
`;

export const StyledThemeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-left: -20px;
  max-width: 1260px;
  .theme {
    margin-top: 20px;
    margin-left: 20px;
    width: 70px;
    color: #fff;
    background-color: transparent;
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    max-width: 7rem;
    margin-top: 0;
    margin-left: 0;
    .theme {
      margin-top: 0.2rem;
      margin-left: 0.2rem;
      width: 1.4rem;
      font-size: 0.14rem;
    }
  }
`;

export const StyledSearchResultWrapper = styled.div`
  display: flex;
  height: calc(100vh - 65px);
  @media screen and (min-width: 300px) and (max-width: 750px) {
    height: calc(100vh - 2.6rem);
    overflow: auto;
  }
`;

export const StyledSearchFilters = styled.div`
  && {
    position: relative;
    padding: 50px;
    width: 360px;
    height: 100%;
    overflow-y: auto;
    background: #f6f6ff;
    transition: width 0.3s ease;
    &.collapse {
      width: 40px;
      padding: 0;
    }
    .collapse-icon {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &.collapse {
        transform: rotate(180deg);
      }
    }
    .my-gallery {
      display: flex;
      align-items: center;
      .icon {
        margin-left: 10px;
        fill: #3d96ff;
      }
    }
    .free-switch {
      margin-top: 10px;
    }
    .MuiAccordion-root {
      background: transparent;
      box-shadow: none;
      &:not(:first-child) {
        margin-top: 40px;
      }
      &::before {
        opacity: 0;
      }
    }
    .MuiAccordionSummary-root {
      padding: 0;
      min-height: 40px;
    }
    .Mui-expanded {
      margin: 0;
    }
    .MuiAccordionDetails-root {
      margin-top: 5px;
      margin-left: -10px;
      padding: 0;
    }
    .title-icon {
      width: 24px;
      height: 24px;
      fill: #f6f6ff;
    }
    .category-title {
      margin-left: 20px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
    }
    .btn {
      margin-top: 15px;
      margin-left: 10px;
      border-radius: 6px;
      &.MuiButton-outlined {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #666666;
        background: #f6f6ff;
        border: 1px solid #999999;
      }
      &.MuiButton-contained {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #ffffff;
        background: #3d96ff;
      }
    }
  }
`;

export const StyledSearchImgBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 0;
  .img-box {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: background-color 0.3s ease;
    }
    &:hover {
      &::after {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .delete {
        fill: #fff;
      }
    }
    .delete {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      fill: transparent;
      transform: translate(-50%, -80%);
      z-index: 1;
      transition: fill 0.3s ease;
      cursor: pointer;
    }
    .img {
      margin-bottom: 4px;
      max-width: 80%;
      max-height: 80%;
    }
  }
  .color {
    margin-left: 20px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    .text {
      margin-right: 10px;
      font-size: 16px;
    }
    .del-icon {
      cursor: pointer;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    margin-right: 0.2rem;
    .img-box {
      margin-top: 0.2rem;
      width: 1rem;
      height: 1rem;
      border-radius: 0.05rem;
      font-size: 0.18rem;
      .delete {
        width: 0.2rem;
        height: 0.2rem;
      }
      .img {
        margin-bottom: 0.05rem;
        max-width: 80%;
        max-height: 80%;
      }
    }
  }
`;

export const StyledSearchSortWrapper = styled.div`
  margin-left: -10px;
  display: flex;
  .btn {
    margin-left: 10px;
    width: 120px;
    height: 36px;
    border-radius: 18px;
  }
  .btn.MuiButton-contained {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #ffffff;
    background: #3d96ff;
  }
  .btn.MuiButton-text {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #666666;
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    .btn {
      margin-left: 0.1rem;
      width: 2rem;
      height: 0.6rem;
      border-radius: 0.2rem;
    }
    .btn.MuiButton-contained {
      font-size: 0.16rem;
    }
    .btn.MuiButton-text {
      font-size: 0.16rem;
    }
  }
`;

export const StyledSearchTagWrapper = styled.div`
  margin-left: -20px;
  display: flex;
  .MuiChip-root {
    margin-left: 20px;
  }
`;

export const StyledSearchResult = styled.div`
  width: calc(100vw - 350px);
  height: 100%;
  padding: 20px 0 0 60px;
  transition: width 0.3s ease;
  &.collapse {
    width: calc(100vw - 40px);
  }
  .flex-box {
    position: relative;
    display: flex;
    align-items: center;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
    .MuiToggleButton-root {
      width: 60px;
      height: 36px;
      border: 1px solid #3d96ff;
      &.Mui-selected {
        background: #3d96ff;
      }
      &:first-of-type {
        border-radius: 10px 0 0 10px;
      }
      &:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .bottom-text {
    text-align: center;
  }
  // .total-num-box {
  //   height: 40px;
  //   font-size: 18px;
  //   font-weight: 600;
  //   line-height: 40px;
  // }
  .water-fall-img-list-container {
    margin-top: 10px;
    padding-right: 60px;
    height: calc(100% - 46px);
    overflow-y: scroll;
    .pagination {
      margin: 30px 0 10px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .Mui-selected {
        background-color: #3ba7fc;
        &:hover {
          background-color: #3ba7fc;
        }
      }
      .jump {
        font-size: 14px;
        .MuiInput-input {
          width: 30px;
          padding: 0 10px;
          text-align: center;
        }
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    padding: 0 0 0.5rem 0.5rem;
    width: 100vw;
    // .total-num-box {
    //   height: 0.6rem;
    //   font-size: 0.2rem;
    //   line-height: 0.6rem;
    // }
    .water-fall-img-list-container {
      margin-top: 0.6rem;
      padding-right: 0.4rem;
      height: calc(100% - 1rem);
      overflow-y: scroll;
    }
  }
`;

export const StyledSearchWrapper = styled(DropFileBox)`
  display: flex;
  align-items: center;
  margin-top: 35px;
  padding: 30px;
  border: 1px dashed #a1deff;
  border-radius: 20px;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #3ba7fc;
  }
  &.column {
    flex-direction: column;
    .result-image {
      position: relative;
      width: 100%;
      height: 300px;
      background: url() center center / contain no-repeat;
      .del-icon {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .search-input {
    flex: 1;
    height: 40px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 10px;
    .MuiInputBase-input {
      padding-left: 20px;
    }
  }
  .dialog-search-btn {
    &.upload-btn {
      margin-top: 25px;
      margin-left: 0;
    }
    margin-left: 25px;
    height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 10px;
    background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
  }
  .upload-img {
    width: 261px;
    height: 153px;
  }
  .upload-title {
    margin-top: 20px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .upload-desc {
    margin-top: 20px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #666666;
  }
  .btn {
    width: 120px;
    height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 10px;
    background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    &:first-of-type {
      margin-right: 20px;
    }
    &.Mui-disabled {
      color: #fff;
      background: #999999;
    }
  }
  .hide-input {
    display: none;
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    margin-top: 0.35rem;
    padding: 0.3rem;
    border-radius: 0.2rem;
    .search-input {
      height: 0.6rem;
      border-radius: 0.1rem;
      .MuiInputBase-input {
        padding-left: 0.2rem;
        font-size: 0.18rem;
      }
    }
    .dialog-search-btn {
      &.upload-btn {
        margin-top: 0.25rem;
      }
      margin-left: 0.25rem;
      height: 0.6rem;
      font-size: 0.18rem;
    }
    .upload-img {
      width: 2.6rem;
      height: 1.5rem;
    }
    .upload-title {
      margin-top: 0.2rem;
      font-size: 0.16rem;
    }
    .upload-desc {
      margin-top: 0.2rem;
      font-size: 0.14rem;
    }
    .btn {
      width: 1.8rem;
      height: 0.6rem;
      font-size: 0.18rem;
      border-radius: 0.1rem;
      &:first-of-type {
        margin-right: 0.2rem;
      }
    }
  }
`;

export const StyledColorBody = styled.div`
  display: flex;
  flex-direction: column;
  .color-values {
    margin: 30px 0 20px 0;
    display: flex;
    height: 100px;
    .color {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 20px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      border-radius: 10px;
      &:not(:first-child) {
        margin-left: 10px;
      }
      .del-color {
        cursor: pointer;
      }
    }
    .add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      color: #666;
      border: 1px dashed #666666;
      border-radius: 10px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        color: #38a8fc;
        border-color: #38a8fc;
        .add-icon {
          border-color: #38a8fc;
        }
      }
      .add-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #666;
        border-radius: 50%;
        transition: border-color 0.3s ease;
      }
      .add-text {
        margin-top: 5px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
      }
    }
  }
  .select-colors {
    width: 100%;
    padding: 40px;
    border-radius: 20px;
    border: 1px dashed #d1d1d1;
    .select-colors-item {
      display: flex;
      align-items: center;
      height: 40px;
      &.mt30 {
        margin-top: 30px;
      }
      .label {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
      }
      .color-item {
        margin-left: 20px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50% !important;
        cursor: pointer;
      }
    }
    .desc {
      margin-top: 30px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .MuiButtonBase-root {
      width: 160px;
      height: 40px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      border-radius: 10px;
      &.MuiButton-contained {
        color: #ffffff;
        background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
      }
      &.MuiButton-outlined {
        color: #3ba7fc;
        border-color: #3ba7fc;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    .color-values {
      margin: 0.3rem 0 0.2rem 0;
      height: 1rem;
      .color {
        position: relative;
        padding: 0.2rem;
        font-size: 0.16rem;
        border-radius: 0.1rem;
        max-width: 22%;
        &:not(:first-child) {
          margin-left: 0.1rem;
        }
        .del-color {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
        }
      }
      .add-btn {
        width: 1rem;
        min-width: 0.8rem;
        height: 1rem;
        border-radius: 0.1rem;
        .add-icon {
          display: none;
        }
        .add-text {
          margin-top: 0.05rem;
          font-size: 0.16rem;
        }
      }
    }
    .select-colors {
      padding: 0.4rem;
      border-radius: 0.2rem;
      .select-colors-item {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        &.mt30 {
          margin-top: 0.3rem;
        }
        .label {
          font-size: 0.16rem;
        }
        .color-item {
          margin-left: 0.2rem;
          width: 0.4rem;
          height: 0.4rem;
          min-width: 0.4rem;
          margin-top: 0.2rem;
        }
      }
      .desc {
        margin-top: 0.3rem;
        font-size: 0.16rem;
      }
    }
    .footer {
      margin-top: 0.3rem;
      .MuiButtonBase-root {
        width: 2rem;
        height: 0.6rem;
        font-size: 0.18rem;
        border-radius: 0.1rem;
        &:last-child {
          margin-left: 0.2rem;
        }
      }
    }
  }
`;

export const SearchResultFeedback = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  display: flex;
  align-items: center;
  .rate-txt {
    margin-left: 10px;
    width: 60px;
    white-space: no-wrap;
  }
`;
