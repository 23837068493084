import React, { useRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { StyledVerifyCodeWrapper } from "../sendVerifyCode/index.styled";
import usePhoneScreen from "../../hooks/usePhoneScreen";

const ImgCode = ({
  backgroundColorMin = 230,
  backgroundColorMax = 250,
  onGetRealImgCode = () => {},
  ...props
}) => {
  const [imgCode, setImgCode] = useState("");

  const { isPhoneScreen } = usePhoneScreen();

  const contentWidth = isPhoneScreen ? 70 : 120;
  const contentHeight = isPhoneScreen ? 30 : 60;
  const fontSizeMin = isPhoneScreen ? 30 : 40;
  const fontSizeMax = isPhoneScreen ? 30 : 50;
  const canvasRef = useRef(null);

  // 生成一个随机数
  const randomNum = (min, max) => Math.floor(Math.random() * (max - min) + min);
  // 生成一个随机的颜色
  const randomColor = (min, max) => {
    const r = randomNum(min, max);
    const g = randomNum(min, max);
    const b = randomNum(min, max);
    return "rgb(" + r + "," + g + "," + b + ")";
  };
  const drawPic = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.textBaseline = "bottom";
    // 绘制背景
    ctx.fillStyle = randomColor(backgroundColorMin, backgroundColorMax);
    ctx.fillRect(0, 0, contentWidth, contentHeight);
    // 绘制文字
    for (let i = 0; i < imgCode.length; i++) {
      drawText(ctx, imgCode[i], i);
    }
    drawLine(ctx);
    drawDot(ctx);
  };
  const drawText = (ctx, txt, i) => {
    ctx.fillStyle = randomColor(10, 100); // 随机生成字体颜色
    ctx.font = randomNum(fontSizeMin, fontSizeMax) + "px SimHei"; // 随机生成字体大小
    const x = (i + 1) * (contentWidth / (imgCode.length + 1));
    const y = randomNum(fontSizeMax, contentHeight - 5);
    var deg = randomNum(-20, 20);
    // 修改坐标原点和旋转角度
    ctx.translate(x, y);
    ctx.rotate((deg * Math.PI) / 180);
    ctx.fillText(txt, 0, 0);
    // 恢复坐标原点和旋转角度
    ctx.rotate((-deg * Math.PI) / 180);
    ctx.translate(-x, -y);
  };
  const drawLine = (ctx) => {
    // 绘制干扰线
    for (let i = 0; i < 4; i++) {
      ctx.strokeStyle = randomColor(120, 200);
      ctx.beginPath();
      ctx.moveTo(randomNum(0, contentWidth), randomNum(0, contentHeight));
      ctx.lineTo(randomNum(0, contentWidth), randomNum(0, contentHeight));
      ctx.stroke();
    }
  };
  const drawDot = (ctx) => {
    // 绘制干扰点
    for (let i = 0; i < 30; i++) {
      ctx.fillStyle = randomColor(0, 255);
      ctx.beginPath();
      ctx.arc(
        randomNum(0, contentWidth),
        randomNum(0, contentHeight),
        1,
        0,
        2 * Math.PI
      );
      ctx.fill();
    }
  };

  const refreshCode = () => {
    const generetaNums = () =>
      Array(10)
        .fill(0)
        .map((_, i) => String(i));
    const generetaChars = () => {
      const strs = [];
      for (let i = "A".charCodeAt(); i <= "Z".charCodeAt(); i++) {
        strs.push(String.fromCharCode(i));
      }
      return strs;
    };

    const codes = [...generetaNums(), ...generetaChars()]
      .sort(() => Math.random() - Math.random())
      .slice(0, 4);
    setImgCode(codes);
    onGetRealImgCode(codes.join(""));
  };

  useEffect(refreshCode, []);
  useEffect(drawPic, [imgCode]);

  return (
    <StyledVerifyCodeWrapper>
      <TextField
        className="input code"
        inputProps={{ maxLength: 4 }}
        placeholder="请输入图形验证码"
        {...props}
      />
      <Button variant="text" className="img" onClick={refreshCode}>
        <canvas
          ref={canvasRef}
          width={contentWidth}
          height={contentHeight}
        ></canvas>
      </Button>
    </StyledVerifyCodeWrapper>
  );
};

export default ImgCode;
