import styled from "styled-components";
import Button from "@mui/material/Button";

export const StyledRectImageWrapper = styled.div`
  position: relative;
  width: 124px;
  height: 124px;
  background: url(${({ url }) => url}) center center / cover;
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    .operation {
      opacity: 1;
    }
  }
  .operation {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 10px 10px 0;
    width: 100%;
    height: 100%;
    background-color: #33333399;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

export const StyledOperationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const StyledBtn = styled(Button)`
  && {
    width: 80px;
    height: 30px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    // color: #666666;
    white-space: nowrap;
    // background: #fff;
    border-radius: 10px;
    &.MuiButton-sizeSmall {
      padding: 0;
      width: 60px;
      height: 24px;
      font-size: 10px;
      &:not(:first-child) {
        margin-top: 6px;
      }
      > div {
        transform: scale(0.8);
      }
    }
    > div {
      margin-right: 5px;
    }
    &:not(:first-child) {
      margin-top: 8px;
    }
    & {
      color: #fff;
      background: #3ba7fc;
      > div {
        > svg {
          filter: drop-shadow(#fff 16px 0);
        }
      }
      > svg {
        fill: #fff;
      }
      &:hover {
        background: #3ba7fc;
      }
    }
  }
`;

export const StyledImageContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* max-width: 1400px; */
  // height: 480px;
  .right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 300px;
  }
  .title {
    position: relative;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .desc {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #333333;
    &.mt20 {
      margin-top: 20px;
    }
    &.ml20 {
      margin-left: -20px;
    }
    .flex-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    background: #f9f9f9;
    border-radius: 20px;
    &:not(:first-child) {
      margin-top: 10px;
    }
    .favourite-btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  .card-btn-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    padding: 5px 15px;
    font-size: 12px;
    border: 1px solid #999999;
    border-radius: 6px;
    cursor: pointer;
  }
  .color {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-left: 20px;
    margin-top: 20px;
    border: 1px solid #999999;
    border-radius: 6px;
    cursor: pointer;
  }
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    .item {
      :first-of-type {
        margin-top: 20px;
      }
      display: flex;
      align-items: center;
      white-space: nowrap;
      &.pointer {
        cursor: pointer;
      }
      .txt {
        :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .btn-wrapper {
      position: relative;
      z-index: 1;
      &:hover {
        .download-wrapper {
          transform: scaleY(1);
        }
      }
    }
    .download-wrapper {
      position: absolute;
      z-index: -1;
      top: 40px;
      left: 0;
      padding-top: 10px;
      background-color: #fff;
      transform: scaleY(0);
      transform-origin: left top;
      transition: transform 0.3s ease;
    }
    .download-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 99%;
      padding: 15px 20px;
      border: 1px solid #3ba7fc;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .MuiButton-sizeSmall {
        width: 140px;
        height: 40px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
      }
      .size {
        font-size: 12px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #999999;
        line-height: 36px;
      }
    }
    .btn {
      margin: 0 auto;
      margin-top: 20px;
      width: 140px;
      height: 40px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      border-radius: 25px;
      &.ml20 {
        margin-left: 20px;
      }
      &.download {
        margin-top: 10px;
      }
      > svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
      }
      &.MuiButton-contained {
        background: #3ba7fc;
        color: #ffffff;
        > svg {
          fill: #ffffff;
        }
      }
      &.MuiButton-outlined {
        color: #3ba7fc;
        border: 1px solid #3ba7fc;
        > svg {
          fill: #3ba7fc;
        }
      }
    }
  }
  .keywords {
    position: relative;
    margin-top: 50px;
    width: calc(740px + 30%);
    .show-more {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      color: #3ba7fc;
    }
    .words {
      margin-left: -20px;
      &.one-line {
        height: 40px;
        overflow-y: hidden;
      }
    }
    &.mt20 {
      margin-top: 20px;
      .card-btn-item {
        cursor: default;
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    .right {
      margin-left: 0;
      margin-top: 1.2rem;
      width: 90vw;
    }
    .title {
      font-size: 0.26rem;
    }
    .desc {
      font-size: 0.2rem;
      &.mt20 {
        margin-top: 0.2rem;
      }
      &.ml20 {
        margin-left: -0.2rem;
      }
    }
    .card {
      padding: 0.3rem 0.5rem;
      border-radius: 0.2rem;
      &:not(:first-child) {
        margin-top: 0.1rem;
      }
      .favourite-btn {
        > svg {
          margin-right: 0.1rem;
          width: 0.4rem;
          height: 0.4rem;
        }
        position: absolute;
        right: 0.2rem;
        bottom: 0.2rem;
        font-size: 0.2rem;
      }
    }
    .card-btn-item {
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      padding: 0.1rem 0.25rem;
      font-size: 0.18rem;
      border-radius: 0.1rem;
    }
    .color {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      border-radius: 0.1rem;
    }
    .btns {
      .item {
        :first-of-type {
          margin-top: 0.2rem;
        }
        .MuiSvgIcon-root {
          width: 0.5rem;
          height: 0.5rem;
        }
        .txt {
          font-size: 0.24rem;
          :not(:last-child) {
            margin-right: 0.1rem;
          }
        }
      }
      .download {
        margin-top: 0.1rem !important;
      }
    }
    .keywords {
      margin-top: 0.5rem;
      width: 90vw;
      .show-more {
        font-size: 0.22rem;
      }
      .words {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.2rem;
      }
      &.mt20 {
        margin-top: 0.2rem;
      }
    }
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 480px;
  background: #fff;
  border-radius: 20px;
  .img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .img-footer {
    position: absolute;
    width: 100%;
    bottom: -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #999999;
  }
  .ai-btns {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    width: 100%;
    bottom: -70px;
    .MuiButtonBase-root {
      width: 100px;
      height: 30px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      background: #3ba7fc;
      border-radius: 10px;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    width: 90vw;
    height: 5rem;
    background: #fff;
    border-radius: 0.2rem;
    .img-footer {
      font-size: 0.18rem;
      bottom: -0.4rem;
    }
    .ai-btns {
      bottom: -1.4rem;
      .MuiButtonBase-root {
        right: 0.2rem;
        bottom: 0.4rem;
        width: 2rem;
        height: 0.5rem;
        font-size: 0.24rem;
      }
    }
  }
`;

export const StyledDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      .MuiOutlinedInput-input {
        font-size: 0.14rem;
      }
      .MuiButtonBase-root.self-btn {
        width: 1.2rem;
        height: 0.6rem;
        font-size: 0.24rem;
        border-radius: 0.2rem;
      }
      .footer {
        margin-top: 0.6rem;
      }
    }
  }

  .MuiButtonBase-root.self-btn {
    width: 100px;
    height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    border-radius: 10px;
    &.MuiButton-contained {
      color: #ffffff;
      background: #3ba7fc;
      &.gradient-bg {
        background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
      }
    }
    &.MuiButton-outlined {
      color: #666;
      border: 1px solid #666666;
    }
    &.mr10 {
      margin-right: 10px;
    }
  }
  .add-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 20px 25px;
    .MuiAutocomplete-root {
      flex: 1;
      margin-right: 25px;
    }
    .MuiTextField-root {
      padding-left: 12px;
      width: 250px;
      min-height: 40px;
      background-color: #fff;
      border: 1px solid #999999;
      border-radius: 10px;
      .MuiInputBase-root {
        padding: 0;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-left: -10px;
    padding: 0 20px;
    .tag-item {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
    width: 100%;
  }
`;

export const AiImgScanningWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .scanning {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 50px;
    background: linear-gradient(180deg, transparent 0%, #20b7fe 100%);
    animation: scanning 5s linear infinite;
  }
  .progress {
    width: 60%;
    .progress-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .progress-box {
      margin-right: 20px;
      width: 80%;
      .MuiLinearProgress-bar {
        background: linear-gradient(
          -90deg,
          rgba(150, 109, 244, 0.2) 0%,
          #20b7fe 100%
        );
      }
    }
  }
  @keyframes scanning {
    0% {
      top: 0%;
      background: linear-gradient(180deg, transparent 0%, #20b7fe 100%);
    }
    46% {
      top: calc(100% - 10px);
      background: linear-gradient(180deg, transparent 0%, #20b7fe 100%);
    }
    50% {
      top: calc(100% + 10px);
      background: linear-gradient(0deg, transparent 0%, #20b7fe 100%);
    }
    96% {
      top: -40px;
      background: linear-gradient(0deg, transparent 0%, #20b7fe 100%);
    }
    100% {
      top: 0%;
      background: linear-gradient(180deg, transparent 0%, #20b7fe 100%);
    }
  }
`;

export const MoreAiBtns = styled.div`
  position: relative;
  &:hover {
    .popover {
      transform: scaleY(100%);
    }
  }
  .MuiButtonBase-root {
    margin-right: 0;
    width: 100px;
    height: 30px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #3ba7fc;
    background: #fff;
    border-radius: 0;
    white-space: nowrap;
  }
  .popover {
    position: absolute;
    left: 0;
    top: 40px;
    padding: 5px 0;
    background-color: #fff;
    width: calc(100% - 10px);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    transform: scaleY(0%);
    transition: transform 0.3s ease;
    transform-origin: top center;

    .btn {
      height: 32px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #3ba7fc;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;
