import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledQrCodeWrapper } from "./index.styled";
import QRCode from "qrcode";

const PayQrCode = ({ loading, url, refresh }) => {
  const [qrCodeSrc, setQrCodeSrc] = useState("");

  useEffect(() => {
    if (!url) {
      return;
    }
    QRCode.toDataURL(url).then((src) => {
      setQrCodeSrc(src);
    });
  }, [url]);

  return (
    <StyledQrCodeWrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="qr-code" onClick={refresh}>
            <img src={qrCodeSrc} alt="" width="100%" height="100%" />
          </div>
          <div className="text">扫码支付</div>
        </>
      )}
    </StyledQrCodeWrapper>
  );
};

export default PayQrCode;
