import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import useToggleMenu from "../../hooks/useToggleMenu";
import { ToggleLanguageWrapper } from "./index.styled";

const ToggleLanguage = () => {
  const { anchorEl, open, openMenu, handleClose } = useToggleMenu();

  return (
    <ToggleLanguageWrapper>
      <label className="label">语言：</label>
      <Button variant="text" className="btn" onClick={openMenu}>
        简体中文
        <ArrowBackIosOutlined
          className={`icon ${open ? "active" : ""}`}
          fontSize="12"
        />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>简体中文</MenuItem>
        <MenuItem onClick={handleClose}>English</MenuItem>
      </Menu>
    </ToggleLanguageWrapper>
  );
};

export default ToggleLanguage;
