import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useGetImages from "./useGetImages";
import { setUserAuthenticated } from "../redux/Reducers/userReducer";
import { getBase64 } from "../utilities/Utilities";

const useLogin = ({
  dispatch,
  loginFn = () => Promise.reject("需传递login异步函数调用"),
}) => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { loginResearch } = useGetImages();
  const { tencentCos } = useSelector((state) => {
    return {
      tencentCos: state?.imageStore?.tencentCos,
    };
  });

  const login = () => {
    setLoading(true);
    loginFn()
      .then((res) => {
        if (res.payload.error || !res.payload.userInfo) {
          throw new Error(res.payload.error);
        }
        const userInfo = res.payload.userInfo;
        // 如果头像是 imageName形式，还要通过 tencentCos 获取头像
        const headImageUrl = userInfo.headImageUrl;

        if (!headImageUrl || /^https?:\/\//.test(headImageUrl)) {
          dispatch(
            setUserAuthenticated({
              userInfo: res.payload,
              isAuthenticated: true,
            })
          );
        } else {
          tencentCos.downloadImage(headImageUrl, function (err, data) {
            if (err) {
              console.error(err);
            } else {
              getBase64(data.Body).then((url) => {
                dispatch(
                  setUserAuthenticated({
                    userInfo: {
                      ...res.payload,
                      headImageUrl: url,
                    },
                    isAuthenticated: true,
                  })
                );
              });
            }
          });
        }
        dispatch({
          type: "user/setUserAuthenticated",
          payload: { userInfo },
        });
        dispatch({
          type: "user/setLoginModalState",
          payload: { isLoginModalOpen: false },
        });
        setLoading(false);

        // 登录成功后如果在搜索页，自动重新搜索
        if (pathname === "/gallery/search") {
          loginResearch({ userId: userInfo.userId });
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return {
    loading,
    login,
  };
};

export default useLogin;
