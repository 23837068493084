import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { StyledDialogTitle } from "../guideBanner/index.styled";
import { ReactComponent as IconClose } from "../../assets/images/guides/icon_close.svg";

const CommonModal = () => {
  const dispatch = useDispatch();
  const {
    open = false,
    content = "",
    handleCloseFn = () => {},
  } = useSelector((state) => state?.userStore?.commonErrorModal ?? {});

  const handleClose = () => {
    handleCloseFn();
    dispatch({
      type: "user/setCommonErrorModalState",
      payload: {
        open: false,
        content: "",
        handleCloseFn: () => {},
      },
    });
  };

  return (
    <Dialog
      classes={{
        paper: `common-modal`,
      }}
      maxWidth={false}
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle>
        <h5 className="title">温馨提示</h5>
        <div className="close" onClick={handleClose}>
          <IconClose />
        </div>
      </StyledDialogTitle>
      <span className="content">{content}</span>
      <Button variant="contained" onClick={handleClose}>
        我知道了
      </Button>
    </Dialog>
  );
};

export default CommonModal;
