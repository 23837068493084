import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledPayWrapper } from "./index.styled";
import Dialog from "@mui/material/Dialog";
import Message from "../message";
import PayTypes from "./payTypes";
import PayQrCode from "./payQrCode";
import RealPayText from "./realPayText";
import { loginAsyncThunk } from "../../redux/Actions/userActions";
import { setUserAuthenticated } from "../../redux/Reducers/userReducer";
import { ReactComponent as IconClose } from "../../assets/images/guides/icon_close.svg";

const PRICE_TYPES = [
  {
    tag: "限时优惠",
    tagStyle: {
      background: "linear-gradient(90deg, #FF6867 0%, #F12F1A 100%)",
    },
    keys: [
      {
        servicePlanKey: "PL-1-15",
        price: 60,
      },
      {
        servicePlanKey: "PL-1-100",
        price: 400,
      },
    ],
  },
  {
    tag: "限时优惠",
    tagStyle: {
      background: "linear-gradient(90deg, #FFA767 0%, #F19E1A 100%)",
    },
    keys: [
      {
        servicePlanKey: "SL-1-1",
        price: 6,
      },
      {
        servicePlanKey: "SL-1-5",
        price: 30,
      },
    ],
  },
  {
    tag: "限时优惠",
    tagStyle: {
      background: "linear-gradient(90deg, #5E32EE 0%, #5E32EE 100%)",
    },
    keys: [
      {
        servicePlanKey: "EL-1-2",
        price: 80,
      },
      {
        servicePlanKey: "EL-1-10",
        price: 400,
      },
    ],
  },
];

const PayModal = () => {
  const dispatch = useDispatch();
  const { isPayModalOpen, userId } = useSelector((state) => {
    return {
      isPayModalOpen: state?.userStore?.isPayModalOpen,
      userId: state?.userStore?.userInfo?.userId,
    };
  });
  const handleClose = () => {
    dispatch({
      type: "user/setPayModalState",
      payload: {
        isPayModalOpen: false,
      },
    });
  };

  const [payUrl, setPayUrl] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);

  const [payData, setPayData] = useState({
    servicePlanKey: "",
    price: 0,
  });

  // 获取支付订单id和支付二维码
  const getPayUrl = () => {
    if (!payData.servicePlanKey) {
      return;
    }
    setLoading(true);
    dispatch(
      loginAsyncThunk({
        params: {
          serviceMethod: "initOrder",
          servicePlanKey: payData.servicePlanKey,
          userId,
        },
      })
    )
      .then((res) => {
        if (res?.payload?.wxPayCode) {
          setOrderId(res.payload.orderId);
          setPayUrl(res.payload.wxPayCode);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => getPayUrl(), [payData.servicePlanKey]);

  // 检查支付状态
  const checkOrderStatus = (orderId) => {
    if (!orderId) {
      return;
    }
    dispatch(
      loginAsyncThunk({
        params: {
          serviceMethod: "getOrderStatus",
          orderId,
          userId,
        },
      })
    )
      .then((res) => {
        if (res?.payload?.orderStatus === "paid") {
          clearInterval(timer.current);
          timer.current = null;
          Message({
            type: "success",
            message: "支付成功",
          });
          dispatch({
            type: "user/setPayModalState",
            payload: {
              isPayModalOpen: false,
            },
          });
          return dispatch(
            loginAsyncThunk({
              params: {
                serviceMethod: "getUserInfo",
                userId,
              },
            })
          );
        } else {
          return null;
        }
      })
      .then((res) => {
        if (res?.payload?.userId) {
          dispatch(
            setUserAuthenticated({
              userInfo: res.payload,
              isAuthenticated: true,
            })
          );
        }
      });
  };

  const timer = useRef(null);
  useEffect(() => {
    // 轮询支付状态及取消定时器
    if (loading) {
      clearInterval(timer.current);
      timer.current = null;
      return;
    }
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (!orderId) {
      return;
    }
    timer.current = setInterval(() => {
      checkOrderStatus(orderId);
    }, 2000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [orderId, loading]);

  const reSelect = () => {
    // 重新选择套餐
    setPayData({
      servicePlanKey: "",
      price: 0,
    });
    setOrderId("");
    setPayUrl("");
  };

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (isPayModalOpen) {
      reSelect();
    }
  }, [isPayModalOpen]);

  return (
    <Dialog
      classes={{ paper: "pay-modal" }}
      maxWidth={false}
      open={isPayModalOpen}
    >
      <StyledPayWrapper>
        {/* 关闭按钮 */}
        <IconClose className="icon-close" onClick={handleClose} />
        {/* 套餐选择 */}
        <PayTypes
          priceTypes={PRICE_TYPES}
          confirmBuy={setPayData}
          currentPay={payData}
          PayCodeAndResult={
            // 选择套餐后展示二维码及支付说明
            loading || orderId ? (
              <>
                <PayQrCode loading={loading} url={payUrl} refresh={getPayUrl} />
                <RealPayText payValue={payData.price} reSelect={reSelect} />
              </>
            ) : null
          }
        />
      </StyledPayWrapper>
    </Dialog>
  );
};

export default PayModal;
