import COS from "cos-js-sdk-v5";
import { globalConfig } from "./staticObjects";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import shortUuid from "short-uuid";
import { BUCKET_NAME, REGION_NAME, FILE_NAME_PREFIX } from "../config";

export function isTop() {
  return window.top === window;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 字节";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["字节", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};

export const helloWord = () => {
  return "hello,world!";
};

export const checkUserPermission = (userInfo, requestPermission) => {
  let checkedPassed = false;
  if (!userInfo || !userInfo.permissions) {
    return checkedPassed;
  }

  const userPermissions = userInfo.permissions;
  checkedPassed = userPermissions.includes(requestPermission);

  return checkedPassed;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  // const byteArrays = Buffer.from(b64Data, "base64");
  const byteArrays = [];
  const byteCharacters = atob(b64Data);

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
class TencentCos {
  constructor(
    bucketName = BUCKET_NAME,
    regionName = REGION_NAME,
    fileNamePrefix = FILE_NAME_PREFIX
  ) {
    this.cos = new COS({
      getAuthorization: function (options, callback) {
        fetch(globalConfig.imageAppApi, {
          method: "POST",
          body: JSON.stringify({
            params: {
              serviceMethod: "tencentCosSts",
              bucketName: `${regionName}_${bucketName}`,
            },
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            const credentials = data?.credentials?.credentials ?? {};
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              ExpiredTime: data?.credentials?.expiredTime,
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      },
    });
    this.bucketName = bucketName;
    this.regionName = regionName;
    this.fileNamePrefix = fileNamePrefix;
  }

  uploadImage(imageData, imageIdKey, func, onProgress) {
    const url = null;

    this.cos.putObject(
      {
        Bucket: this.bucketName,
        Region: this.regionName,

        Key: this.fileNamePrefix + imageIdKey,
        Body: imageData,
        onProgress,
      },
      func
    );

    return url;
  }

  downloadImage(fileName, func, onProgress = () => {}, fileNamePrefix) {
    this.cos.getObject(
      {
        Bucket: this.bucketName,
        Region: this.regionName,
        Key: (fileNamePrefix || this.fileNamePrefix) + fileName,
        DataType: "blob",
        onProgress,
      },
      func
    );
  }
}
export const downloadImage = (
  imageBase64Data,
  imageName = `图芬奇-${shortUuid.generate()}.webp`
) => {
  var a = document.createElement("a"); //Create <a>
  a.href = imageBase64Data;
  a.download = imageName; //File name Here
  a.click(); //Downloaded file
};

export const downloadImageGivenUrl = (url, imgType, imageName) => {
  if (!url) {
    return;
  }
  const pointIndex = url.lastIndexOf(".");
  let type = imgType || "webp";
  if (!imgType && pointIndex > -1) {
    type = url.substring(pointIndex + 1);
  }
  const name = imageName
    ? `${imageName}.${type}`
    : `图芬奇-${shortUuid.generate()}.${type}`;

  return fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return true;
    })
    .catch((err) => {
      console.log(err);
      return true;
    });
};

export const base64Str2URL = (base64Str, fileType) => {
  {
    /* 
    "application/pdf"
    "image/webp"
    "image/jpeg"
    */
  }
  const blob = b64toBlob(base64Str, fileType);
  const pdfUrl = window.URL.createObjectURL(blob);
  console.log(pdfUrl);
  return pdfUrl;
};

export const downloadImagesAsZip = async (imageUrls, imgType) => {
  const zip = new JSZip();
  const promises = [];

  // Loop through the image URLs and add each image to the zip file
  imageUrls.forEach((url) => {
    promises.push(
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const type =
            imgType || url.substring(url.lastIndexOf(".") + 1) || "jpg";
          // Add the image to the zip file
          zip.file(`图芬奇-${shortUuid.generate()}.${type}`, blob);
        })
    );
  });

  // Wait for all the image downloads to complete
  await Promise.all(promises);

  // Generate the zip file
  const zipBlob = await zip.generateAsync({ type: "blob" });

  // Download the zip file
  saveAs(zipBlob, `图芬奇-${shortUuid.generate()}.zip`);
};

export const downloadDiffTypeImagesAsZip = async (images) => {
  const zip = new JSZip();
  const promises = [];

  // Loop through the image URLs and add each image to the zip file
  images.forEach(({ url, type, selfName }) => {
    promises.push(
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Add the image to the zip file
          zip.file(
            `${selfName || `图芬奇-${shortUuid.generate()}`}.${type}`,
            blob
          );
        })
    );
  });

  // Wait for all the image downloads to complete
  await Promise.all(promises);

  // Generate the zip file
  const zipBlob = await zip.generateAsync({ type: "blob" });

  // Download the zip file
  saveAs(zipBlob, `图芬奇-${shortUuid.generate()}.zip`);
};

export default TencentCos;
