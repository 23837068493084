import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Reducers/userReducer";
import imagesReducer from "./Reducers/imagesReducer";
import editorReducer from "./Reducers/editorReducer";
export const store = configureStore({
  reducer: {
    editorStore: editorReducer,
    imageStore: imagesReducer,
    userStore: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
