import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const StyledFeedbackWrapper = styled.div`
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #26b4fd;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  border: 1px solid #3d96ff;
  transform: translateX(70%);
  transition: transform 0.3s ease 0.3s;
  cursor: pointer;
  .icon {
    width: 16px;
    height: 16px;
  }
  :hover {
    transform: translateX(0);
    color: #fff;
    background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    .qrcode {
      opacity: 1;
    }
  }
  .qrcode {
    position: absolute;
    top: -250px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 240px;
    background: #ffffff;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: default;
    .qrcode-item {
      display: flex;
      flex-direction: column;
      :not(:first-child) {
        margin-top: 10px;
      }
    }
    @media screen and (min-width: 300px) and (max-width: 750px) {
      pointer-events: none;
    }
    &::after {
      content: "";
      position: absolute;
      height: 10px;
      width: 100%;
      bottom: -10px;
    }
    .code {
      width: 80px;
      height: 80px;
    }
    .text {
      margin-top: 5px;
      font-size: 12px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    border: 1px solid #999999;
    border-radius: 10px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledH5TextField = styled(TextField)`
  && {
    margin-top: 0.3rem;
    border-radius: 0.1rem;
  }
`;
