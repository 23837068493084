import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { LinkListTitle, LinkListWrapper } from "./index.styled";

const links = [
  {
    titleKey: "AI图像处理",
    children: [
      {
        key: "自动抠图",
        path: "/guide/auto-cutout",
      },
      {
        key: "局部消除",
        path: "/guide/locale-limination",
      },
      {
        key: "图片高清",
        path: "/guide/pic-hd",
      },
      {
        key: "头像动漫",
        path: "/guide/avatar-cartoon",
      },
      {
        key: "AI绘画",
        path: "/guide/ai-draw",
      },
    ],
  },
  {
    titleKey: "图片编辑",
    children: [
      {
        key: "图片加水印",
        path: "/guide/watermark",
      },
      {
        key: "图片上色",
        path: "/guide/pic-color",
      },
      {
        key: "风格迁移",
        path: "/guide/style-transfer",
      },
    ],
  },
  // {
  //   titleKey: "图片素材",
  //   children: [],
  // },
  // {
  //   titleKey: "开发者服务",
  //   children: [
  //     {
  //       key: "自动抠图API",
  //       path: "/developer-service",
  //     },
  //     {
  //       key: "局部消除API",
  //       path: "/developer-service",
  //     },
  //     {
  //       key: "图片高清API",
  //       path: "/developer-service",
  //     },
  //     {
  //       key: "图片恢复API",
  //       path: "/developer-service",
  //     },
  //     {
  //       key: "图片鉴黄API",
  //       path: "/developer-service",
  //     },
  //   ],
  // },
];

const Links = () => {
  return links.map(({ titleKey, children = [] }) => (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      key={titleKey}
      item
      className="footer-link-grid"
      xs={12 / links.length}
    >
      <LinkListTitle>{titleKey}</LinkListTitle>
      <LinkListWrapper>
        {children.map(({ key, path }) => (
          <Link key={key} href={path} underline="hover" className="link">
            {key}
          </Link>
        ))}
      </LinkListWrapper>
    </Grid>
  ));
};

export default Links;
