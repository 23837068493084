import React from "react";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
// import CountUp from "react-countup";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { StyledWaitProgressWrapper } from "./index.styled";
import waitProgressLogo from "../../assets/images/payModal/img_VIP_popup_wait@2x.png";

// const EXPLAINS = [
//   "高清大图不限下载次数",
//   "多种工具无限次使用",
//   "支持批处理/异步处理",
//   "专属VIP级响应速度",
// ];

const WaitProgressModal = () => {
  // const dispatch = useDispatch();
  const { isWaitProgress } = useSelector((state) => {
    return { isWaitProgress: state?.userStore?.isWaitProgress };
  });
  const {
    open,
    vip = true,
    progress = 0,
    loadingText = "正在执行您的任务，请稍后...",
  } = isWaitProgress;
  // const handleClose = () => {
  //   dispatch({
  //     type: "user/setWaitProgressModalState",
  //     payload: {
  //       isWaitProgress: {
  //         open: false,
  //         progress: 0,
  //       },
  //     },
  //   });
  // };

  return (
    <Dialog
      classes={{ paper: "pay-modal" }}
      maxWidth={false}
      open={open}
      // onClose={handleClose}
    >
      <StyledWaitProgressWrapper>
        {vip ? (
          <div className="banner">
            {/* <div className="left">
              <h5 className="title">成为会员，让图芬奇成为您的伴侣</h5>
              {EXPLAINS.map((text) => (
                <div className="explain-item" key={text}>
                  <SvgIcon fontSize={22} color="#7A4AE8" className="yes-icon">
                    <IconExplain />
                  </SvgIcon>
                  {text}
                </div>
              ))}
            </div> */}
            <div className="right">
              <img className="logo" src={waitProgressLogo} alt="" />
            </div>
          </div>
        ) : (
          <div className="circle">
            <CircularProgress />
          </div>
        )}
        <div className="progress">
          <div className="text">{loadingText}</div>
          <div className="progress-wrapper">
            <LinearProgress
              className="progress-box"
              variant="determinate"
              value={progress}
            />
            {/*<CountUp
              className="value"
              end={Math.round(progress || 0)}
              suffix="%"
            />*/}
          </div>
        </div>
      </StyledWaitProgressWrapper>
    </Dialog>
  );
};

export default WaitProgressModal;
