import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import SendVerifyCode from "../sendVerifyCode";
import ImgCode from "./ImgCode";
import { StyledInputWrapper, LoginBtn } from "./index.styled";
import {
  useValidateEmail,
  useValidateVerifyCode,
  useValidateImgCode,
} from "../../hooks/useValidate";
import useLogin from "../../hooks/useLogin";
import useBlind from "../../hooks/useBlind";
import usePhoneScreen from "../../hooks/usePhoneScreen";

import {
  loginAsyncThunk,
  sendVerifyCodeAsyncThunk,
} from "../../redux/Actions/userActions";

const TelephoneLoginForm = ({ onChangeFormType = () => {}, blind = false }) => {
  const { userInfo } = useSelector((state) => state?.userStore);
  const { isPhoneScreen } = usePhoneScreen();

  const dispatch = useDispatch();

  const {
    email,
    setEmail,
    errorText: emailErrorText,
    validateEmail,
  } = useValidateEmail();

  const {
    verifyCode,
    setVerifyCode,
    errorText: verifyCodeErrorText,
    validateVerifyCode,
  } = useValidateVerifyCode();

  const {
    imgCode,
    setImgCode,
    errorText: imgCodeErrorText,
    validateImgCode,
  } = useValidateImgCode();

  const [realImgCode, setRealImgCode] = useState("");
  const beforeSend = () => validateEmail() && validateImgCode(realImgCode);

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changeVerifyCode = (e) => {
    setVerifyCode(e.target.value);
  };

  const changeImgCode = (e) => {
    setImgCode(e.target.value);
  };

  const sendEmailVerifyCode = () =>
    dispatch(
      sendVerifyCodeAsyncThunk({
        params: {
          serviceMethod: "sendVerifiedCode",
          emailName: email,
          loginType: "email",
          blinedUserId: userInfo.userId,
          blindModel: blind,
        },
      })
    );

  const tempUserId = useRef(null);
  const setTempUserId = (userId) => {
    tempUserId.current = userId;
  };

  const { loading, login } = useLogin({
    dispatch,
    loginFn: () =>
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "loginNew",
            userId: tempUserId.current,
            loginType: "mobile",
            userInputVerifiedCode: verifyCode,
          },
        })
      ),
  });

  const handleLogin = () => {
    if (
      !validateEmail() ||
      !validateVerifyCode() ||
      !validateImgCode(realImgCode)
    ) {
      return;
    }

    login();
  };

  const { blind_op } = useBlind({
    dispatch,
    blindFn: () =>
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "updateUserInfo",
            userId: userInfo.userId,
            userInputVerifiedCode: verifyCode,
            emailName: email,
          },
        })
      ),
  });

  const handleBlind = () => {
    if (
      !validateEmail() ||
      !validateVerifyCode() ||
      !validateImgCode(realImgCode)
    ) {
      return;
    }

    blind_op();
  };

  return (
    <StyledInputWrapper>
      <TextField
        className="input"
        maxLength="30"
        error={Boolean(emailErrorText)}
        helperText={emailErrorText}
        fullWidth
        placeholder="请输入邮箱"
        value={email}
        onChange={changeEmail}
      />
      <ImgCode
        onGetRealImgCode={setRealImgCode}
        value={imgCode}
        onChange={changeImgCode}
        error={Boolean(imgCodeErrorText)}
        helperText={imgCodeErrorText}
      />
      <SendVerifyCode
        value={verifyCode}
        onChange={changeVerifyCode}
        error={Boolean(verifyCodeErrorText)}
        helperText={verifyCodeErrorText}
        beforeSend={beforeSend}
        sendFn={sendEmailVerifyCode}
        handleSendSuccess={setTempUserId}
        waitSecondLimit={180}
      />
      {!blind && (
        <>
          <LoginBtn disabled={loading} onClick={handleLogin}>
            登录
          </LoginBtn>
          <Stack className="btns" direction="row" spacing={4}>
            {!isPhoneScreen && (
              <span className="btn" onClick={() => onChangeFormType("weixin")}>
                微信登录
              </span>
            )}
            <span className="btn" onClick={() => onChangeFormType("account")}>
              账号登录
            </span>
            <span className="btn" onClick={() => onChangeFormType("telephone")}>
              手机号登录
            </span>
          </Stack>
        </>
      )}
      {blind && (
        <LoginBtn disabled={loading} onClick={handleBlind}>
          绑定
        </LoginBtn>
      )}
    </StyledInputWrapper>
  );
};

export default TelephoneLoginForm;
