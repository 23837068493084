import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApiAsyncCall } from "../../redux/Actions/imagesAction";

const sendVerifyCodeAsyncThunk = createAsyncThunk(
  "user/sendVerifyCode",
  userApiAsyncCall
);

const loginAsyncThunk = createAsyncThunk("user/login", userApiAsyncCall);
// user module
const logoutAsyncThunk = createAsyncThunk("user/logout", userApiAsyncCall);

const registerStep1 = createAsyncThunk("user/registerStep1", userApiAsyncCall);

const registerStep2 = createAsyncThunk("user/registerStep2", userApiAsyncCall);

const updatePassword1 = createAsyncThunk(
  "user/updatePassword1",
  userApiAsyncCall
);
const updatePassword2 = createAsyncThunk(
  "user/updatePassword2",
  userApiAsyncCall
);
export {
  sendVerifyCodeAsyncThunk,
  loginAsyncThunk,
  updatePassword2,
  updatePassword1,
  registerStep1,
  registerStep2,
  logoutAsyncThunk,
};
