import styled from "styled-components";
import toolNavHoverBg from "../../assets/images/header/tool_nav_hover.png";
import fireIcon from "../../assets/images/header/fire.png";

export const StyledHeader = styled.header`
  position: relative;
  height: 65px;
  z-index: 100;

  @media screen and (max-width: 1024px) {
    height: 1rem;
    > div {
      height: 1rem;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  /* padding-left: 10px; */
  width: 100vw;
  height: 65px;
  background: #ffffff;
  .search-box {
    position: absolute;
    left: 47%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  height: 100%;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
  .logo {
    width: 120px;
    height: 35px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 0.24rem;
    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 1.74rem;
      height: 0.5rem;
      transform: translate(-50%, -50%);
    }
    .h5-avatar {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
    }
    .right {
      display: flex;
      align-items: center;
    }
    .h5-nav {
      width: 0.4rem;
      height: 0.4rem;
      &:first-child {
        margin-right: 0.2rem;
      }
    }
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  .nav-list {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 40px;
  }
  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 25px;
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    transition: color 0.3s ease;
    &.pointer {
      cursor: pointer;
    }
    &:hover {
      color: rgb(85, 85, 255);
      .arrow {
        transform: rotate(90deg);
      }
    }
    &.hover {
      .hover-container {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
      }
    }
    .arrow {
      margin-left: 7px;
      transform: rotate(-90deg);
      transition: transform 0.3s ease;
    }
    .hover-container {
      display: flex;
      position: absolute;
      top: 100%;
      left: 25px;
      // padding: 48px 40px 60px 40px;
      padding: 20px 30px;
      border-top: 2px solid rgb(85, 85, 255);
      background: #ffffff;
      border-radius: 0px 0px 40px 40px;
      opacity: 0;
      transform-origin: left top;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
      pointer-events: none;
      > div {
        max-height: calc(100vh - 160px);
        overflow-y: auto;
      }
    }
  }
`;

export const FreeToolsNavBox = styled.div`
  display: flex;
  .category-item {
    .category-title {
      padding-left: 20px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
    }
    .tool-list {
      display: flex;
      flex-direction: column;
    }
    .tool-item {
      position: relative;
      justify-content: flex-start;
      padding: 20px;
      width: 280px;
      height: 120px;
      border-radius: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 20px;
        right: 18px;
        width: 17px;
        height: 19px;
        background: url(${fireIcon}) center center / cover;
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: url(${toolNavHoverBg}) center center / cover;
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }
      &:hover {
        ::before,
        ::after {
          opacity: 1;
        }
      }
      .tool-img {
        width: 120px;
        height: 80px;
        border-radius: 10px;
      }
      .tool-name {
        margin-left: 20px;
        display: inline-block;
        width: 72px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        line-height: 36px;
      }
    }
  }
`;

export const StyledPersonalWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    height: 1rem;
  }
  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 20px;
  }
  .name {
    max-width: 100px;
    margin-left: 15px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  .buy-btn {
    margin: 5px 15px 0 0;
    padding: 10px;
    height: 30px;
    min-wdith: 0;
    font-size: 16px;
    color: #3d96ff;
    border-color: #3d96ff;
  }
`;

export const StyledBecomeMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  height: 100%;
  font-size: 12px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #feb430;
  cursor: pointer;
`;

export const StyleH5FreeTools = styled.div`
  && {
    position: fixed;
    z-index: 1000;
    top: 1rem;
    right: 0;
    padding: 0.5rem 0.4rem;
    background-color: #fff;
    animation: fadeIn 0.5s ease;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .category-title {
      padding-left: 0.2rem;
      font-size: 0.18rem;
      line-height: 0.36rem;
    }
    .tool-item {
      padding: 0.2rem;
      width: 2.8rem;
      height: 1.2rem;
      border-radius: 0.2rem;
      &&:before {
        top: 0.2rem;
        right: 0.2rem;
        width: 0.2rem;
        height: 0.2rem;
      }
      .tool-img {
        width: 1.2rem;
        height: 0.8rem;
        border-radius: 0.1rem;
      }
      .tool-name {
        margin-left: 0.2rem;
        width: 0.7rem;
        font-size: 0.18rem;
      }
    }
  }
`;
