import { lazy } from "react";

export default [
  {
    path: "/",
    Element: lazy(() => import("../views/gallery/index")),
  },
  {
    path: "/home",
    Element: lazy(() => import("../views/home")),
  },
  {
    path: "/auth",
    Element: lazy(() => import("../views/auth")),
  },
  {
    path: "/image-list",
    Element: lazy(() => import("../views/cpViews/search-result")),
  },
  {
    path: "/guide",
    Element: lazy(() => import("../components/emptyLayout")),
    children: [
      {
        path: "ai-draw",
        Element: lazy(() => import("../views/guides/aiDraw")),
      },
      {
        path: "ai-create",
        Element: lazy(() => import("../views/guides/aiDraw/aiCreateIframe")),
      },
      {
        path: "locale-limination",
        Element: lazy(() => import("../views/guides/localeLimination")),
      },
      {
        path: "auto-cutout",
        Element: lazy(() => import("../views/guides/autoCutout")),
      },
      {
        path: "avatar-cartoon",
        Element: lazy(() => import("../views/guides/avatarCartoon")),
      },
      {
        path: "pic-color",
        Element: lazy(() => import("../views/guides/picColor")),
      },
      {
        path: "pic-hd",
        Element: lazy(() => import("../views/guides/picHD")),
      },
      {
        path: "style-transfer",
        Element: lazy(() => import("../views/guides/styleTransfer")),
      },
      {
        path: "watermark",
        Element: lazy(() => import("../views/guides/picAddWatermark")),
      },
      {
        path: "compression-conversion",
        Element: lazy(() => import("../views/guides/compressionAndConversion")),
      },
      {
        path: "ocr",
        Element: lazy(() => import("../views/guides/ocr")),
      },
      {
        path: "remove-watermark",
        Element: lazy(() => import("../views/guides/picRemoveWatermark")),
      },
    ],
  },
  {
    path: "/edit",
    Element: lazy(() => import("../components/emptyLayout")),
    children: [
      {
        path: "auto-cutout",
        Element: lazy(() => import("../views/tools/autoCutoutEdit")),
      },
      {
        path: "locale-limination",
        Element: lazy(() => import("../views/tools/localeLiminationEdit")),
      },
      {
        path: "avatar-cartoon",
        Element: lazy(() => import("../views/tools/avatarCartoonEdit")),
      },
      {
        path: "ai-draw",
        Element: lazy(() => import("../views/tools/aiDrawEdit")),
      },
      {
        path: "watermark",
        Element: lazy(() => import("../views/tools/watermarkEdit")),
      },
      {
        path: "more-edit",
        Element: lazy(() => import("../views/tools/moreEdit")),
      },
    ],
  },
  {
    path: "/gallery",
    Element: lazy(() => import("../components/emptyLayout")),
    children: [
      {
        path: "search",
        Element: lazy(() => import("../views/gallery/result")),
      },
      {
        path: "content",
        Element: lazy(() => import("../views/gallery/content")),
      },
    ],
  },
  {
    path: "/developer-service",
    Element: lazy(() => import("../views/developerService")),
  },
  {
    path: "/help",
    Element: lazy(() => import("../views/help")),
  },
  {
    path: "/service-terms",
    Element: lazy(() => import("../views/serviceTerms")),
  },
  {
    path: "/personal",
    Element: lazy(() => import("../components/personalLayout")),
    children: [
      {
        path: "account",
        Element: lazy(() => import("../views/personal/account")),
      },
      {
        path: "gallery",
        Element: lazy(() => import("../views/personal/gallery")),
      },
      {
        path: "task",
        Element: lazy(() => import("../views/personal/task")),
      },
      {
        path: "order",
        Element: lazy(() => import("../views/personal/order")),
      },
      {
        path: "invoice/:status",
        Element: lazy(() => import("../views/personal/invoice")),
      },
      {
        path: "favourites",
        Element: lazy(() => import("../views/personal/favourites")),
      },
    ],
  },
];
