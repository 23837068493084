import styled from "styled-components";
import titleBgRight from "../../assets/images/payModal/bg_VIPtitle_left.png";
import titleBgLeft from "../../assets/images/payModal/bg_VIPtitle_right.png";

export const StyledPayWrapper = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  background: #ffffff;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    &&&& {
      width: 75vw;
      height: 6rem;
      .content {
        padding: 0;
        padding-top: 0.6rem;
        width: 100%;
      }
      .icon-close {
        top: 0.1rem;
        right: 0.1rem;
      }
    }
  }
  .icon-close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 20px;
    width: 20px;
    height: 20px;
    fill: #333;
    cursor: pointer;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .right {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`;

export const StyledExplainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  height: 100%;
  background: linear-gradient(90deg, #866ff6 0%, #6981f7 100%);
  @media screen and (min-width: 300px) and (max-width: 750px) {
    display: none;
  }
  .title {
    position: relative;
    margin-left: 70px;
    width: 96px;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #ffffff;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      width: 36px;
      height: 18px;
    }
    &::before {
      left: -45px;
      background: url(${titleBgLeft}) center center / cover;
    }
    &::after {
      right: -45px;
      background: url(${titleBgRight}) center center / cover;
    }
  }
  .logo {
    margin-top: 26px;
    margin-left: 36px;
    width: 172px;
    height: 132px;
  }
  .explain-item {
    display: flex;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    .yes-icon {
      margin: 0 10px 0 24px;
    }
  }
`;

export const StyledPayTypesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      .type {
        height: 1rem;
        border-radius: 0.1rem;
        width: calc(calc(100% - 0.3rem) / 3);
      }
      .title {
        font-size: 0.14rem;
        white-space: nowrap;
      }
      .tag {
        display: none;
      }
    }
  }
  .type {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(calc(100% - 40px) / 3);
    height: 100%;
    border: 1px solid #aca8ff;
    background: linear-gradient(90deg, #cecbfa 0%, #fbf8ff 100%);
    border-radius: 10px;
    &.active {
      border-color: #f12f1a;
      .real-price {
        color: #e60012;
      }
    }
  }
  .tag {
    position: absolute;
    top: -20px;
    left: -1px;
    padding: 5px 10px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 10px 0px 10px 0px;
  }
  .title {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 600;
    color: #333333;
    margin-bottom: 5px;
  }
  .sub-title {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  .year {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .num-btns {
    display: flex;
    .MuiButton-outlined,
    .MuiButton-contained {
      min-width: 40px;
      padding: 2px 5px;
      font-size: 16px;
      line-height: 20px;
      box-sizing: border-box;
      color: #866ff6;
      border-color: #aca8ff;
    }
    .MuiButton-contained {
      position: relative;
      z-index: 1;
      color: #fff;
      border-color: transparent;
      background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    }
  }
  .price {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    font-size: 36px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    .unit {
      margin-left: 2px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
      transform: translateY(-6px);
    }
  }
  .avg {
    margin-top: 2px;
    font-size: 14px;
    font-weight: bold;
    font-family: Source Han Sans SC;
  }
  .use {
    margin-top: 20px;
    width: 80%;
    height: 80px;
    .divider {
      position: relative;
      width: 60px;
      margin: 0 auto;
      font-size: 14px;
      color: #999;
      text-align: center;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        background-color: #999;
        position: absolute;
        top: 50%;
      }
      &::before {
        left: -40px;
        transform: scale(0.5);
      }
      &::after {
        right: -40px;
        transform: scale(0.5);
      }
    }
    .use-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      .use-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 6px;
        font-size: 16px;
        line-height: 24px;
        color: #666;
      }
    }
  }
  .buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .buy-btn {
      padding: 5px 20px;
      font-size: 18px;
      color: #fff;
      line-height: 24px;
      background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    }
    .text {
      margin-top: 12px;
      font-size: 14px;
      color: #6d6d6d;
    }
  }
  .pay-code-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledCouponCardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.2rem;
      .item {
        padding: 0.15rem;
        width: 2.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
      }
      .align-center {
        .item-icon {
          display: none;
        }
      }
      .text {
        margin-left: 0.05rem;
        font-size: 0.15rem;
        line-height: 0.24rem;
        white-space: pre;
      }
      .price {
        font-size: 0.3rem;
        .unit {
          margin-right: 0.05rem;
          font-size: 0.14rem;
          transform: translateY(-2px);
        }
      }
      .radio {
        margin-left: 0.02rem;
      }
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    width: 210px;
    height: 60px;
    border: 1px solid #e60012;
    background: linear-gradient(90deg, #ffc5b1 0%, #fff3f3 100%);
    border-radius: 10px;
    &.active {
      .text {
        color: #e60012;
      }
      .price {
        color: #e60012;
      }
    }
  }
  .align-center {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 5px;
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #999;
    line-height: 14px;
    text-align: left;
  }
  .price {
    display: flex;
    align-items: flex-end;
    font-family: Source Han Sans SC;
    font-size: 30px;
    font-weight: bold;
    color: #999;
    .unit {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      transform: translateY(-5px);
    }
  }
  .radio {
    display: flex;
    align-items: center;
    margin-left: 10px;
    fill: #e60012;
    > svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const StyledQrCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  width: 240px;
  height: 240px;
  background: #f7f7f7;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      width: 1.8rem;
      height: 1.8rem;
      .qr-code {
        width: 1.2rem;
        height: 1.2rem;
      }
      .text {
        margin-top: 0.08rem;
        font-size: 0.12rem;
        white-space: nowrap;
      }
    }
  }
  .qr-code {
    width: 180px;
    height: 180px;
    cursor: pointer;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
  }
`;

export const StyledCouponInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    &&&& {
      .has-coupon-question {
        font-size: 0.14rem;
      }
      .input {
        width: 1.85rem;
        .MuiInputBase-input {
          padding-left: 0.2rem;
        }
      }
      .count-down {
        width: 2.66rem;
        .text {
          display: none;
        }
      }
    }
  }
  .has-coupon-question {
    font-size: 14px;
    color: #999;
    text-align: right;
    cursor: pointer;
  }
  .input-box {
    display: flex;
    justify-content: space-between;
    .input {
      width: 185px;
      height: 25px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 4px;
      .MuiInputBase-input {
        padding: 0;
        padding-left: 20px;
        height: 26px;
        font-size: 12px;
        line-height: 26px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    .btn {
      height: 26px;
      font-size: 12px;
      font-family: Source Han Sans SC;
      font-weight: 300;
      color: #ffffff;
      background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    }
  }
  .count-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    width: 266px;
    height: 33px;
    background: linear-gradient(90deg, #ffc5b1 0%, #fff3f3 100%);
    border-radius: 0px;
    .text {
      font-size: 12px;
      font-family: Source Han Sans SC;
      font-weight: 300;
      color: #e60012;
    }
    .number {
      display: flex;
      justify-content: space-between;
      .num {
        position: relative;
        margin-left: 10px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #e60012;
        text-align: center;
        line-height: 20px;
        border: 1px solid #e60012;
        border-radius: 2px;
        &:not(:last-child)::after {
          position: absolute;
          content: ":";
          top: 50%;
          right: -8px;
          transform: translateY(-50%);
        }
      }
    }
  }
`;

export const StyledRealPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    &&&& {
      .label {
        font-size: 0.16rem;
      }
      .unit {
        font-size: 0.24rem;
      }
      .value {
        margin-left: 0;
        font-size: 0.48rem;
      }
      .delete-value {
        margin-left: 0;
        font-size: 0.16rem;
      }
      .other-desc {
        margin-top: 0;
        font-size: 0.12rem;
      }
    }
  }
  .pay-text-wrapper {
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
  }
  .label {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
  }
  .pay-value {
    display: flex;
    align-items: flex-end;
    font-family: Source Han Sans SC;
    color: #e60012;
    .unit {
      font-size: 24px;
      font-weight: 500;
      transform: translateY(2px);
    }
    .value {
      margin-left: 8px;
      font-size: 48px;
      font-weight: bold;
      transform: translateY(10px);
    }
  }
  .delete-value {
    margin-left: 10px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #e60012;
  }
  .other-desc {
    margin-top: 20px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #999999;
    .blank-link {
      color: #2a8ffc;
      cursor: pointer;
    }
  }
  .return-select {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .MuiButton-outlined {
      padding: 0 10px;
      height: 40px;
      line-height: 30px;
      color: #2a8ffc;
      border-color: #2a8ffc;
    }
  }
`;
