export const HOST = process.env.apiHost || "https://www.soujpg.com";
export const PORT = process.env.apiPort || "8106";
export const API_PREFIX = `${HOST}:${PORT}`;

export const BUCKET_NAME =
  process.env.bucketName || "soujpg-private1-1307121509";
export const REGION_NAME = process.env.regionName || "ap-beijing";
export const FILE_NAME_PREFIX = process.env.fileNamePrefix || "ai-image/";

console.log("HOST", HOST);
console.log("PORT", PORT);
console.log("API_PREFIX", API_PREFIX);
console.log("BUCKET_NAME", BUCKET_NAME);
console.log("REGION_NAME", REGION_NAME);
console.log("FILE_NAME_PREFIX", FILE_NAME_PREFIX);
