import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { StyledDialogTitle } from "../guideBanner/index.styled";
import { StyledDialogBody } from "../image/index.styled";
import {
  StyledFeedbackWrapper,
  StyledTextField,
  StyledH5TextField,
} from "./index.styled";
import Message from "../message";
import SvgIcon from "../svgIcon";
import useOpen from "../../hooks/useOpen";
import usePhoneScreen from "../../hooks/usePhoneScreen";
import { ReactComponent as IconClose } from "../../assets/images/guides/icon_close.svg";
import { ReactComponent as IconFeedback } from "../../assets/images/header/icon_feedback.svg";
import { loginAsyncThunk } from "../../redux/Actions/userActions";
import apiContactQRcode from "../../assets/images/contact/api.jpg";
import tecContactQRcode from "../../assets/images/contact/technology.png";

const FeedBack = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state?.userStore?.userInfo ?? {});

  const { open, handleOpen, handleClose } = useOpen();
  const [content, setContent] = useState("");
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };

  const onSubmit = () => {
    if (!content) {
      return Message({
        type: "info",
        message: "请写下您宝贵的意见",
      });
    }
    dispatch(
      loginAsyncThunk({
        params: {
          serviceMethod: "createFeedback",
          content: content,
          userId: userId,
        },
      })
    ).then((res) => {
      if (!res?.payload?.errorCode) {
        Message({
          type: "success",
          message: "提交成功",
        });
        handleClose();
      }
    });
  };

  const [iconActive, setIconActive] = useState(false);

  const { isPhoneScreen } = usePhoneScreen();

  const stop = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <StyledFeedbackWrapper
        onClick={handleOpen}
        onMouseOver={() => {
          setIconActive(true);
        }}
        onMouseOut={() => {
          setIconActive(false);
        }}
      >
        <div className="qrcode" onClick={stop}>
          <div className="qrcode-item">
            <img className="code" src={apiContactQRcode} />
            <span className="text">商务合作</span>
          </div>
          <div className="qrcode-item">
            <img className="code" src={tecContactQRcode} />
            <span className="text">技术咨询</span>
          </div>
        </div>
        <SvgIcon fontSize={16} color={iconActive ? "#fff" : "#26b4fd"}>
          <IconFeedback />
        </SvgIcon>
        &nbsp;投诉/建议
      </StyledFeedbackWrapper>
      <Dialog
        classes={{ paper: "tag-modal" }}
        maxWidth={false}
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle>
          <h5 className="title">投诉建议</h5>
          <div className="close" onClick={handleClose}>
            <IconClose />
          </div>
        </StyledDialogTitle>
        <StyledDialogBody>
          {!isPhoneScreen ? (
            <StyledTextField
              multiline
              style={{ marginTop: "30px" }}
              rows={4}
              placeholder="请写下您宝贵的意见..."
              value={content}
              onChange={onChangeContent}
            />
          ) : (
            <StyledH5TextField
              placeholder="请写下您宝贵的意见..."
              style={{ fontSize: 12 }}
              value={content}
              onChange={onChangeContent}
            />
          )}
          <div className="footer">
            <Button
              variant="outlined"
              className="mr10 self-btn"
              onClick={handleClose}
            >
              取消
            </Button>
            <Button
              variant="contained"
              className="gradient-bg self-btn"
              onClick={onSubmit}
            >
              确定
            </Button>
          </div>
        </StyledDialogBody>
      </Dialog>
    </>
  );
};

export default FeedBack;
