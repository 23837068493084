import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FreeTools } from "./navs";
import { ReactComponent as IconNav } from "../../assets/images/personal/icon_order.svg";
import { ReactComponent as IconSearch } from "../../assets/images/search/icon_search.svg";
import { StyleH5FreeTools } from "./index.styled";

const NavH5 = () => {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShow(false);
  }, [pathname]);

  const gotoSearch = () => {
    navigate("/");
  };

  const open = (e) => {
    e.stopPropagation();
    setShow((prev) => !prev);
  };
  const close = () => {
    setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", close);
    return () => window.removeEventListener("click", close);
  }, []);

  return (
    <>
      <div className="right">
        {!["/", "/gallery/search"].includes(pathname) && (
          <IconSearch className="h5-nav" onClick={gotoSearch} />
        )}
        <IconNav className="h5-nav" onClick={open} />
      </div>
      {show && (
        <StyleH5FreeTools>
          <FreeTools />
        </StyleH5FreeTools>
      )}
    </>
  );
};

export default NavH5;
