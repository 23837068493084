import styled from "styled-components";

export const StyledVerifyCodeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  width: 400px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.36rem;
      width: 4rem;
      .code {
        margin-right: 0.2rem;
      }
      .send-btn {
        height: 0.64rem;
        font-size: 0.2rem;
        border-radius: 0.1rem;
      }
    }
  }
  .code {
    flex: 1;
    margin-right: 20px;
  }
  .send-btn {
    /* width: 140px; */
    height: 64px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    background: #20b7fe;
    border-radius: 10px;
    text-transform: lowercase;
    &.Mui-disabled {
      color: #ffffff;
      background-color: #999;
    }
  }
  .img {
    padding: 0;
  }
`;
