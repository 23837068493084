import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { NavWrapper, FreeToolsNavBox } from "./index.styled";
import autoCutoutImg from "../../assets/images/header/tool_cutout.png";
import localeLiminationImg from "../../assets/images/header/tool_locale_limination.png";
import picHDImg from "../../assets/images/header/tool_pichd.png";
// import avatarImg from "../../assets/images/header/tool_avatar.png";
import aiImg from "../../assets/images/header/tool_ai.png";
import watermarkerImg from "../../assets/images/header/tool_watermarker.png";
import picColorImg from "../../assets/images/header/tool_piccolor.png";
// import styleChangeImg from "../../assets/images/header/tool_stylechange.png";
import compressionImg from "../../assets/images/header/tool_compression.png";
import ocrImg from "../../assets/images/header/tool_ocr.jpg";

const freeTools = [
  {
    categoryKey: "AI图像处理",
    children: [
      {
        img: autoCutoutImg,
        titleKey: "自动抠图",
        path: "auto-cutout",
      },
      {
        img: localeLiminationImg,
        titleKey: "局部消除",
        path: "locale-limination",
      },
      {
        img: picHDImg,
        titleKey: "图片高清",
        path: "pic-hd",
      },
      // {
      //   img: avatarImg,
      //   titleKey: "头像动漫",
      //   path: "avatar-cartoon",
      // },
      {
        img: aiImg,
        titleKey: "AI绘画",
        path: "ai-draw",
      },
    ],
  },
  {
    categoryKey: "图片编辑",
    children: [
      {
        img: watermarkerImg,
        titleKey: "图片加水印",
        path: "watermark",
      },
      {
        img: picColorImg,
        titleKey: "图片上色",
        path: "pic-color",
      },
      // {
      //   img: styleChangeImg,
      //   titleKey: "风格迁移",
      //   path: "style-transfer",
      // },
      {
        img: compressionImg,
        titleKey: "批量转换压缩",
        path: "compression-conversion",
      },
      {
        img: ocrImg,
        titleKey: "图片识别文字",
        path: "ocr",
      },
    ],
  },
];

export const FreeTools = ({ handleNavigate = () => {} }) => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(`/guide/${path}`);
    handleNavigate();
  };

  return (
    <FreeToolsNavBox>
      {freeTools.map(({ categoryKey, children }) => (
        <div key={categoryKey} className="category-item">
          <h5 className="category-title">{categoryKey}</h5>
          <ul className="tool-list">
            {children.map(({ img, titleKey, path }) => (
              <li key={titleKey} onClick={() => navigateTo(path)}>
                <Button variant="text" className="tool-item">
                  <img className="tool-img" src={img} />
                  <span className="tool-name">{titleKey}</span>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </FreeToolsNavBox>
  );
};

const Navs = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    path && navigate(path);
  };

  const [hoverKey, setHoverKey] = useState("");
  const clearHoverKey = useCallback(() => {
    setHoverKey("");
  }, []);
  const navs = [
    {
      titleKey: "图片素材",
      path: "/gallery/search",
    },
    {
      titleKey: "AI工具",
      // arrow: true,
      // children: <FreeTools handleNavigate={clearHoverKey} />,
      path: "/guide/ai-create",
      // badge: "hot",

    },
    {
      titleKey: "使用教程",
      // arrow: true,
      // children: <FreeTools handleNavigate={clearHoverKey} />,
      path: "/help",
      // badge: "hot",

    }
    // {
    //   titleKey: "AI绘画",
    //   path: "/guide/ai-create",
    //   badge: "hot",
    // },
    // {
    //   titleKey: "开发者服务",
    //   path: "/developer-service",
    // },
  ];
  return (
    <NavWrapper>
      <ul className="nav-list">
        {navs.map(({ titleKey, arrow, badge, children, path }) => (
          <li
            className={`nav-item ${children ? "" : "pointer"} ${
              hoverKey === titleKey ? "hover" : ""
            }`}
            key={titleKey}
            onMouseOver={() => {
              setHoverKey(titleKey);
            }}
            onMouseOut={clearHoverKey}
            onClick={() => navigateTo(path)}
          >
            {badge ? (
              <Badge badgeContent={badge} color="error">
                {titleKey}
              </Badge>
            ) : (
              titleKey
            )}
            {arrow && (
              <ArrowBackIosOutlined className="arrow" sx={{ fontSize: 16 }} />
            )}
            {children && <div className="hover-container">{children}</div>}
          </li>
        ))}
      </ul>
    </NavWrapper>
  );
};

export default Navs;
