import { createSlice } from "@reduxjs/toolkit";
import { AppStatus } from "../../utilities/staticObjects";

const initialState = () => {
  let userInfo_local = localStorage.getItem("userInfo");
  if (userInfo_local !== null || userInfo_local != undefined) {
    userInfo_local = JSON.parse(userInfo_local);
  }
  return {
    isLoginModalOpen: false,
    isBlindModalOpen: false,
    isPayModalOpen: false,
    isWaitProgress: {
      open: false,
      progress: 0,
    },
    commonErrorModal: {
      open: false,
      content: "",
      handleCloseFn: () => {},
    },
    isAdvertiseOpen: false,
    isAuthenticated: false,
    userAuthStatus: AppStatus.LOADING,
    userInfo: userInfo_local || {
      nickName: null,
      emailName: null,
      userId: null,
      password: null,
      userInputEmailVerifiedCode: null,
      permissions: [],
    },
    userLoginResponse: {
      // userId: null,
      errorCode: null,
      // nickName: null,
      // emailName: null,
      status: null,
    },
  };
};
export const authReducer = createSlice({
  name: "user",
  initialState: initialState(),
  reducers: {
    setBlindModalState: (state, { payload }) => {
      state.isBlindModalOpen = payload.isBlindModalOpen;
    },
    setLoginModalState: (state, { payload }) => {
      state.isLoginModalOpen = payload.isLoginModalOpen;
    },
    setPayModalState: (state, { payload }) => {
      state.isPayModalOpen = payload.isPayModalOpen;
    },
    setWaitProgressModalState: (state, { payload }) => {
      state.isWaitProgress = payload.isWaitProgress;
    },
    setCommonErrorModalState: (state, { payload }) => {
      state.commonErrorModal = payload;
    },
    setAdvertiseModalState: (state, { payload }) => {
      state.isAdvertiseOpen = payload.isAdvertiseOpen;
      localStorage.setItem("isAdvertiseOpen", payload.isAdvertiseOpen);
    },
    setUserAuthenticated: (state, { payload }) => {
      state.isAuthenticated = !!payload.userInfo;
      state.userInfo = { ...payload.userInfo };
      state.userAuthStatus = AppStatus.READY;
      if (payload.userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(payload.userInfo));
      }
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo[payload.name] = payload.value;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userInfo = {
        nickName: null,
        emailName: null,
        userId: null,
        password: null,
        userInputEmailVerifiedCode: null,
        permissions: [],
      };
      localStorage.removeItem("userInfo");
    },
  },
  extraReducers: {
    // [loginAsyncThunk.pending]: (state) => {
    // alert('called pending');
    // state.userLoginResponse.status = AppStatus.LOADING;
    // },
    // [loginAsyncThunk.fulfilled]: (state, { payload }) => {
    // alert(payload.userId);
    // if (payload.userId) {
    //   state.isAuthenticated = true;
    //   state.userLoginResponse.status = AppStatus.READY;
    //   state.userInfo = payload.userInfo;
    // } else {
    // console.log('being called');
    //   state.isAuthenticated = false;
    //   state.userLoginResponse.status = AppStatus.READY;
    //   state.userLoginResponse.errorCode = payload.errorCode;
    //   state.userInfo = payload.userInfo;
    // }
    // },
    // [loginAsyncThunk.rejected]: (state, { payload }) => {
    // console.log(payload);
    //     state.userLoginResponse.errorCode = payload.errorCode;
    //     state.userLoginResponse.status = AppStatus.READY;
    //   },
  },
});

export const { setUserAuthenticated, setUserInfo } = authReducer.actions;
export default authReducer.reducer;
