import styled from "styled-components";
import Button from "@mui/material/Button";
import loginBg from "../../assets/images/header/login.png";

export const StyledLoginWrapper = styled.div`
  display: flex;
  max-width: 1240px;
  width: 65vw;
  height: 720px;
  background: #ffffff;
  border-radius: 40px;
  overflow: hidden;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      width: 80vw;
      height: 7.2rem;
      border-radius: 0.4rem;
      .bg {
        display: none;
      }
      .login-form {
        width: 100%;
        margin-left: -0.4rem;
        .title {
          font-size: 0.36rem;
        }
        .sub-title {
          margin-top: 0.3rem;
          font-size: 0.18rem;
        }
        .bottom-text {
          margin-top: 0.3rem;
          font-size: 0.18rem;
        }
        .btns {
          margin-top: 0.3rem;
          .btn {
            font-size: 0.2rem;
          }
        }
      }
    }
  }
  .bg {
    flex: 1;
    height: 100%;
    border-radius: 40px;
    background: url(${loginBg}) center/cover;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 620px;
    .Mui-error {
      font-size: 14px;
    }
    .title {
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
    }
    .sub-title {
      margin-top: 30px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
    }
    .bottom-text {
      margin-top: 30px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      .agreement {
        color: #20b7fe;
        cursor: pointer;
      }
    }
    .btns {
      margin-top: 30px;
      .btn {
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
          color: #29c4ff;
        }
      }
    }
  }
`;

export const StyledWeXinQrCodeWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.24rem;
      .wx-qrcode-wrapper {
        width: 2.4rem;
        height: 2.4rem;
        > iframe {
          width: 2.4rem !important;
          height: 2.4rem !important;
        }
      }
    }
  }
  .wx-qrcode-wrapper {
    width: 240px;
    height: 240px;
    > iframe {
      width: 240px !important;
      height: 240px !important;
    }
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  width: 400px;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      margin-top: 0.26rem;
      width: 4rem;
      .input {
        height: 0.64rem;
        &.pwd {
          margin-top: 0.3rem;
          .MuiIconButton-root {
            right: 0.3rem;
          }
          .MuiSvgIcon-root {
            width: 0.3rem;
            height: 0.3rem;
          }
        }
        .MuiInputBase-input {
          padding: 0.16rem 0.2rem;
          font-size: 0.2rem;
          border-radius: 0.1rem;
        }
        .Mui-error {
          margin-top: 0;
          font-size: 0.2rem;
          white-space: nowrap;
        }
      }
    }
  }
  .input {
    height: 64px;
    &.pwd {
      position: relative;
      margin-top: 36px;
      width: 100%;
      .MuiIconButton-root {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        &::after {
          display: none;
        }
      }
    }
    .MuiInputBase-root {
      height: 100%;
    }
    .MuiInputBase-input {
      padding-left: 36px;
      font-size: 20px;
      border: 1px solid #999999;
      border-radius: 10px;
      transition: border-color 0.3s ease;
      &:focus {
        border-color: #29c4ff;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;

export const LoginBtn = styled(Button)`
  && {
    margin-top: 36px;
    width: 400px;
    height: 64px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
    border-radius: 10px;
    @media screen and (min-width: 300px) and (max-width: 750px) {
      margin-top: 0.36rem;
      width: 4rem;
      height: 0.64rem;
      font-size: 0.2rem;
      border-radius: 0.1rem;
    }
    &.Mui-disabled {
      color: #fff;
      background: #999999;
    }
  }
  & + .wexin-btn {
    margin-top: 15px;
    font-size: 20px;
    @media screen and (min-width: 300px) and (max-width: 750px) {
      margin-top: 0.15rem;
      font-size: 0.2rem;
    }
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: #29c4ff;
    }
  }
`;
