import styled from "styled-components";
import DropFileBox from "../dropFileBox";

export const StyledWrapper = styled.section`
  display: flex;
  align-items: center;
  height: 680px;
  background: url(${({ bgUrl }) => bgUrl}) center / cover;
  overflow: hidden;
  .content {
    margin-left: 18.75%;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 48px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #000000;
  }
  .desc {
    margin-top: 30px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
  }
  .btns {
    display: flex;
    flex-wrap: nowrap;
    max-width: 480px;
    margin-top: 75px;
    .btn {
      width: 242px;
      height: 82px;
      font-size: 30px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      border-radius: 40px;
      > svg {
        margin-right: 10px;
        width: 37px;
        height: 30px;
      }
      &:nth-of-type(2) {
        margin-left: 24px;
      }
      &.upload {
        color: #ffffff;
        > svg {
          fill: #fff;
        }
      }
      &.my-gallery {
        font-weight: 500;
        color: #000000;
        border-color: #000;
        > svg {
          fill: #000;
        }
      }
    }
  }
  .use-back-end {
    display: flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    .label {
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .example {
    margin-top: 48px;
    .example-text {
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
    }
    .example-imgs {
      display: flex;
      margin-top: 20px;
      margin-left: -10px;
      .img {
        margin-left: 10px;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      padding: 0 !important;
      justify-content: center;
      align-items: center;
      height: 5.6rem !important;
      .content {
        margin-left: 0;
        max-width: 80vw;
      }
      .title {
        font-size: 0.48rem;
      }
      .desc {
        margin-top: 0.3rem;
        font-size: 0.18rem;
      }
      .btns {
        margin-top: 0.4rem;
        .btn {
          width: 2.4rem;
          height: 0.8rem;
          font-size: 0.3rem;
          border-radius: 0.4rem;
          > svg {
            margin-right: 0.1rem;
            width: 0.37rem;
            height: 0.3rem;
          }
          &:nth-of-type(2) {
            margin-left: 0.24rem;
          }
        }
      }
      .use-back-end {
        margin-top: 0.12rem;
        .label {
          font-size: 0.18rem;
        }
        .MuiSvgIcon-root {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      > img {
        display: none;
      }
    }
  }
`;

export const StyledEmptyModalWrapper = styled(DropFileBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 750px;
  height: 400px;
  border: 1px dashed #a1deff;
  border-radius: 20px;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #3ba7fc;
  }
  .upload-img {
    width: 261px;
    height: 153px;
  }
  .upload-title {
    margin-top: 20px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .upload-desc {
    margin-top: 20px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #666666;
  }
  .btns {
    display: flex;
    margin-top: 20px;
    .btn {
      width: 120px;
      height: 40px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      border-radius: 10px;
      background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
      &:first-of-type {
        margin-right: 20px;
      }
      &.Mui-disabled {
        color: #fff;
        background: #999999;
      }
    }
    .hide-input {
      display: none;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      width: 70vw !important;
      height: 4rem !important;
      border-radius: 0.2rem;
      .upload-img {
        width: 2.6rem;
        height: 1.5rem;
      }
      .upload-title {
        margin-top: 0.2rem;
        font-size: 0.16rem;
      }
      .upload-desc {
        margin-top: 0.2rem;
        font-size: 0.14rem;
      }
      .btns {
        display: flex;
        margin-top: 0.2rem;
        .btn {
          padding: 0;
          width: 1.2rem;
          height: 0.5rem;
          font-size: 0.18rem;
          border-radius: 0.1rem;
          &:first-of-type {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
`;

export const StyledModalWrapper = styled(DropFileBox)`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 410px;
  &.half-section {
    width: 390px;
    height: 300px;
    border-radius: 10px;
    border: 1px dashed #a1deff;
    transition: border-color 0.3s ease;
    &:hover {
      border-color: #3ba7fc;
    }
    @media screen and (min-width: 300px) and (max-width: 750px) {
      && {
        height: 4rem;
        border-radius: 0.1rem;
      }
    }
  }
  .imgs-container {
    display: flex;
    flex-wrap: wrap;
    margin: 18px 0 10px -20px;
    flex: 1;
    overflow-y: auto;
    &.small {
      margin: 0;
      .add-img,
      .img-box {
        width: 80px;
        height: 80px;
        margin: 10px 0 0 10px;
      }
    }
    .add-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 20px;
      width: 100px;
      height: 100px;
      color: #666;
      border: 1px dashed #666666;
      border-radius: 10px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        color: #38a8fc;
        border-color: #38a8fc;
      }
      .add-icon {
        margin-top: -10px;
        font-size: 40px;
        font-weight: bold;
        line-height: 35px;
        vertical-align: middle;
      }
      .add-text {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
      }
    }
    .img-box {
      position: relative;
      margin: 20px 0 0 20px;
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.button {
        outline: 4px solid transparent;
        cursor: pointer;
        transition: outline-color 0.3s ease;
        &:hover,
        &.active {
          outline-color: #38a8fc;
        }
      }
      &:hover {
        .del-icon {
          opacity: 1;
        }
      }
      .del-icon {
        position: absolute;
        opacity: 0;
        top: -4px;
        right: -4px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        transition: opacity 0.3s ease;
      }
    }
  }
  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    &.small {
      margin-left: 20px;
    }
    .left {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      .text-btn {
        margin-left: 25px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #38a8fc;
        cursor: pointer;
      }
    }
    .btns {
      display: flex;
      .btn {
        width: 100px;
        height: 40px;
        border-radius: 10px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
        &.MuiButton-outlined {
          border: 1px solid #666666;
        }
        &.MuiButton-contained {
          margin-left: 12px;
          color: #fff;
          background: linear-gradient(90deg, #966df4 0%, #20b7fe 100%);
        }
        &.Mui-disabled {
          color: #fff;
          background: #999999;
        }
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      width: 70vw;
      height: 8rem;
      .imgs-container {
        margin: 0.18rem 0 0.1rem 0.2rem;
        &.small {
          .add-img,
          .img-box {
            width: 1rem;
            height: 1rem;
            margin: 0.1rem 0 0 0.1rem;
          }
        }
        .add-img {
          margin: 0.2rem 0 0 0.2rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.1rem;
          .add-icon {
            margin-top: -0.1rem;
            font-size: 0.4rem;
            line-height: 0.35rem;
          }
          .add-text {
            font-size: 0.16rem;
          }
        }
        .img-box {
          position: relative;
          margin: 0.2rem 0 0 0.2rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.1rem;
          &.button {
            outline-width: 0.04rem;
            &:hover {
              outline-color: transparent;
            }
            &.active {
              outline-color: #38a8fc;
            }
          }
          .del-icon {
            opacity: 1;
          }
        }
      }
      .operation {
        height: 0.4rem;
        &.small {
          margin-left: 0.2rem;
        }
        .left {
          font-size: 0.16rem;
          .text-btn {
            margin-left: 0.25rem;
            font-size: 0.16rem;
          }
        }
        .btns {
          .btn {
            width: 1rem;
            height: 0.5rem;
            border-radius: 0.1rem;
            font-size: 0.18rem;
          }
        }
      }
    }
  }
`;

export const StyledDialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 300px) and (max-width: 750px) {
    && {
      .title {
        font-size: 0.4rem;
      }
      .close {
        position: relative;
        top: -35px;
      }
    }
  }
  .title {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
  }
  .close {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
