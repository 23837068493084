import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import WeiXinLoginForm from "./weiXinLoginForm";
import TelephoneLoginForm from "./telephoneLoginForm";
import EmailLoginForm from "./emailLoginForm";
import AccountLoginForm from "./AccountLoginForm";
import { StyledLoginWrapper } from "./index.styled";
import usePhoneScreen from "../../hooks/usePhoneScreen";

const loginFormMap = {
  weixin: WeiXinLoginForm,
  telephone: TelephoneLoginForm,
  email: EmailLoginForm,
  account: AccountLoginForm,
};

const LoginModal = () => {
  const dispatch = useDispatch();
  const { isLoginModalOpen } = useSelector((state) => {
    return { isLoginModalOpen: state?.userStore?.isLoginModalOpen };
  });
  const handleClose = () => {
    dispatch({
      type: "user/setLoginModalState",
      payload: {
        isLoginModalOpen: false,
      },
    });
  };

  const { isPhoneScreen } = usePhoneScreen();

  const [formKey, setFormKey] = useState(
    isPhoneScreen ? "telephone" : "weixin"
  );
  const FormComponent = loginFormMap[formKey];

  const navigateToServiceTerms = () => {
    window.open("/service-terms", "_blank");
  };

  return (
    <Dialog
      classes={{ paper: "login-modal" }}
      maxWidth={false}
      open={isLoginModalOpen}
      onClose={handleClose}
    >
      <StyledLoginWrapper>
        <div className="bg"></div>
        <div className="login-form">
          <h5 className="title">
            {
              {
                weixin: "微信",
                telephone: "手机号",
                email: "邮箱",
                account: "账号",
              }[formKey]
            }
            登录
          </h5>
          <p className="sub-title">首次登录即为注册</p>
          {isLoginModalOpen && <FormComponent onChangeFormType={setFormKey} />}
          <p className="bottom-text">
            点击登录代表您同意
            <span className="agreement" onClick={navigateToServiceTerms}>
              《图芬奇用户协议》
            </span>
          </p>
        </div>
      </StyledLoginWrapper>
    </Dialog>
  );
};

export default LoginModal;
