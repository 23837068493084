import React from "react";
import Link from "@mui/material/Link";

import Grid from "@mui/material/Grid";
import { FooterWrapper, ContactQrCode } from "./index.styled";
import ToggleLanguage from "./ToggleLanguage";
import Links from "./Links";
import { ReactComponent as Logo } from "../../assets/images/header/logo.svg";
import { useLocation } from "react-router-dom";
import apiContactQRcode from "../../assets/images/contact/api.jpg";
import tecContactQRcode from "../../assets/images/contact/technology.png";

const Footer = () => {
  const location = useLocation();

  const { pathname } = location;
  return (
    pathname.indexOf("edit") === -1 &&
    pathname.indexOf("personal") === -1 &&
    !["/gallery/search", "/guide/ai-create"].includes(pathname) && (
      <FooterWrapper>
        <Grid container>
          <Grid
            className="h5-grid"
            item
            container
            direction="column"
            alignItems="center"
            xs={4}
            style={{ marginTop: "20px" }}
          >
            <Logo className="logo" />
            <ToggleLanguage />
          </Grid>
          <Grid
            className="h5-grid"
            item
            container
            justifyContent="space-between"
            xs
          >
            <Links />
          </Grid>
          <Grid
            item
            container
            flexWrap="nowrap"
            xs={3}
            style={{ paddingTop: "30px" }}
            className="h5-grid code-container"
          >
            <div className="contact-item">
              商务合作
              <ContactQrCode>
                <img className="img" src={apiContactQRcode} />
              </ContactQrCode>
            </div>
            <div className="contact-item">
              技术咨询
              <ContactQrCode>
                <img className="img" src={tecContactQRcode} />
              </ContactQrCode>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className="footer-version"
          style={{ maxWidth: "1124px", marginTop: "45px" }}
        >
          <span>Copyright ©{new Date().getFullYear()} 图芬奇 保留所有权利</span>
          <span>
            <a href="https://beian.miit.gov.cn/" className="link">
              津ICP备2022009650号-1
            </a>
          </span>
          <span>
            <Link href={"/service-terms"} underline="hover" className="link">
              服务条款
            </Link>
          </span>
          <span>图芬奇 V1.0.0</span>
          <span>开发者：天津搜图信息科技有限公司</span>
        </Grid>
      </FooterWrapper>
    )
  );
};

export default Footer;
