import { API_PREFIX } from "../config";
import ai3DImg from "../assets/images/guides/3d.png";
import aiAnimateIllustrationImg from "../assets/images/guides/animateIllustration.jpg";
import aiAnimateRoleImg from "../assets/images/guides/animateRole.jpg";
import aiSceneImg from "../assets/images/guides/scene.jpg";
import aiAnimeAvatarImg from "../assets/images/guides/animeAvatar.jpg";
import aiQCartoonImg from "../assets/images/guides/qCartoon.png";
import aiRealistImg from "../assets/images/guides/realist.jpg";
import aiBuildImg from "../components/imageEditor/assets/ai_category_build.webp";
import aiLogoImg from "../components/imageEditor/assets/ai_category_logo.png";

export const AppStatus = Object.freeze({
  LOADING: "loading",
  READY: "ready",
});

export const APIType = Object.freeze({
  IMAGES: "images",
  KEYWORDS: "keywords",
});

export const globalConfig = Object.freeze({
  imageAppApi: `${API_PREFIX}/rest/image-search`,
  userApi: `${API_PREFIX}/rest/user`,
  imageAIToolAPIPrefix: `${API_PREFIX}/`,
  loginedUserMaxPaginationNum: 100,
  noLoginedUserMaxPaginationNum: 10,
});

// 高清倍数
export const HD_RATIOS = Object.freeze([2, 4, 8]);

// 图片种类
export const KIND = Object.freeze([
  { label: "人物", value: true },
  { label: "物品", value: false },
]);

// 去除图片水印类型
export const WATERMARK_TYPE = Object.freeze([
  { label: "文字", value: "text" },
  { label: "图片", value: "image" },
]);

export const asyncAITimeOut = 1000 * 300;

export const bestPrompts = [
  {
    prompts:
      "万圣节假期的化身，以一个留着短发、带着恶棍微笑的可爱女孩的形式出现，可爱女孩，可爱的帽子、可爱的脸颊、虚幻的引擎、高度详细的、artgerm 数字插图、woo-tooth、studio ghibli、deviation tart、sharp focus、artstation，由阿列克谢·维诺格拉多夫面包店、糖果、祖母绿的眼睛",
  },
  {
    prompts:
      "复杂的 3d 渲染一个漂亮的瓷器轮廓女人的超细节机器人脸，半机械人，机器人零件，150 毫米，美丽的工作室柔和的光线，边缘的光线，充满活力的细节，奢华的赛博朋克，蕾丝，超现实主义，解剖，面部肌肉，电缆电线，微芯片，优雅，美丽的背景，辛烷值渲染，H.R.吉格风格，8k",
  },

  {
    prompts:
      "充满好奇收藏品的商店：：不可思议，动漫，数字 2D，由京都动画制作动画，吉卜力工作室，宫崎骏，AKIRA 艺术风格，美丽，华丽，戏剧性的灯光，三分规则，完美的构图，在 ArtStation 上流行，4k-ar 1:2-质量 2",
  },

  {
    prompts:
      "女神特写肖像头骨，莫霍克，公羊头骨，骨架，胸部，x 光片，脊椎，水母凤头，鹦鹉螺，兰花，头骨，斗鱼，生物发光生物，由 Tooth Wu 和 wlop 创作的复杂艺术品和哔哔声。辛烷值渲染，在艺术台上流行，格雷格·鲁特考斯基非常连贯的对称艺术品。电影，超现实主义，高细节，辛烷值渲染，8k",
  },

  {
    prompts:
      "废墟中的寺庙，森林，楼梯，柱子，电影，细节，大气，史诗，概念艺术，哑光画，背景，薄雾，照片逼真，概念艺术、体积光，电影史诗，8k",
  },

  {
    prompts:
      "森林漫游者，绘画风格，单色，插图，明亮多彩，高对比度，神话，电影，细节，大气，8k，电晕渲染",
  },
  {
    prompts:
      "生命的意义，令人惊叹的艺术，令人惊叹，高分辨率，高度细致，鼓舞人心，8k",
  },

  {
    prompts:
      "《星球大战》中的猫，穿着不同制服和处境的猫，在一艘主力舰上，放大镜头，逼真，焦点清晰，复古冷色温，以脸为中心",
  },
  {
    prompts:
      "强壮的战士公主|以中心为中心|关键视觉|复杂|高度细致|令人惊叹的美丽|精确的线性|充满活力|全面的电影| Carne Griffiths | Conrad Roset",
  },
];

export const CONTROL_MODES = [
  {
    label: "Lineart（提取线条）",
    value: "lineart",
  },
  {
    label: "MLSD（提取直线）",
    value: "mlsd",
    extraParams: {
      mlsd_invert: false,
    },
  },
  {
    label: "Invert（线稿原型）",
    value: "mlsdReverse",
    extraParams: {
      mlsd_invert: true,
    },
  },
  {
    label: "Depth（提取深度）",
    value: "depth",
  },
  {
    label: "Softedge（软边缘）",
    value: "softedge",
  },
  {
    label: "Openpose(姿势识别)",
    value: "openpose",
  },
];

export const SCHEDULERS = [
  {
    label: "DPMS Karras",
    value: "DPMSDEKarras",
  },
  {
    label: "Euler a",
    value: "EulerAncestralDiscreteScheduler",
  },
  {
    label: "Euler d",
    value: "EulerDiscreteScheduler ",
  },
  {
    label: "DPMS m",
    value: "DPMSolverMultistepScheduler",
  },
  {
    label: "DPMS",
    value: "DDPMScheduler ",
  },
  {
    label: "DDIM",
    value: "DDIMScheduler",
  },
  {
    label: "KDPM2 AD",
    value: "KDPM2AncestralDiscreteScheduler",
  },
  {
    label: "UniPC",
    value: "UniPCMultistepScheduler",
  },
  {
    label: "Heun",
    value: "HeunDiscreteScheduler",
  },
  {
    label: "DEIS",
    value: "DEISMultistepScheduler",
  },
  {
    label: "PNDM",
    value: "PNDMScheduler  ",
  },
  {
    label: "KDPM2",
    value: "KDPM2DiscreteScheduler ",
  },
  {
    label: "LMS",
    value: "LMSDiscreteScheduler ",
  },
];

export const MODELTYEP2MODELNAME = {
  cartoon: "meinamix_meinaV10",
  person: "xxmix9realistic_v40",
  illustration: "tmndMix_tmndMixVPruned",
  architecture: "xsarchitecturalv3com_v31",
  generalDesign: "xsmerge_v31InSafetensor",
  futureArchitecture: "raRender_v20",
  cute: "qteamixQ_omegaFp16",
  threeDimensional: "sdvn53dcutewave_v10",
  mechanicsStyle: "CoMix_v3.1_科幻plus_3.1",
  colorfulStyle: "etherBluMix_etherBluMix5",
  cuteStyle: "manmaruMix_v20",
  cg: "CoMix_v3.1_科幻plus_3.1",
  martial: "ChosenChineseStyleNsfw_v10",
  beautyGoddess: "majicmixRealistic_betterV2V25",
  fantasyBeauty: "majicmixReverie_v10",
  sheerBeauty: "PhotoCNvtuberMix_v1.0",
  photographyBeauty: "leosamsMoonfilm_filmGrain20",
  photoBeautity: "awportrait_v11",
  handsomeBoy: "majicmixAlpha_v10",
  cuteChild: "WFChild_v1.0",
};

export const CREATE_CONTENT = [
  {
    categoryName: "动漫插画",
    coverUrl: aiAnimateIllustrationImg,
    initSelectIndex: 1,
    field: "animateIllustration",
    secondCategory: [
      {
        categoryName: "标准风格",
        modelType: "cartoon",
      },
      {
        categoryName: "细腻风格",
        modelType: "illustration",
      },
      {
        categoryName: "可爱风格",
        modelType: "cuteStyle",
      },
      {
        categoryName: "CG风格",
        modelType: "cg",
      },
      {
        categoryName: "浅色风格",
        modelType: "colorfulStyle",
      },
      {
        categoryName: "古风武侠",
        modelType: "martial",
      },
      {
        categoryName: "水彩风格",
        modelType: "cartoon",
        loraKey: 0,
      },
      {
        categoryName: "精致风格",
        modelType: "cartoon",
        loraKey: 1,
      },
      {
        categoryName: "简洁风格",
        modelType: "cartoon",
        loraKey: 2,
      },
      {
        categoryName: "水墨古风",
        modelType: "cartoon",
        extraPrompt: {
          keywords: "traditional chinese ink painting,",
          placeholder: "中国水墨画，古风，",
        },
        initWeight: 0.8,
        loraKey: 3,
      },
      {
        categoryName: "极简风格",
        modelType: "cartoon",
        loraKey: 4,
        initWeight: 0.4,
      },
      {
        categoryName: "黑白漫画",
        modelType: "illustration",
        loraKey: 35,
        initWeight: 0.8,
        extraPrompt: {
          placeholder: "杰作，黑白漫画，线稿，单色，灰度，线性",
          keywords:
            "masterpiece, best quality,line,monochrome,greyscale，black and white comics",
        },
      },
      {
        categoryName: "鬼刀风格",
        modelType: "person",
        loraKey: 5,
      },
      {
        categoryName: "Q版风格",
        modelType: "cartoon",
        loraKey: 6,
      },
      {
        categoryName: "机甲风格",
        modelType: "cartoon",
        loraKey: 7,
      },
      {
        categoryName: "高达风格",
        modelType: "cartoon",
        loraKey: 8,
      },
      {
        categoryName: "像素风格",
        modelType: "cartoon",
        loraKey: 9,
      },
      {
        categoryName: "新海诚风格",
        modelType: "cartoon",
        loraKey: 10,
        initWeight: 0.8,
        extraPrompt: {
          keywords: "shinkai makoto,",
          placeholder: "新海诚，",
        },
      },
      {
        categoryName: "宫崎骏风格",
        modelType: "cartoon",
        loraKey: 11,
        initWeight: 0.8,
        extraPrompt: {
          keywords: "hayao miyazaki \\(style\\),(retro photography",
          placeholder: "宫崎骏\\(风格\\),(复古摄影)",
        },
      },
    ],
  },
  {
    title: "角色选择",
    categoryName: "动漫角色",
    field: "animateRole",
    coverUrl: aiAnimateRoleImg,
    secondCategory: [
      {
        categoryName: "初音",
        title: "风格选择",
        loraKey: 11,
      },
      {
        categoryName: "洛天依",
        modelType: "cartoon",
        loraKey: 12,
      },
      {
        categoryName: "蕾姆",
        title: "风格选择",
        loraKey: 34,
      },
      {
        categoryName: "艾米莉娅",
        title: "风格选择",
        loraKey: 29,
      },
      {
        categoryName: "玛奇玛",
        modelType: "cartoon",
        loraKey: 13,
      },
      {
        categoryName: "蒂法",
        modelType: "cartoon",
        loraKey: 14,
      },
      {
        categoryName: "尼尔：机械纪元",
        modelType: "cartoon",
        loraKey: 15,
        initWeight: 0.8,
      },
      {
        categoryName: "四宫辉夜",
        modelType: "cartoon",
        loraKey: 16,
      },
      {
        categoryName: "薇尔莉特",
        modelType: "cartoon",
        loraKey: 17,
      },
      {
        categoryName: "阿尼亚",
        modelType: "cartoon",
        loraKey: 18,
        initWeight: 0.4,
      },
      {
        categoryName: "约尔太太",
        modelType: "cartoon",
        loraKey: 19,
      },
      {
        categoryName: "和泉纱雾",
        modelType: "cartoon",
        loraKey: 20,
      },
      {
        categoryName: "灰原哀",
        modelType: "cartoon",
        loraKey: 21,
      },
      {
        categoryName: "小鸟游六花",
        modelType: "cartoon",
        loraKey: 22,
      },
      {
        categoryName: "雏田",
        modelType: "cartoon",
        loraKey: 23,
      },
      {
        categoryName: "安柏",
        modelType: "cartoon",
        loraKey: 24,
      },
      {
        categoryName: "可莉",
        modelType: "cartoon",
        loraKey: 25,
      },
      {
        categoryName: "绮良良",
        modelType: "cartoon",
        loraKey: 26,
      },
      {
        categoryName: "八重神子",
        modelType: "cartoon",
        loraKey: 27,
      },
    ],
    thirdCategory: [
      {
        categoryName: "标准风格",
        modelType: "cartoon",
      },
      {
        categoryName: "细腻风格",
        modelType: "illustration",
      },
      {
        categoryName: "真实风格",
        modelType: "person",
      },
    ],
  },
  {
    categoryName: "写实人物",
    field: "realist",
    coverUrl: aiRealistImg,
    secondCategory: [
      {
        categoryName: "唯美女神",
        modelType: "beautyGoddess",
      },
      {
        categoryName: "纯欲美女",
        modelType: "sheerBeauty",
      },
      {
        categoryName: "写实女友",
        modelType: "person",
      },
      {
        categoryName: "梦幻美女",
        modelType: "fantasyBeauty",
      },
      {
        categoryName: "摄影美女",
        modelType: "photographyBeauty",
      },
      {
        categoryName: "写真美女",
        modelType: "photoBeautity",
      },
      {
        categoryName: "机甲女神",
        modelType: "mechanicsStyle",
      },
      {
        categoryName: "写实帅哥",
        modelType: "handsomeBoy",
      },
      {
        categoryName: "可爱儿童",
        modelType: "cuteChild",
      },
      {
        categoryName: "拍立得人像",
        modelType: "person",
        loraKey: 36,
      },
      {
        categoryName: "鬼刀女神",
        modelType: "person",
        loraKey: 5,
      },
    ],
  },
  {
    categoryName: "Q版卡通",
    field: "qCartoon",
    coverUrl: aiQCartoonImg,
    secondCategory: [
      {
        categoryName: "Q版人物",
        modelType: "cute",
      },
      {
        categoryName: "Q版头像",
        modelType: "cartoon",
        loraKey: 6,
        initScaleType: "square",
      },
      {
        categoryName: "Q萌宠物",
        modelType: "cartoon",
        loraKey: 30,
      },
    ],
  },
  {
    categoryName: "3D卡通",
    field: "3d",
    coverUrl: ai3DImg,
    modelType: "sdvn53dcutewave_v10",
  },
  {
    title: "类型选择",
    categoryName: "唯美场景",
    field: "scene",
    coverUrl: aiSceneImg,
    secondCategory: [
      {
        categoryName: "动漫场景",
        modelType: "illustration",
      },
      {
        categoryName: "写实场景",
        modelType: "person",
      },
      {
        categoryName: "水彩场景",
        modelType: "illustration",
        loraKey: 37,
        initWeight: 0.4,
      },
      {
        categoryName: "插画场景",
        modelType: "illustration",
        loraKey: 38,
        initWeight: 0.4,
      },
      {
        categoryName: "水墨场景",
        modelType: "illustration",
        loraKey: 3,
        initWeight: 0.7,
      },
      {
        categoryName: "漫画场景",
        modelType: "illustration",
        loraKey: 35,
        initWeight: 0.8,
        extraPrompt: {
          keywords:
            "masterpiece, best quality,line,monochrome,greyscale，black and white comics,",
          placeholder: "杰作，黑白漫画，线稿，单色，灰度，线性，",
        },
      },
      {
        categoryName: "新海诚风格",
        modelType: "cartoon",
        loraKey: 10,
        initWeight: 0.8,
        extraPrompt: {
          keywords: "shinkai makoto,",
          placeholder: "新海诚，",
        },
      },
      {
        categoryName: "宫崎骏风格",
        modelType: "cartoon",
        loraKey: 11,
        initWeight: 0.8,
        extraPrompt: {
          keywords: "hayao miyazaki \\(style\\),(retro photography",
          placeholder: "宫崎骏\\(风格\\),(复古摄影)",
        },
      },
    ],
  },
  {
    categoryName: "动漫头像",
    field: "animeAvatar",
    initScaleType: "square",
    coverUrl: aiAnimeAvatarImg,
    secondCategory: [
      {
        categoryName: "可爱头像",
        modelType: "cute",
      },
      {
        categoryName: "Q版头像",
        modelType: "cartoon",
        loraKey: 6,
      },
      {
        categoryName: "细腻头像",
        modelType: "illustration",
      },
      {
        categoryName: "像素头像",
        modelType: "illustration",
        loraKey: 9,
      },
      {
        categoryName: "风格头像",
        modelType: "illustration",
        loraKey: 33,
      },
      {
        categoryName: "写实头像",
        modelType: "person",
      },
      {
        categoryName: "黑白头像",
        modelType: "illustration",
        loraKey: 35,
        initWeight: 0.8,
        extraPrompt: {
          keywords:
            "masterpiece, best quality,line,monochrome,greyscale，black and white comics",
          placeholder: "杰作，黑白漫画，线稿，单色，灰度，线性",
        },
      },
      {
        categoryName: "3D头像",
        modelType: "threeDimensional",
      },
    ],
  },
  {
    categoryName: "建筑室内",
    field: "architecture",
    coverUrl: aiBuildImg,
    modelType: "xsarchitecturalv3com_v31",
  },
  {
    categoryName: "LOGO",
    field: "logo",
    coverUrl: aiLogoImg,
    modelType: "sd-1.5",
  },
];

export const LOAR_PARAMS = [
  {
    label: "二次元水彩",
    value: "colorwater.safetensors",
    key: 0,
  },
  {
    label: "简洁插画",
    value: "Neoplasticism.safetensors",
    key: 1,
  },
  {
    label: "简笔画",
    value: "handdraw.safetensors",
    key: 2,
  },
  {
    label: "水墨古风",
    value: "水墨MoXinV1.safetensors",
    key: 3,
  },
  {
    label: "极简插画",
    value: "animeminimalistv1.safetensors",
    key: 4,
  },
  {
    label: "鬼刀",
    value: "WLOP104.safetensors",
    key: 5,
  },
  {
    label: "Q版头像",
    value: "Q版化头像chibi-v1.safetensors",
    key: 6,
  },
  {
    label: "机甲少女",
    value: "Mechawarrior.safetensors",
    key: 7,
  },
  {
    label: "高达风格",
    value: "RX78V1.safetensors",
    key: 8,
  },
  {
    label: "像素风格",
    value: "pixelV2.safetensors",
    key: 9,
  },
  {
    label: "新海诚",
    value: "shinkai_makoto_offset.safetensors",
    key: 10,
  },
  {
    label: "初音",
    value: "hatsunemiku1000006.safetensors",
    key: 11,
  },
  {
    label: "洛天依",
    value: "luotianyikoushiki.safetensors",
    key: 12,
  },
  {
    label: "玛奇玛",
    value: "makimaoffset.safetensors",
    key: 13,
  },
  {
    label: "蒂法",
    value: "tifanvwlsv1final.safetensors",
    key: 14,
  },
  {
    label: "机械纪元a2B",
    value: "y2bv21.safetensors",
    key: 15,
  },
  {
    label: "四宫辉夜",
    value: "kakuya.safetensors",
    key: 16,
  },
  {
    label: "薇尔莉特",
    value: "Evergarden.safetensors",
    key: 17,
  },
  {
    label: "阿尼亚",
    value: "aki000008.safetensors",
    key: 18,
  },
  {
    label: "约尔太太",
    value: "yor.safetensors",
    key: 19,
  },
  {
    label: "和泉纱雾",
    value: "sagiriv1.safetensors",
    key: 20,
  },
  {
    label: "灰原哀",
    value: "haibaraai001.safetensors",
    key: 21,
  },
  {
    label: "小鸟游六花",
    value: "RikkaFB.safetensors",
    key: 22,
  },
  {
    label: "雏田",
    value: "Hinata.safetensors",
    key: 23,
  },
  {
    label: "安柏",
    value: "amber.safetensors",
    key: 24,
  },
  {
    label: "可莉",
    value: "klee2000010.safetensors",
    key: 25,
  },
  {
    label: "绮良良",
    value: "Genshinv3.safetensors",
    key: 26,
  },
  {
    label: "八重神子",
    value: "miko000014.safetensors",
    key: 27,
  },
  {
    label: "艾米莉娅",
    value: "aimiliya10.safetensors",
    key: 29,
  },
  {
    label: "Q版头像",
    value: "Q版化头像chibi-v1.safetensors",
    key: 29,
  },
  {
    label: "猫猫",
    value: "cat.safetensors",
    key: 30,
  },
  {
    label: "自然插画",
    value: "Textureillustration.safetensors",
    key: 31,
  },
  {
    label: "像素风格",
    value: "pixelV2.safetensors",
    key: 32,
  },
  {
    label: "精简头像",
    value: "间清头像v1.safetensors",
    key: 33,
  },
  {
    label: "蕾姆",
    value: "Remv2FB.safetensors",
    key: 34,
  },
  {
    label: "漫画风",
    value: "漫画风animeoutlineV4_16.safetensors",
    key: 35,
  },
  {
    label: "拍立得",
    value: "InstantPhotoX3.safetensors",
    key: 36,
  },
  {
    label: "水彩风",
    value: "水彩archi_watercolor-v1.safetensors",
    key: 37,
  },
  {
    label: "插画风格",
    value: "illustratearchitecture.safetensors",
    key: 38,
  },
];

export const PIC_RATIOS = ["1:1", "4:3", "16:9", "9:16"];
