import { useState } from "react";

const useBlind = ({
  dispatch,
  blindFn = () => Promise.reject("需传递blind异步函数调用"),
}) => {
  const [loading_blind, setLoading_blind] = useState(false);

  const blind_op = () => {
    setLoading_blind(true);
    blindFn()
      .then((res) => {
        if (res.payload.errorCode) {
          throw new Error(res.payload.errorCode);
        }

        dispatch({
          type: "user/setBlindModalState",
          payload: { isBlindModalOpen: false },
        });
        setLoading_blind(false);
        // 设置绑定的信息到当前的userInfo state
        const mobileNumber = res.payload.userInfo.mobileNumber;
        const emailName = res.payload.userInfo.emailName;
        if (mobileNumber !== null) {
          dispatch({
            type: "user/setUserInfo",
            payload: {
              name: "mobileNumber",
              value: mobileNumber,
            },
          });
        }
        if (emailName !== null) {
          dispatch({
            type: "user/setUserInfo",
            payload: {
              name: "emailName",
              value: emailName,
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading_blind(false);
      });
  };

  return {
    loading_blind,
    blind_op,
  };
};

export default useBlind;
