import { useState } from "react";
import { useEffect } from "react";

export default () => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(
    window.matchMedia("(max-width: 438px)").matches
  );

  const handleResize = () =>
    setIsPhoneScreen(window.matchMedia("(max-width: 438px)").matches);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isPhoneScreen };
};
