import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getImages } from "../redux/Actions/imagesAction";
import {
  setParams,
  setImages,
  setImagesLoading,
} from "../redux/Reducers/imagesReducer";
import { isTop } from "../utilities/Utilities";

const useGetImages = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { userId, params, images, loading } = useSelector((state) => ({
    userId: state?.userStore?.userInfo?.userId,
    params: state?.imageStore?.params,
    images: state?.imageStore?.imagesList?.images?.responseItems ?? [],
    loading: state?.imageStore?.imagesLoading,
  }));

  // 统一格式化搜索参数
  const formatQueryParams = (data = {}) => {
    const queryParams = {
      ...data,
      serviceMethod: "imageSearch",
      userId: data.userId || userId,
      // faceImageNameBlobUrl imageNameBlobUrl 是识图搜索时本地上传的图片临时blob地址，后端不需要这两个参数
      faceImageNameBlobUrl: undefined,
      imageNameBlobUrl: undefined,
      // faceUrl 和 url 为临时blob地址时置为null，https网上图片链接时传，空值默认传null
      faceUrl: data?.faceImageName ? null : data?.faceUrl || null,
      url: data?.imageName ? null : data?.url || null,
      // 上传图片时使用imageName，使用网络图片时不传
      faceImageName: data?.faceImageName || undefined,
      imageName: data?.imageName || undefined,
    };
    // imageName inputText为空时转为null
    queryParams.imageName = queryParams.imageName || null;
    queryParams.inputText = queryParams.inputText || null;
    queryParams.imageType =
      queryParams.imageType === "all" ? null : queryParams.imageType;

    // 开启语义化搜索 sentence使用inputText
    if (queryParams.sentence) {
      queryParams.sentence = queryParams.inputText;
      queryParams.inputText = null;
    } else {
      queryParams.sentence = null;
    }
    return queryParams;
  };

  /**
   * @param {object} searchData 搜索条件
   * @param {number} defaultPageNum 通过分享链接打开搜索结果页面的默认页码
   * @content 根据条件重新搜索
   *  */
  const search = (searchData, defaultPageNum) => {
    if (loading) {
      return;
    }

    const data = {
      ...params,
      ...searchData,
    };

    dispatch(setImagesLoading({ loading: true }));
    dispatch(
      setParams({
        ...data,
        pageNum: defaultPageNum || 1,
      })
    );
    dispatch(
      setImages({
        responseItems: [],
        totalCount: 0,
        kwIds: [],
        kwNames: [],
        scores: [],
      })
    );

    // 在搜索首页并且不是内嵌到外接合作系统时，点击搜索，跳转到搜索结果页面
    if (pathname !== "/gallery/search" && isTop()) {
      navigate("/gallery/search");
      dispatch(setImagesLoading({ loading: false }));
      return;
    }

    const fParams = formatQueryParams({
      ...data,
      pageNum: defaultPageNum || 1,
    });

    // 在搜索页，更改筛选条件，需要同步更改搜索链接以供用户可以直接分享
    if (pathname === "/gallery/search") {
      const searchParams = `${Object.entries(fParams).reduce(
        (str, [k, v]) =>
          ![
            "serviceMethod",
            "userId",
            // "pageNum",
            "pageSize",
            "imageAppOpName",
            "imageName",
            "faceImageName",
          ].includes(k) && v
            ? `${str}${str ? "&" : ""}${
                // k为开启语义时，inputText为sentence的内容，sentence为1表示开启
                k === "sentence"
                  ? `${k}=1&inputText=${encodeURIComponent(v)}`
                  : `${k}=${encodeURIComponent(v)}`
              }`
            : str,
        ""
      )}`;
      window.history.replaceState(null, "", `${pathname}?${searchParams}`);
    }

    return dispatch(
      getImages({
        params: fParams,
      })
    )
      .then((res) => {
        if (!Array.isArray(res?.payload?.responseItems)) {
          return;
        }
        dispatch({
          type: "allImages/setImages",
          payload: res.payload,
        });
      })
      .finally(() => {
        dispatch(setImagesLoading({ loading: false }));
      });
  };

  // 下一页
  const getNextPage = () => {
    if (loading) {
      return;
    }

    dispatch(setImagesLoading({ loading: true }));
    dispatch(
      getImages({
        params: formatQueryParams({ ...params, pageNum: params.pageNum + 1 }),
      })
    )
      .then((res) => {
        if (!Array.isArray(res?.payload?.responseItems)) {
          return;
        }
        dispatch(
          setParams({
            ...params,
            pageNum: params.pageNum + 1,
          })
        );
        dispatch({
          type: "allImages/setImages",
          payload: {
            ...res.payload,
            responseItems: images.concat(res.payload.responseItems),
          },
        });
        pathname !== "/gallery/search" && navigate("/gallery/search");
      })
      .finally(() => {
        dispatch(setImagesLoading({ loading: false }));
      });
  };

  // 翻页操作
  const getPageNumData = (pageNum) => {
    if (loading) {
      return;
    }

    dispatch(setImagesLoading({ loading: true }));
    dispatch(
      setParams({
        ...params,
        pageNum,
      })
    );
    const fParams = formatQueryParams({ ...params, pageNum });
    // 在搜索页，更改筛选条件，需要同步更改搜索链接以供用户可以直接分享
    if (pathname === "/gallery/search") {
      const searchParams = `${Object.entries(fParams).reduce(
        (str, [k, v]) =>
          ![
            "serviceMethod",
            "userId",
            // "pageNum",
            "pageSize",
            "imageAppOpName",
            "imageName",
            "faceImageName",
          ].includes(k) && v
            ? `${str}${str ? "&" : ""}${
                // k为开启语义时，inputText为sentence的内容，sentence为1表示开启
                k === "sentence"
                  ? `${k}=1&inputText=${encodeURIComponent(v)}`
                  : `${k}=${encodeURIComponent(v)}`
              }`
            : str,
        ""
      )}`;
      window.history.replaceState(null, "", `${pathname}?${searchParams}`);
    }
    dispatch(
      getImages({
        params: fParams,
      })
    )
      .then((res) => {
        if (!Array.isArray(res?.payload?.responseItems)) {
          return;
        }
        dispatch({
          type: "allImages/setImages",
          payload: {
            ...res.payload,
            responseItems: res.payload.responseItems || [],
          },
        });
      })
      .finally(() => {
        dispatch(setImagesLoading({ loading: false }));
      });
  };

  // 非登录态变为登录后重新搜索，在登录setUserInfo后直接调用search函数，即使使用useCallback(()=> {}, [userId])，search中拿到的userId仍然为null...
  const loginResearch = (info) => {
    // 登录成功后如果在搜索页，自动重新搜索
    if (pathname === "/gallery/search") {
      dispatch(setImagesLoading({ loading: true }));
      const fParams = formatQueryParams({ ...params, userId: info.userId });
      dispatch(
        getImages({
          params: fParams,
        })
      )
        .then((res) => {
          if (!Array.isArray(res?.payload?.responseItems)) {
            return;
          }
          dispatch({
            type: "allImages/setImages",
            payload: res.payload,
          });
        })
        .finally(() => {
          dispatch(setImagesLoading({ loading: false }));
        });
    }
  };

  const searchParams = useMemo(() => params ?? {}, [params]);

  return {
    searchParams,
    search,
    getNextPage,
    getPageNumData,
    loginResearch,
  };
};

export default useGetImages;
