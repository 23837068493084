import React, { useEffect, useLayoutEffect, useState, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LoginModal from "./components/loginModal";
import PayModal from "./components/payModal";
import WaitProgressModal from "./components/waitProgressModal";
// import AdvertisementModal from "./components/AdvertisementModal";
import CommonModal from "./components/CommonModal";
import Footer from "./components/footer";
import Header from "./components/header";
import RoutesWrapper from "./RoutesWrapper";
import FeedBack from "./components/feedBack";
import Redirect from "./components/Redirect";
import routes from "./router";
import { setParams } from "./redux/Reducers/imagesReducer";
import { setUserAuthenticated } from "./redux/Reducers/userReducer";
import { loginAsyncThunk } from "./redux/Actions/userActions";
import { isTop, getBase64 } from "./utilities/Utilities";
import "./App.scss";

function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [transparentNav, setTransparentNav] = useState(true);
  const { params, tencentCos } = useSelector((state) => {
    return {
      params: state?.imageStore?.params,
      tencentCos: state?.imageStore?.tencentCos,
    };
  });

  const { pathname, search = "" } = useLocation();
  useEffect(() => {
    if (pathname === "/gallery/content" && search.indexOf("cpId") > -1) {
      // 表示从三方系统内嵌的列表页点击跳转到图片详情页，连接上会带上渠道id cpId 渠道用户id cpUserId 等参数
      // 则从参数中获取调用对应图芬奇系统内userInfo接口
      // 搜索结果页面，链接有搜索参数，取参数覆盖默认参数
      const cpObj = {
        cpId: "",
        cpUserId: "",
        cpUserPermission: "",
      };

      search
        .substring(1)
        .split("&")
        .forEach((item) => {
          const [k, v] = item.split("=").map((i) => decodeURIComponent(i));
          if (k in cpObj) {
            cpObj[k] = v;
          }
        });
      if (Object.values(cpObj).every((value) => !!value)) {
        dispatch(
          loginAsyncThunk({
            params: {
              serviceMethod: "loginFromCp",
              ...cpObj,
            },
          })
        ).then((res) => {
          if (res.payload.userId) {
            dispatch(
              setUserAuthenticated({
                userInfo: res.payload,
                isAuthenticated: true,
              })
            );
          }
        });
        return;
      }
    }

    let userInfo_local = localStorage.getItem("userInfo");
    if (userInfo_local !== null || userInfo_local != undefined) {
      userInfo_local = JSON.parse(userInfo_local);
      // 更新用户信息数据
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "getUserInfo",
            userId: userInfo_local.userId,
          },
        })
      ).then((res) => {
        if (res.payload.userId) {
          // 如果头像是 imageName形式，还要通过 tencentCos 获取头像
          const headImageUrl = res.payload.headImageUrl;

          if (!headImageUrl || /^https?:\/\//.test(headImageUrl)) {
            dispatch(
              setUserAuthenticated({
                userInfo: res.payload,
                isAuthenticated: true,
              })
            );
          } else {
            tencentCos.downloadImage(headImageUrl, function (err, data) {
              if (err) {
                console.error(err);
              } else {
                getBase64(data.Body).then((url) => {
                  dispatch(
                    setUserAuthenticated({
                      userInfo: {
                        ...res.payload,
                        headImageUrl: url,
                      },
                      isAuthenticated: true,
                    })
                  );
                });
              }
            });
          }
        }
      });
      dispatch(
        setParams({
          ...params,
          // userId: userInfo_local.userId
        })
      );
    } else {
      dispatch(
        setUserAuthenticated({ userInfo: null, isAuthenticated: false })
      );
    }
  }, []);

  return (
    <div className="App poppins">
      {isTop() && <LoginModal />}
      <PayModal />
      <WaitProgressModal />
      <CommonModal />
      {/* <AdvertisementModal /> */}
      {location.pathname.indexOf("/edit") === -1 && isTop() && <FeedBack />}
      {location.pathname !== "/auth" && isTop() && <Header />}
      <RoutesWrapper setTransparentNav={setTransparentNav}>
        <Routes>
          {routes.map(({ path, Element, redirect, children, ...props }) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "calc(100vh - 65px)",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  {redirect ? <Redirect redirect={redirect} /> : <Element />}
                </Suspense>
              }
              {...props}
            >
              {children &&
                children?.map(({ path: subPath, Element: SubElement }) => (
                  <Route
                    key={subPath}
                    path={subPath}
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              minHeight: "600px",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <SubElement />
                      </Suspense>
                    }
                  />
                ))}
            </Route>
          ))}
        </Routes>
      </RoutesWrapper>
      {location.pathname !== "/auth" && isTop() && <Footer />}
    </div>
  );
}

export default App;
