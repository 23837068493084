import { createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utilities/APIConfig";
import { globalConfig } from "../../utilities/staticObjects";

const imageAppApi = globalConfig.imageAppApi;
const userApi = globalConfig.userApi;
async function asyncCall(data, { getState, rejectWithValue }) {
  try {
    if (data === null || data === undefined) {
      const state = getState();
      data = { params: state.imageStore.params };
    }

    const { result, error } = await APICall(imageAppApi, "POST", data);
    if (result) {
      return result;
    } else if (error) {
      return rejectWithValue(error);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
}

async function asyncCall2(data, { rejectWithValue }) {
  try {
    const { result, error } = await APICall(imageAppApi, "POST", data);
    if (result) {
      return result;
    } else if (error) {
      return rejectWithValue(error);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
}

async function userApiAsyncCall(data, { rejectWithValue }) {
  try {
    const { result, error } = await APICall(userApi, "POST", data);
    if (result) {
      return result;
    } else if (error) {
      return rejectWithValue(error);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
}
const getImages = createAsyncThunk("imageApp/getImages", asyncCall);
async function asyncCall1(data, { rejectWithValue }) {
  try {
    const { result, error } = await APICall(imageAppApi, "POST", data);
    if (result) {
      return result;
    } else if (error) {
      return rejectWithValue(error);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
}
const getFaceSearchImages = createAsyncThunk(
  "imageApp/getFaceSearchImages",
  asyncCall1
);

const getKeywords = createAsyncThunk("imageApp/getKeywords", asyncCall2);

const getTagsSuggestions = createAsyncThunk(
  "imageApp/getTagsSuggestion",
  async (data, { rejectWithValue }) => {
    try {
      const { result, error } = await APICall(imageAppApi, "POST", data);
      if (result) {
        return { result, param: data.params.kwPrefix };
      } else if (error) {
        return rejectWithValue(error);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

const getImagesMetaInfo = createAsyncThunk(
  "imageApp/getImagesMetaInfo",
  asyncCall1
);

// user image ops

const download = createAsyncThunk("imageApp/download", asyncCall2);

const addToGallery = createAsyncThunk("imageApp/addToGallery", asyncCall2);

const uploadImageToGallery = createAsyncThunk(
  "imageApp/uploadImageToGallery",
  asyncCall2
);

const getAllTags = createAsyncThunk("image/getAllTags", asyncCall2);

const getTagsImages = createAsyncThunk("image/getTagsImages", asyncCall2);

const uploadMultipleImages = createAsyncThunk(
  "image/uploadMultipleImages",
  asyncCall2
);
const setImageQuality = createAsyncThunk(
  "imageApp/setImageQuality",
  asyncCall2
);

const like = createAsyncThunk("imageApp/like", asyncCall2);

const setImageIllegal = createAsyncThunk(
  "imageApp/setImageIllegal",
  asyncCall2
);

const setImageKwMatchedScore = createAsyncThunk(
  "imageApp/setImageKwMatchedScore",
  asyncCall2
);

const setImagesConcept = createAsyncThunk(
  "imageApp/setImagesConcept",
  asyncCall2
);
const textToImage = createAsyncThunk("imageApp/textToImage", asyncCall2);
const login = createAsyncThunk("user/login", userApiAsyncCall);

export {
  getImages,
  getFaceSearchImages,
  getKeywords,
  getTagsSuggestions,
  getAllTags,
  uploadMultipleImages,
  getTagsImages,
  getImagesMetaInfo,
  download,
  uploadImageToGallery,
  addToGallery,
  like,
  textToImage,
  userApiAsyncCall,
  setImageIllegal,
  setImageQuality,
  setImageKwMatchedScore,
  setImagesConcept,
  login,
};
