import styled from "styled-components";

export const StyledSvgIcon = styled.div`
  width: ${({ fontSize }) => fontSize + "px"};
  height: ${({ fontSize }) => fontSize + "px"};
  overflow: hidden;
  > svg {
    width: ${({ fontSize }) => fontSize + "px"};
    height: ${({ fontSize }) => fontSize + "px"};
    position: relative;
    left: ${({ fontSize }) => `-${fontSize}px`};
    filter: drop-shadow(
      ${({ color }) => color} ${({ fontSize }) => `${fontSize}px`} 0
    );
  }
`;
