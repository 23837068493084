import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useToggleMenu from "../../hooks/useToggleMenu";
import {
  StyledPersonalWrapper,
  StyledRight,
  // StyledBecomeMember,
} from "./index.styled";
import defaultAvatar from "../../assets/images/home/avatar2.png";
import { logoutAsyncThunk } from "../../redux/Actions/userActions";
// import { ReactComponent as VIPIcon } from "../../assets/images/header/icon_VIP_top.svg";

const Personal = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userInfo } = useSelector((state) => state?.userStore);

  const { anchorEl, open, openMenu, handleClose } = useToggleMenu();

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate("/personal/account");
    handleClose();
  };
  // const navigateToGallery = () => {
  //   navigate("/personal/gallery");
  //   handleClose();
  // };
  const navigateToFavourite = () => {
    navigate("/personal/favourites");
    handleClose();
  };

  const logout = () => {
    dispatch(
      logoutAsyncThunk({
        params: { userId: userInfo.userId, serviceMethod: "logout" },
      })
    );
    dispatch({
      type: "user/logout",
    });
    handleClose();

    // 搜索结果页不重新加载
    if (pathname !== "/gallery/search") {
      window.location.reload();
    }
  };

  const openPayModal = () => {
    dispatch({
      type: "user/setPayModalState",
      payload: {
        isPayModalOpen: true,
      },
    });
  };

  const avatar = useMemo(() => {
    const headImageUrl = userInfo?.headImageUrl;
    if (!headImageUrl) {
      return defaultAvatar;
    }
    if (/^https?:\/\/|^data:image\//.test(headImageUrl)) {
      return headImageUrl;
    } else {
      return "";
    }
  }, [userInfo]);

  return (
    <StyledRight>
      {/* {!userInfo.userLevel > 0 && (
        <StyledBecomeMember onClick={openPayModal}>
          <VIPIcon />
          <span>成为会员</span>
        </StyledBecomeMember>
      )} */}
      {userInfo.userId?.indexOf("-") > -1 ? null : (
        <Button
          variant="outlined"
          size="small"
          className="buy-btn"
          onClick={openPayModal}
        >
          立即购买
        </Button>
      )}
      <StyledPersonalWrapper onMouseOver={openMenu}>
        {avatar && (
          <img className="avatar" src={avatar} width={40} height={40} />
        )}
        <span className="name">
          {userInfo?.nickName || userInfo?.mobileNumber || userInfo?.emailName}
        </span>
      </StyledPersonalWrapper>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div onMouseLeave={handleClose}>
          <MenuItem onClick={navigateToAccount}>个人中心</MenuItem>
          {/* <MenuItem onClick={navigateToGallery}>我的图库</MenuItem> */}
          <MenuItem onClick={navigateToFavourite}>我的收藏</MenuItem>
          <MenuItem onClick={logout}>退出登录</MenuItem>
        </div>
      </Menu>
    </StyledRight>
  );
};

export default Personal;
