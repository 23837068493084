import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StyledInputWrapper, LoginBtn } from "./index.styled";
import { useValidateAccount } from "../../hooks/useValidate";
import useLogin from "../../hooks/useLogin";
import usePhoneScreen from "../../hooks/usePhoneScreen";
import { loginAsyncThunk } from "../../redux/Actions/userActions";

const TelephoneLoginForm = ({ onChangeFormType = () => {} }) => {
  const { isPhoneScreen } = usePhoneScreen();

  const dispatch = useDispatch();

  const {
    accountName,
    setAccountName,
    accountPasswd,
    setAccountPasswd,
    accountErrorText,
    pwdErrorText,
    validate,
  } = useValidateAccount();

  const changeAccount = (e) => {
    setAccountName(e.target.value);
  };

  const changePwd = (e) => {
    setAccountPasswd(e.target.value);
  };

  const { loading, login } = useLogin({
    dispatch,
    loginFn: () =>
      dispatch(
        loginAsyncThunk({
          params: {
            serviceMethod: "loginNew",
            accountName,
            accountPasswd,
            loginType: "passwd",
          },
        })
      ),
  });

  const handleLogin = () => {
    if (!validate()) {
      return;
    }

    login();
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInputWrapper>
      <TextField
        className="input"
        error={Boolean(accountErrorText)}
        helperText={accountErrorText}
        maxLength="50"
        fullWidth
        placeholder="请输入账号"
        value={accountName}
        onChange={changeAccount}
      />
      <div className="input pwd">
        <TextField
          error={Boolean(pwdErrorText)}
          helperText={pwdErrorText}
          maxLength="50"
          type={showPassword ? "text" : "password"}
          fullWidth
          placeholder="请输入密码"
          value={accountPasswd}
          onChange={changePwd}
        />
        <IconButton
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </div>
      <LoginBtn disabled={loading} onClick={handleLogin}>
        登录
      </LoginBtn>
      <Stack className="btns" direction="row" spacing={4}>
        {!isPhoneScreen && (
          <span className="btn" onClick={() => onChangeFormType("weixin")}>
            微信登录
          </span>
        )}
        <span className="btn" onClick={() => onChangeFormType("telephone")}>
          手机号登录
        </span>
        <span className="btn" onClick={() => onChangeFormType("email")}>
          邮箱登录
        </span>
      </Stack>
    </StyledInputWrapper>
  );
};

export default TelephoneLoginForm;
