import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";

const style = {
  width: "166px",
  height: "41px",
  fontSize: "18px",
  fontFamily: "Source Han Sans SC",
  color: "#333333",
  textAlign: "center",
  lineHeight: "41px",
  border: "1px solid #000000",
  borderRadius: "20px",
  cursor: "pointer",
};

const LoginBtn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openLoginModal = () => {
    dispatch({
      type: "user/setLoginModalState",
      payload: {
        isLoginModalOpen: true,
      },
    });
  };

  return (
    <Button style={style} variant="outlined" onClick={openLoginModal}>
      {t("loginLabel")}/{t("registerLabel")}
    </Button>
  );
};

export default LoginBtn;
