import React, { useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { StyledPayTypesWrapper } from "./index.styled";
import SvgIcon from "../../components/svgIcon";
import { ReactComponent as IconRight } from "../../assets/images/payModal/icon_yes_popup_pay.svg";

const TypeMap = {
  PL: "新媒体授权",
  SL: "标准授权",
  EL: "扩展授权",
};

const PayTypes = ({
  priceTypes = [],
  currentPay = {},
  confirmBuy = () => {},
  PayCodeAndResult = null,
}) => {
  // 各个套餐的购买数量类型，代表各个type的keys里第index项
  const [typeActiveIndexs, setTypeActiveIndexs] = useState(
    Array(priceTypes.length).fill(0)
  );
  const changeActiveType = (typeIndex, typeActiveIndex) => {
    // 当前支付状态，不能切换
    if (PayCodeAndResult) {
      return;
    }

    setTypeActiveIndexs((preVal) => {
      const arr = [...preVal];
      arr[typeIndex] = typeActiveIndex;
      return arr;
    });
  };

  // 点击购买
  const buy = (index) => {
    const { servicePlanKey, price } =
      priceTypes[index].keys[typeActiveIndexs[index]];
    confirmBuy({ servicePlanKey, price });
  };

  return (
    <StyledPayTypesWrapper>
      {/* 套餐cards */}
      {priceTypes.map(({ tag, tagStyle, keys }, typeIndex) => {
        const btns = keys.map(({ servicePlanKey, price }) => {
          const [type, yearTime, downloadNum] = servicePlanKey.split("-");
          return {
            type,
            yearTime,
            downloadNum,
            price,
          };
        });

        const UseIcon = (
          <SvgIcon fontSize={18} color="#ea4335">
            <IconRight />
          </SvgIcon>
        );

        // 当前type类型套餐的购买数量keys的index项
        const activeIndex = typeActiveIndexs[typeIndex];

        // 点击立即购买后，仅展示选中的套餐card，右侧展示支付码及支付说明
        if (
          PayCodeAndResult &&
          currentPay.servicePlanKey !==
            priceTypes[typeIndex].keys[activeIndex].servicePlanKey
        ) {
          return null;
        }

        return (
          <div key={typeIndex} className="type">
            {tag && (
              <div className="tag" style={tagStyle}>
                {tag}
              </div>
            )}
            <h5 className="title">{TypeMap[btns[activeIndex].type]}</h5>
            <p className="sub-title">仅限中小微企业使用</p>
            <p className="year">一年下载量</p>
            {/* 当前套餐购买数量切换 */}
            <ButtonGroup size="small" className="num-btns">
              {btns.map(({ downloadNum }, numIndex) => {
                if (PayCodeAndResult && activeIndex !== numIndex) {
                  return null;
                }

                return (
                  <Button
                    key={downloadNum}
                    variant={
                      activeIndex === numIndex ? "contained" : "outlined"
                    }
                    disabled={!!PayCodeAndResult}
                    onClick={() => changeActiveType(typeIndex, numIndex)}
                  >
                    {downloadNum}
                  </Button>
                );
              })}
            </ButtonGroup>
            <div className="price">
              {btns[activeIndex].price}
              <span className="unit">元</span>
            </div>
            <div className="avg">
              平均
              {btns[activeIndex].price / btns[activeIndex].downloadNum}
              元/张
            </div>
            <div className="use">
              <div className="divider">授权用途</div>
              <ul className="use-list">
                <li className="use-item">
                  数字用途
                  {UseIcon}
                </li>
                {typeIndex > 0 && (
                  <li className="use-item">
                    线下印刷（{typeIndex > 1 ? "不限量" : "限50W"}）{UseIcon}
                  </li>
                )}
                {typeIndex > 1 && (
                  <li className="use-item">
                    户外广告用途
                    {UseIcon}
                  </li>
                )}
              </ul>
            </div>
            <div className="buy">
              {!PayCodeAndResult && (
                <Button
                  className="buy-btn"
                  variant="contained"
                  onClick={() => buy(typeIndex)}
                >
                  立即购买
                </Button>
              )}
              <p className="text">套餐自购买日起365天内有效</p>
            </div>
          </div>
        );
      })}
      {PayCodeAndResult && (
        <div className="pay-code-wrapper">{PayCodeAndResult}</div>
      )}
    </StyledPayTypesWrapper>
  );
};

export default PayTypes;
