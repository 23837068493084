import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledHeader,
  HeaderWrapper,
  HeaderBox,
  NavWrapper,
} from "./index.styled";
import { ReactComponent as Logo } from "../../assets/images/header/logo.svg";
import Navs from "./navs";
import SearchBox from "../galleryIndexSearch/searchBox";
import LoginBtn from "./loginBtn";
import Personal from "./personal";
import usePhoneScreen from "../../hooks/usePhoneScreen";
import { ReactComponent as IconMine } from "../../assets/images/personal/icon_mine.svg";
import defaultAvatar from "../../assets/images/home/avatar2.png";
import NavH5 from "./navH5";

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state) => state?.userStore ?? {});
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const openLoginModal = () => {
    dispatch({
      type: "user/setLoginModalState",
      payload: {
        isLoginModalOpen: true,
      },
    });
  };

  const navigateToAccount = () => {
    navigate("/personal/account");
  };

  const { isPhoneScreen } = usePhoneScreen();

  return (
    <StyledHeader>
      <HeaderWrapper>
        <HeaderBox>
          {isPhoneScreen ? (
            <>
              {!userInfo.userId ? (
                <IconMine className="h5-avatar" onClick={openLoginModal} />
              ) : (
                <img
                  className="h5-avatar"
                  onClick={navigateToAccount}
                  src={userInfo?.headImageUrl ?? defaultAvatar}
                />
              )}
              <Logo className="logo" onClick={goHome} />
              <NavH5 />
            </>
          ) : (
            <>
              <NavWrapper>
                <Logo className="logo" onClick={goHome} />
                {["/gallery/search", "/gallery/content"].includes(pathname) ? (
                  <SearchBox className="search-box" top />
                ) : (
                  <Navs />
                )}
              </NavWrapper>
              {userInfo.userId ? <Personal /> : <LoginBtn />}
            </>
          )}
        </HeaderBox>
      </HeaderWrapper>
    </StyledHeader>
  );
};

export default Header;
