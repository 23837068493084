import React, { useEffect, useRef } from "react";

const DropFileBox = ({
  multiple = true,
  handleDropFiles = () => {},
  children,
  ...props
}) => {
  const domRef = useRef(null);

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDrop(e) {
    const dt = e.dataTransfer;
    const files = dt.files;

    // 处理文件列表
    handleDropFiles({ target: { files: multiple ? files : [files[0]] } });
  }

  useEffect(() => {
    const dom = domRef.current;

    if (!dom) {
      return;
    }

    // 拦截文件拖放事件，防止浏览器默认的行为
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dom.addEventListener(eventName, preventDefaults, false);
    });

    // 实现拖放事件处理函数
    dom.addEventListener("drop", handleDrop, false);
  }, []);

  return (
    <div ref={domRef} {...props}>
      {children}
    </div>
  );
};

export default DropFileBox;
